import { EffectsMapObject, routerRedux } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers } from "@reco-m/core";
import { app, changeUrlParams, getParkCode, getSearchUrl } from "@reco-w/core-ui";

import { WorkOrderSearchParamInUrlEnum } from "@reco-w/common-common";
import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { policyDeclareService, policyImplementationService, policyService, policySpecialService } from "@reco-w/policy-service";
import { Namespaces, PolicyDeclareModeEnum, SearchParamInUrlEnum } from "./common";
import { getDeclareStatus } from "./utils";

export namespace newPolicyDeclareFormModel {
    export const namespace = Namespaces.newpolicyDeclareForm;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, implementationId, declareFormId, specialId, props }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getDeclareMode", message });
                yield yield put({ type: "getUserInfo", message });
                yield yield put({ type: "getCurrentConsumer", message });
                yield yield put({ type: "getImplementationDetail", message, id: implementationId, specialId });

                yield put({ type: "getDataByUrl", message, props });

                if (!specialId && !declareFormId && !window.location.pathname.includes(`/${getParkCode()}/my/myDeclare`)) {
                    yield put({ type: "getValidDeclareStatus", message, declareFormId });
                }

                if (declareFormId) {
                    yield yield put({ type: "getDeclareDetail", declareFormId, message });
                }
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getDataByUrl({ message, props }, { call, put }) {
            try {
                if (!props) {
                    return;
                }
                const params = getSearchUrl([SearchParamInUrlEnum.specialId, SearchParamInUrlEnum.specialProjectId], props);

                const specialId = params[SearchParamInUrlEnum.specialId];
                const specialProjectId = params[SearchParamInUrlEnum.specialProjectId];
                if (specialId && !specialProjectId) {
                    yield put({ type: "getCheckApplyFrequency", message, specialId, specialProjectId });
                }
            } catch (e) {
                yield call(message!.error, "getDataByUrl：" + e.errmsg);
            }
        },

        /**
         * 获取申报类型
         * @param { message }
         * @param { call, put }
         */
        *getDeclareMode({ message }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const config = yield call(policyService.getConfig);
                const unitConfig = config.unitConfig;
                const declareMode = unitConfig?.declareMode;
                if (declareMode === PolicyDeclareModeEnum.none) {
                    yield put({ type: "handleError", message, errorText: "当前政策不支持申报" });
                    return;
                } else {
                    yield put({ type: "input", data: { declareMode: declareMode } });
                }
            } catch (e) {
                yield call(message!.error, "getDeclareMode：" + e.errmsg);
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取用户信息
         * @param { message, data, callback }
         * @param { call, put,select }
         */
        *getUserInfo({ message }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message });
                const memberState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    member = memberState.member,
                    user = memberState.user?.currentUser;

                if (member?.status !== CertifyStatusEnum.allow) {
                    yield put({ type: "handleError", message, errorText: "当前用户未成功认证" });
                    return;
                } else {
                    yield put({
                        type: "input",
                        data: {
                            realName: user?.realName || "",
                            inputerMobile: user?.mobile || "",
                            customerName: member?.companyName,
                            customerId: member?.companyId,
                            creditCode: member?.creditCode,
                            user,
                        },
                    });
                }
            } catch (e) {
                yield call(message!.error, "getUserInfo：" + e.errmsg);
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取当前个人用户信息
         */
        *getCurrentConsumer({ message }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                const state: any = yield select((state) => state[Namespaces.newpolicyDeclareForm]),
                    declareMode = state!.declareMode;

                if (declareMode === PolicyDeclareModeEnum.complex) {
                    yield yield put({ type: `${commonNamespaces.consumerCommon}/getCurrentConsumer`, message });
                    const consumerCommonState: any = yield select((state) => state[commonNamespaces.consumerCommon]),
                        consumerDetail = consumerCommonState.consumerDetail;
                    if (!consumerDetail) {
                        yield put({ type: "handleError", message, errorText: "当前用户未完善企业信息" });
                        return;
                    } else {
                        yield put({ type: "input", data: { consumerDetail } });
                    }
                }
            } catch (e) {
                yield call(message!.error, "getCurrentConsumer：" + e.errmsg);
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取实施细则详情
         * @param { message, id }
         * @param { call, put }
         */
        *getImplementationDetail({ message, id, specialId }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                if ((!id || id === "0") && specialId) {
                    return;
                }

                const result = yield call(policyImplementationService.get, id);

                const state = yield select((state) => state[Namespaces.newpolicyDeclareDetail]),
                    declareMode = state!.declareMode;

                if (declareMode === PolicyDeclareModeEnum.complex && result) {
                    const specialList = yield call(policySpecialService.getSpecialDetailList, {
                        policyImplementationDetailId: result.id,
                        isValid: true,
                    });
                    result.special = specialList.find((x) => x.policyImplementationDetailId === result.id);
                    result.flowId = (result.special?.flowList?.length > 0 && result.special?.flowList[0]?.flowId) || 0;
                }

                if (result?.special) {
                    result.declareStartTime = result.special.startDate;
                    result.declareEndTime = result.special.endDate;
                }

                const declareStatusObj = getDeclareStatus(result?.declareStartTime, result?.declareEndTime, result?.isAllowDeclare);

                if (!result) {
                    yield put({ type: "handleError", message, errorText: "该实施细则不存在" });
                    return;
                } else if (!declareStatusObj?.diffTime) {
                    yield put({ type: "handleError", message, errorText: `该实施细则${declareStatusObj?.text}` });
                    return;
                } else {
                    yield put({ type: "input", data: { implementationDetail: result } });
                }

                if (result?.special) {
                    const path = changeUrlParams(window.location.search, {
                        [SearchParamInUrlEnum.specialId]: result?.special?.id,
                        [WorkOrderSearchParamInUrlEnum.flowId]: result?.flowId,
                        t: new Date().format("yyyyMMddhhmmss"),
                    });
                    yield put(routerRedux.replace(`/${getParkCode()}/policy/${id}/form${path}`));
                }
            } catch (e) {
                yield call(message!.error, "getImplementationDetail：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取简单版用户提交信息
         * @param { message }
         * @param { call, put,select }
         */
        *getValidDeclareStatus({ message }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                const state = yield select((state) => state[Namespaces.newpolicyDeclareForm]),
                    implementationDetail = state!.implementationDetail,
                    customerId = state.customerId,
                    declareMode = state!.declareModel;

                if (declareMode === PolicyDeclareModeEnum.simple) {
                    const isUnvalidDeclare = yield call(policyDeclareService.getValidDeclareStatus, customerId, implementationDetail?.id);

                    if (!isUnvalidDeclare) {
                        yield put({ type: "handleError", message, errorText: "您已申报过该政策，无法继续申报", url: `/${getParkCode()}/policy/${implementationDetail?.id}` });
                        return;
                    }
                }
            } catch (e) {
                yield call(message!.error, "getValidDeclareStatus：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 检查复杂版申报频率
         * @param { message }
         * @param { call, put,select }
         */
        *getCheckApplyFrequency({ message, specialId, specialProjectId }, { call, put, select }) {
            try {
                if (specialId && !specialProjectId) {
                    yield put({ type: "showLoading" });

                    const state = yield select((state) => state[Namespaces.newpolicyDeclareForm]),
                        consumerDetail = state!.consumerDetail,
                        declareMode = state!.declareModel;

                    if (declareMode === PolicyDeclareModeEnum.complex) {
                        yield call(policySpecialService.checkApplyFrequency, specialId, { consumerId: consumerDetail?.id });
                    }
                }
            } catch (e) {
                yield put({ type: "handleError", message, errorText: e.errmsg, url: `/${getParkCode()}/policy` });
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取当前企业信息
         */
        *getDeclareDetail({ message, declareFormId }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(policyDeclareService.get, declareFormId);

                yield put({ type: "input", data: { declare: result } });
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 处理错误信息
         * @param { message, id }
         * @param { call, put }
         */
        *handleError({ message, url, errorText }, { put }) {
            message?.error(errorText);
            yield put(routerRedux.replace(url || `/${getParkCode()}/policy`));
            yield put({ type: "hideLoading" });
        },
    };
}
app.model(newPolicyDeclareFormModel);
