import { HttpService, resolveService } from "@reco-m/core";

export class notificationHttpService extends HttpService {
    constructor() {
        super("notification/notification");
    }

    /**
     * 阅读消息
     * @param deliveryId
     * @returns
     */
    readNotification(deliveryId: any) {
        return this.httpPost("read?deliveryId=" + deliveryId);
    }

    /**
     * 阅读所有消息
     * @param data
     * @returns
     */
    readAllNotification(data: any) {
        return this.httpPost("read-all?notificationType=" + data.notificationType + "&sceneId=" + data.sceneId);
    }

    /**
     * 获取消息数量
     * @param data
     * @returns
     */
    getNotificationCount(data) {
        return this.httpGet("count", this.resolveRequestParams(data));
    }
}

export const notificationService = resolveService(notificationHttpService);
