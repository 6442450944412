import React from "react";
import ReactDOM from "react-dom";

import { message } from "antd";
import { freeze } from "immer";

import { CoreComponent, browser, MessageInstance, getObjectProp } from "@reco-m/core";

import { View, Loading } from "../components";

export namespace ViewComponent {
    export interface IProps<S = any> extends CoreComponent.IProps<S> {
        showloading?: boolean;
        ignoreClass?: boolean;
        showheader?: boolean;
        scrollable?: boolean;
        canBack?: boolean;
        bodyClass?: string;
    }

    export interface IState extends CoreComponent.IState {
        showloading: boolean;
        isLoading: boolean;
        vertical?: boolean;
    }

    export abstract class Base<P extends IProps = IProps, S extends IState = IState> extends CoreComponent.Base<P, S> {
        static pageType = "view";

        static defaultProps = {
            ...CoreComponent.Base.defaultProps,
            showloading: true,
            ignoreClass: false,
            scrollable: true,
            showheader: true,
            canBack: false,
        };

        protected isRoot = false;
        protected showheader = true;
        protected showloading = true;
        protected scrollable = true;
        protected canBack = false;
        protected bodyClass: string;

        protected headerContent: any;
        protected scroll: any;

        /**
         * 园区code
         */
        get parkCode() {
            return this.props.match!.params.parkcode || client["parkCode"];
        }

        constructor(props: P, context: any) {
            super(props, context);
        }

        protected createMessageTools(): MessageInstance {
            return freeze({
                info(content: React.ReactNode, duration?: number, onClose?: () => void): void {
                    message.info(content, duration, onClose);
                },
                success(content: React.ReactNode, duration?: number, onClose?: () => void): void {
                    message.success(content, duration, onClose);
                },
                error(content: React.ReactNode, duration?: number, onClose?: () => void): void {
                    message.error(content, duration, onClose);
                },
                warning(content: React.ReactNode, duration?: number, onClose?: () => void): void {
                    message.warning(content, duration, onClose);
                },
                loading(content: React.ReactNode, duration?: number, onClose?: () => void): void {
                    message.loading(content, duration, onClose);
                },
            });
        }

        goBack(e?: MouseEvent | boolean) {
            if (e === !1) this.__goBack();
            else if (browser.versions.ios) {
                $(ReactDOM.findDOMNode(this) as any)
                    .closest(".container-page")
                    .removeClass("slideInRight")
                    .addClass("slideOutRight");
                $(ReactDOM.findDOMNode(this) as any)
                    .prev(".container-page")
                    .addClass("accelerate");
                setTimeout(this.__goBack.bind(this), 200);
            } else {
                $(ReactDOM.findDOMNode(this) as any)
                    .closest(".container-page")
                    .removeClass("zoomIn")
                    .addClass("zoomOut");
                $(ReactDOM.findDOMNode(this) as any)
                    .prev(".container-page")
                    .addClass("accelerate");
                if (browser.versions.android) {
                    $(ReactDOM.findDOMNode(this) as any)
                        .prev(".container-page")
                        .addClass("android-accelerate")
                        .css({ display: "flex", opacity: 1 });
                }
                setTimeout(this.__goBack.bind(this), 200);
            }
        }

        renderHeader(): React.ReactNode {
            const pageHeader = client.pageLayout?.pageHeader;

            return pageHeader ? this.renderEmbeddedView(pageHeader) : null;
        }

        renderBody(): React.ReactNode {
            return "未加载数据！" as any;
        }

        renderFooter(): React.ReactNode {
            const pageFooter = client.pageLayout?.pageFooter;

            return pageFooter ? this.renderEmbeddedView(pageFooter) : null;
        }

        /**
         * 渲染我的目录
         * @returns
         */
        renderMyMenu(): React.ReactNode {
            const myMenu: any = getObjectProp(client, "pageLayout.pageMyMenu");

            return myMenu ? this.renderEmbeddedView(myMenu) : null;
        }

        renderLoading(showloading = false): React.ReactNode {
            const { canBack, state } = this.props as any,
                back = canBack || this.canBack || !this.isRoot;

            return showloading ||
                (this.showloading &&
                    this.props.showloading &&
                    (!state || !this.state || (state.showloading !== !1 && state.isLoading !== !1) || (this.state.showloading !== !1 && this.state.isLoading === !0))) ? (
                <Loading.Component showback={back} />
            ) : null;
        }

        renderOutTop(): React.ReactNode {
            return null;
        }

        renderOutBottom(): React.ReactNode {
            return null;
        }

        refScroll(el) {
            this.scroll = el;
        }

        render(): React.ReactNode {
            const { ignoreClass } = this.props;

            return (
                <>
                    {this.renderOutTop()}
                    <View.Component ignoreClass={ignoreClass!} isRoot={this.isRoot}>
                        {this.renderHeader()}
                        <div
                            className={this.classnames(
                                this.scrollable && this.props.scrollable !== false ? "container-scrollable" : "",
                                "container-fill body",
                                this.props.bodyClass || this.bodyClass
                            )}
                            ref={(el) => this.refScroll(el)}
                        >
                            {this.renderBody()}
                        </div>
                        {this.renderFooter()}
                        {this.renderLoading()}
                    </View.Component>
                    {this.renderOutBottom()}
                </>
            );
        }
    }
}
