import { HttpService, resolveService } from "@reco-m/core";
export class FlowApiHttpService extends HttpService {
    constructor(controlName) {
        super(controlName);
    }

    getCustom(url: string, data?: any) {
        return this.httpGet(url, data ? this.resolveRequestParams({ ...data, ignoreNetError: true }) : {});
    }
}
export const flowApiService = resolveService(FlowApiHttpService);
