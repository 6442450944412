import { ViewComponent } from "@reco-w/core-ui";
// import { ViewComponent, Breadcrumbs, Banner } from "@reco-w/core-ui";
// import { BackTop } from "antd";

export namespace UtilView {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> {
        state;
    }

    export interface IState extends ViewComponent.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        otherData: any = {};

        getSearchParamEx(attr) {
            return this.getSearchParam(attr) ? decodeURI(this.getSearchParam(attr)) : undefined;
        }

        get store(): any {
            return this.props.state!;
        }

        updateData(pa) {
            this.dispatch("update", pa);
        }

        assignOtherData(pa) {
            Object.assign(this.otherData, pa);
        }
        inputOtherData(pa) {
            this.assignOtherData(pa);
            this.forceUpdate();
        }

        // renderBodyContent() {}
        // renderBody(): React.ReactNode {
        //     return (
        //         <>
        //             <div className="content">
        //                 <Banner.Component bimg="assets/images/kjw/jr-banner.jpg" text={this.title} />
        //                 <div className="container">
        //                     <Breadcrumbs.Component breadcrumbList={this.breadcrumbList} />
        //                     {this.renderBodyContent()}
        //                 </div>
        //             </div>
        //             <div className="scoll-top">
        //                 <BackTop visibilityHeight={50} />
        //             </div>
        //         </>
        //     );
        // }
    }
}
