/**
 * 获取页面参数
 * @returns
 */
export function getRequest() {
    const url = location.href,
        theRequest = {};
    if (url.indexOf("?") !== -1) {
        const str = url.substr(url.indexOf("?") + 1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
        }
    }
    return theRequest;
}

/**
 * 处理表单模板
 * @param template
 */
export function filterTemplate(template) {
    if (template?.indexOf("<form>") > -1 && template?.indexOf("</form>") > -1) {
        return template.slice(template?.indexOf("<form>") + 6, template?.indexOf("</form>"));
    } else if (template?.indexOf("<body>") > -1 && template?.indexOf("</body>") > -1) {
        return template.slice(template?.indexOf("<body>") + 6, template?.indexOf("</body>"));
    } else {
        return template;
    }
}

/**
 * 选出html中所有的div元素
 * @param template
 */
export function filterDiv(template: any) {
    let num = $(template).length;
    let list: any = [];
    for (let i = 0; i <= num; i++) {
        list.push($(template)[i]);
    }
    return list.filter((item) => item && item.tagName);
}
