import { HttpService, resolveService } from "@reco-m/core";

/**
 * 资讯（文章）管理
 */
export class ArticleHttpService extends HttpService {
    constructor() {
        super("article/article");
    }

    /**
     * 获取相邻的数据
     * @param id
     * @param data
     * @returns
     */
    getAdjacentPolicy(id, data?) {
        return this.httpGet("adjacent-article/" + id, this.resolveRequestParams(data));
    }
}

export const articleService = resolveService(ArticleHttpService);
