import { getCurrentParkId, HttpService, resolveService } from "@reco-m/core";

/**
 * 会员服务管理
 */
export class MyMemberServiceHttpService extends HttpService {
    constructor() {
        super("member-service/user-social-information");
    }
    /**
     * 个人主页各项数量
     * @param id
     * @returns
     */
    getMyCount(id: any) {
        return this.httpGet(`social-info/${id}/${getCurrentParkId()}/${getCurrentParkId()}`);
    }
}

export const myMemberServiceService = resolveService(MyMemberServiceHttpService);
