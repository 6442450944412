import { EffectsMapObject } from "dva";

import { getLocalStorage, getCurrentParkName, getCurrentParkId } from "@reco-m/core";

import { stateFlowService, flowDataSourceService } from "@reco-w/approval-service";

import { filterTemplate, filterDiv, getRequest } from "./utils";
import { FlowDisplayModeEnum } from "./common";

export const ApprovalStateBaseEffects: EffectsMapObject = {
    *getWorkFlowForm({ message, data, callback, isPut }, { call, put }) {
        try {
            yield put({ type: "showLoading" });

            let resultData: any;
            if (data.flowProjectId) {
                resultData = yield call(stateFlowService.getState, data);
            } else {
                resultData = yield call(stateFlowService.createState, data);
            }
            if (resultData?.flow?.displayMode !== FlowDisplayModeEnum.form && !resultData?.flow?.htmlContent?.APP) {
                yield call(message!.error, "请上传移动端模板");
                return;
            }
            // 获取数据源
            const dataSources = yield call(flowDataSourceService.getList, { flowId: [resultData.flow.flowId, 0], unitId: resultData.flow.unitId });
            // 获取变量
            const processResult = yield call(stateFlowService.getStateVariables, {
                flowProjectId: resultData?.project?.id,
                taskId: resultData?.task?.id,
            });
            const gsmc = processResult.find((x) => x.text === "系统变量.当前公司名称");
            gsmc && (gsmc.value = getLocalStorage("companyName"));

            const gsmcid = processResult.find((x) => x.text === "系统变量.当前公司ID");
            gsmcid && (gsmcid.value = getLocalStorage("companyId"));

            let routes = resultData?.flow?.process?.routes?.filter((r: any) => r.fromNodeId === resultData?.project?.currentNodeId) || [];
            if (data.flowProjectId && routes.some((d: any) => d.conditions?.length)) {
                routes = yield call(stateFlowService.getStateRoutes, {
                    flowProjectId: data?.flowProjectId,
                    componentData: resultData?.projectContent?.componentData,
                    taskId: data.taskId,
                });
            }
            routes = (routes || []).filter((r: any) => r.isValid);
            const formData = JSON.parse(resultData?.projectContent?.componentData);
            const incomeParams = Object.assign(formData?.urlData || {}, getRequest() || {}, data);
            formData.urlData = incomeParams;
            if (data.flowProjectId) {
                const newControls = resultData?.flow?.form?.controls?.filter((x) => !x.isDelete && !formData.controls.find((f: any) => f.controlCode === x.controlCode)) || [];
                newControls.forEach((c: any) => {
                    c.loadCustom = true;
                    c.loadDefault = true;
                });
                formData.controls = formData.controls.concat(newControls);
            }
            const node = resultData?.flow?.process?.nodes?.find((n: any) => n.nodeId === resultData?.project?.currentNodeId);
            let permissions = node?.permissions || [];
            if (data.routeId) {
                permissions = resultData?.flow?.process?.routes?.find((r: any) => r.routeId === data.routeId)?.permissions || [];
            }
            // tslint:disable-next-line: prefer-for-of
            for (let index = 0; index < formData?.controls?.length; index++) {
                const control = formData.controls[index];
                let element = resultData?.flow?.form?.controls?.find((d: any) => d.controlCode === control.controlCode);
                if (control?.controlCode?.indexOf("_") >= 0 && control?.dynamicRow) {
                    if (control.sourceControl) {
                        element = resultData?.flow?.form?.controls?.find((d: any) => d.controlCode === control.sourceControl);
                    } else {
                        const arr = control.controlCode.split("_");
                        element = resultData?.flow?.form?.controls?.find((d: any) => control.controlCode === `${arr[0]}_${d.controlCode}_${arr[arr.length - 1]}`);
                    }
                }
                if (!element) continue;
                if (element.defaultValue) {
                    const val = processResult.find((p) => p.text === element.defaultValue);
                    if (val) element.value = val.value;
                    if (element.defaultValue === "系统变量.当前园区名称") {
                        element.value = getCurrentParkName();
                    } else if (element.defaultValue === "系统变量.当前园区ID") {
                        element.value = getCurrentParkId();
                    }
                }
                if (element.defaultHidValue) {
                    const val = processResult.find((p) => p.text === element.defaultHidValue);
                    if (val) element.hidValue = val.value;
                    if (element.defaultHidValue === "系统变量.当前园区名称") {
                        element.hidValue = getCurrentParkName();
                    } else if (element.defaultHidValue === "系统变量.当前园区ID") {
                        element.hidValue = getCurrentParkId();
                    }
                }
                const permission = permissions?.find((d: any) => element.controlCode === d.controlCode);
                if (!control.loadDefault && permission?.visible) {
                    control.loadDefault = true;
                    if (!control?.value) {
                        control.value = element.value || "";
                        if (!control.value && element.defaultValue && element.defaultValue.indexOf("Url参数.") > -1) {
                            control.value = incomeParams[element.defaultValue.replace("Url参数.", "")];
                        }
                    }
                    if (!control?.hidValue) {
                        control.hidValue = element.hidValue || "";
                        if (!control.hidValue && element.defaultHidValue && element.defaultHidValue.indexOf("Url参数.") > -1) {
                            control.hidValue = incomeParams[element.defaultHidValue.replace("Url参数.", "")];
                        }
                    }
                }
            }
            if (resultData?.flow?.displayMode !== FlowDisplayModeEnum.form) {
                filterDiv(filterTemplate(resultData?.flow?.htmlContent?.APP)).forEach((item: any) => {
                    const children = $(item).children(),
                        type = $(item).attr("type") || $(item).attr("data-type"),
                        id = $(item).attr("id"),
                        big = $(item).attr("data-big"),
                        dtype = $(item).attr("data-type"),
                        totalto = $(item).attr("total-to");
                    if (children.length === 0) {
                        if (id) {
                            const control = formData?.controls?.find((d: any) => d.controlCode === id);
                            control && (control.totalto = totalto);
                            control && (control.big = big);
                            control && (control.formItemType = type);
                        }
                    } else {
                        children?.toArray()?.forEach((item: any) => {
                            const cid = $(item).attr("id");
                            const ctype = $(item).attr("type") || $(item).attr("data-type");
                            const ctotal = $(item).attr("total-to");
                            const cbig = $(item).attr("data-big");
                            if (cid) {
                                const control = formData?.controls?.find((d: any) => d.controlCode === cid);
                                control && (control.totalto = ctotal);
                                control && (control.big = cbig);
                                control && (control.formItemType = ctype);
                                control && (control.parentType = dtype);
                            }
                        });
                    }
                });
            } else {
                $(resultData?.flow?.htmlContent?.PC)
                    .find("[total-to]")
                    .each((_index: any, item: any) => {
                        const id = $(item).attr("id"),
                            totalto = $(item).attr("total-to");
                        if (id) {
                            const control = formData?.controls?.find((d: any) => d.controlCode === id);
                            control && (control.totalto = totalto);
                        }
                    });
                $(resultData?.flow?.htmlContent?.PC)
                    .find("[data-big]")
                    .each((_index: any, item: any) => {
                        const id = $(item).attr("id"),
                            big = $(item).attr("data-big");
                        if (id) {
                            const control = formData?.controls?.find((d: any) => d.controlCode === id);
                            control && (control.big = big);
                        }
                    });
            }
            if (isPut) {
                yield put({
                    type: "input",
                    data: {
                        form: resultData,
                        variables: processResult,
                        formData: formData,
                        permissions: permissions,
                        routes: routes,
                        incomeParams: incomeParams,
                        dataSources: dataSources,
                    },
                });
            }
            callback &&
                callback({
                    form: resultData,
                    variables: processResult,
                    formData: formData,
                    permissions: permissions,
                    routes: routes,
                    incomeParams: incomeParams,
                    dataSources: dataSources,
                });
        } catch (error) {
            yield call(message!.error, `${error.errmsg || error.toString()}`);
        } finally {
            yield put({ type: "hideLoading" });
        }
    },

    *transitState({ fail, data, callback }, { call, put }) {
        try {
            yield put({ type: "showLoading" });
            const result = yield call(stateFlowService.transitState, data);
            callback && callback(result);
        } catch (error) {
            fail && fail(error.errmsg || error.toString());
        } finally {
            yield put({ type: "hideLoading" });
        }
    },

    *getStateRoutes({ message, data, callback, isPut }, { call, put }) {
        try {
            const result = yield call(stateFlowService.getStateRoutes, data);
            if (isPut) {
                yield put({
                    type: "input",
                    data: {
                        routes: result,
                    },
                });
            }
            callback && callback(result);
        } catch (error) {
            yield call(message!.error, `${error.errmsg || error.toString()}`);
        }
    },
};
