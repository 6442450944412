import { HttpService, resolveService, getCurrentParkId } from "@reco-m/core";

export class CashAliPayHttpService extends HttpService {
    constructor() {
        super("cash/alipay");
    }

    pay(params: any) {
        return this.httpPost(getCurrentParkId() + "/pay", params);
    }
    payConfirm(params: any) {
        return this.httpPost(getCurrentParkId() + "/pay-confirm", params);
    }
}

export class WechatPayService extends HttpService {
    constructor() {
        super("cash/wechatpay");
    }
    pay(params: any) {
        return this.httpPost(getCurrentParkId() + "/pay", params);
    }
    payConfirm(params: any) {
        return this.httpPost(getCurrentParkId() + "/pay-confirm", params);
    }
}

export const wechatPayService = resolveService(WechatPayService);
export const cashAliPayService = resolveService(CashAliPayHttpService);
