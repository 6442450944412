import { router } from "dva";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";

import { getParkCode, loadLazyModule } from "@reco-w/core-ui";
import { InitWrapper } from "@reco/util-comp";

function RouterConfig({ history }: any) {
    return (
        <InitWrapper>
            <ConfigProvider locale={zhCN}>
                <router.Router history={history}>
                    <router.Switch>
                        <router.Route path="/login" component={loadLazyModule(() => import(/* webpackChunkName: "login" */ "@reco-w/bi-auth-login"))} />
                        <router.Route path="/:parkcode/login" component={loadLazyModule(() => import(/* webpackChunkName: "login" */ "@reco-w/bi-auth-login"))} />
                        <router.Route exact path="/:parkcode" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/bi-home-home"))} />
                        {/* <router.Route path="/:parkcode/activity" component={loadLazyModule(() => import("@reco-w/activity-activity"))} />
                    <router.Route path="/:parkcode/circle" component={loadLazyModule(() => import("@reco-w/circle-circle"))} />
                    <router.Route path="/:parkcode/article" component={loadLazyModule(() => import("@reco-w/article-article"))} /> */}
                        <router.Route path="/:parkcode/my" component={loadLazyModule(() => import(/* webpackChunkName: "my" */ "@reco-w/bi-my-my"))} />
                        {/* <router.Route path="/:parkcode/policy" component={loadLazyModule(() => import("@reco-w/policy-newpolicy"))} />
                    <router.Route path="/:parkcode/resource/:roomname" component={loadLazyModule(() => import("@reco-w/order-room"))} />
                    <router.Route path="/:parkcode/survey" component={loadLazyModule(() => import( "@reco-w/survey-survey"))} />
                    <router.Route path="/:parkcode/impression" component={loadLazyModule(() => import("@reco-w/impression-impression"))} />
                    <router.Route path="/:parkcode/problem" component={loadLazyModule(() => import("@reco-w/problem-problem"))} /> */}
                        <router.Route path="/:parkcode/workorder" component={loadLazyModule(() => import(/* webpackChunkName: "workorder" */ "@reco-w/workorder-workorder"))} />
                        {/* <router.Route path="/:parkcode/market" component={loadLazyModule(() => import("@reco-w/market-market"))} />
                    <router.Route path="/:parkcode/product" component={loadLazyModule(() => import("@reco-w/market-product"))} />
                    <router.Route path="/:parkcode/heardsearch" component={loadLazyModule(() => import("@reco-w/layout-headersearch"))} />
                    <router.Route path="/:parkcode/share" component={loadLazyModule(() => import("@reco-w/share-share"))} />
                    <router.Route path="/:parkcode/msgreach" component={loadLazyModule(() => import("@reco-w/msgreach-msgreach"))} />
                    <router.Route path="/:parkcode/enterprise" component={loadLazyModule(() => import("@reco-w/enterprise-enterprise"))} />
                    <router.Route path="/:parkcode/recruit" component={loadLazyModule(() => import( "@reco-w/recruit-recruit"))} /> */}

                        <router.Route
                            path="/:parkcode/peakdynamic"
                            component={loadLazyModule(() => import(/* webpackChunkName: "peakdynamic" */ "@reco-w/peakdynamic-peakdynamic"))}
                        />
                        <router.Route
                            path="/:parkcode/mediareport"
                            component={loadLazyModule(() => import(/* webpackChunkName: "mediareport" */ "@reco-w/mediareport-mediareport"))}
                        />
                        <router.Route path="/:parkcode/summitlive" component={loadLazyModule(() => import(/* webpackChunkName: "summitlive" */ "@reco-w/summitlive-summitlive"))} />
                        <router.Route path="/:parkcode/sidelights" component={loadLazyModule(() => import(/* webpackChunkName: "sidelights" */ "@reco-w/sidelights-sidelights"))} />
                        <router.Route
                            path="/:parkcode/previousreview"
                            component={loadLazyModule(() => import(/* webpackChunkName: "previousreview" */ "@reco-w/previousreview-previousreview"))}
                        />
                        <router.Route
                            path="/:parkcode/summitmeeting"
                            component={loadLazyModule(() => import(/* webpackChunkName: "summitmeeting" */ "@reco-w/summitmeeting-summitmeeting"))}
                        />
                        <router.Route path="/:parkcode/agendals" component={loadLazyModule(() => import("@reco-w/summitmeeting-agendalist"))} />
                        <router.Route path="/:parkcode/guest" component={loadLazyModule(() => import("@reco-w/guest-guest"))} />

                        <router.Redirect exact to={`/${getParkCode()}`} />
                    </router.Switch>
                </router.Router>
            </ConfigProvider>
        </InitWrapper>
    );
}

export default RouterConfig;
