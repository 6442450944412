export enum Namespaces {
    header = "headerNew",
    home = "homeNew",
    footer = "footerNew",
}

/**
 * banner图所属终端
 */
export enum BannerTerminalValueEnum {
    web = "1",
}

// 组织架构
export enum OrganizationTypeEnum {
    // 指导单位
    zddw = 1,
    // 支持单位
    zcdw = 2,
    // 主办单位
    zbdw = 3,
    // 承办单位
    cbdw = 4
}

// 合作伙伴
export enum PartnerTypeEnum {
    // 企业伙伴
    qy = 1,
    // 媒体伙伴
    mt = 2
}

// 图片类型
export enum PictureTypeEnum {
    // banner
    banner = 1,
    // 交通路线
    jtlx = 2,
    // 会场布局
    hcbj = 3,
    // 酒店推荐
    jdtj = 4
}

// 联系类型
export enum ContactTypeEnum {
    // 报名咨询
    bmzx = 1,
    // 会议合作
    hyhz = 2,
    // 赞助合作
    zzhz = 3,
    // 媒体合作
    mthz = 4
}

export const languageType = client["languageType"];
export const FORUM_TYPE_TAG_CODE = +languageType === 0 ? "FORUM/LUNTFL" : "FORUM/LUNTFL_EN";
export const FORUM_DIRE_TAG_CODE = +languageType === 0 ? "FORUM/LUNTFX" : "FORUM/LUNTFX_EN";
// 合作伙伴类型
export const HUOBLX_TAG_CODE = +languageType === 0 ? "PARTNERS/HUOBLX" : "PARTNERS/HUOBLX_EN";

// 参展报名
// export const exhibitionApplyUrl = "https://mp.weixin.qq.com/s/vX79buvlfg8QH8iwQzuGuA";
export const exhibitionApplyUrl = "https://mp.weixin.qq.com/s/Z_lAal6y7xBCpmXCvPp7vw";
