import { EffectsMapObject, routerRedux } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers, getCurrentParkId, pictureService } from "@reco-m/core";
import { app, getParkCode } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { IParkBindTableNameEnum, NULL_POINTER } from "@reco-w/common-common";
import { marketService, serviceProductService } from "@reco-w/market-service";

import { InstitutionCustomTypeEnum, InstitutionStatusEnum, Namespaces } from "./common";

export namespace marketDetailModel {
    export const namespace = Namespaces.marketDetail;

    export const state: any = freeze({ ...CoreState, showloading: true }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ message, id, successCall }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getInstitutionList", message, id });
                yield put({ type: "getAcceptanceMode", message });
                yield put({ type: "getInstitution", message, id, isgetTitle: true, successCall });
                yield put({ type: "getProductList", message, institutionId: id, data: { pageSize: 600 } });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        *initProductPage({ message, productId, successCall }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getAcceptanceMode", message });
                yield put({ type: "getProduct", message, data: productId, isgetTitle: true, successCall });
                yield put({ type: "getProductList", message, data: { pageSize: 3 } });
            } catch (e) {
                yield call(message!.error, "initProductPage：" + e.errmsg);
            }
        },

        /**
         * 获取受理模式
         */
        *getAcceptanceMode({ message }, { call, put }) {
            try {
                const institutionMode = yield call(marketService.getAcceptanceMode);

                yield put({ type: "input", data: { institutionMode } });
            } catch (e) {
                yield call(message!.error, "getAcceptanceMode：" + e.errmsg);
            }
        },

        /**
         * 获取机构列表
         */
        *getInstitutionList({ message, id, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const result = yield call(marketService.getPaged, {
                    parkId: getCurrentParkId(),
                    pageIndex: 1,
                    pageSize: 4,
                    orderBy: "inputTime desc",
                    serviceCategoryValue: null,
                    status: InstitutionStatusEnum.pass,
                    isConfirmed: true,
                    ...data,
                });

                let items = result?.items || [];
                if (items?.length > 0 && items.some((x) => x.id === id)) {
                    items = items.filter((x) => x.id !== id);
                }
                items = items.slice(0, 3);

                yield put({ type: "input", data: { institutions: items, a: new Date() } });
            } catch (e) {
                yield call(message!.error, "getInstitutionList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取机构详情
         */
        *getInstitution({ message, id, isgetTitle, successCall }, { call, put }) {
            try {
                const result = yield call(marketService.get, id) || {};

                if (result) {
                    let pictureData = yield call(pictureService.getPictureList, {
                        bindTableName: IParkBindTableNameEnum.institution,
                        bindTableId: id,
                        customType: InstitutionCustomTypeEnum.qualification,
                    });
                    pictureData = pictureData?.length > 0 && pictureData.map((t) => t.filePath);

                    yield put({ type: "input", data: { institution: result, qualificationPics: pictureData } });

                    if (isgetTitle) {
                        yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, title: result.serviceInstitutionBasicFormVM.institutionName, location: "/market", message });
                    }
                }
                if (successCall) {
                    yield call(successCall, result);
                }
            } catch (e) {
                yield call(message!.error, "getInstitution：" + e.errmsg);
            }
        },

        /**
         * 获取产品列表
         */
        *getProductList({ institutionId, data, message }, { call, put }) {
            try {
                const result = yield call(serviceProductService.getPaged, {
                    pageIndex: 1,
                    parkId: getCurrentParkId(),
                    institutionId: institutionId,
                    status: InstitutionStatusEnum.pass,
                    isOnService: true, // 上架
                    ...data,
                });

                yield put({ type: "input", data: { products: result.items, productNum: result?.totalItems, a: new Date() } });
            } catch (e) {
                yield call(message!.error, "getProductList：" + e.errmsg);
            }
        },

        /**
         * 获取产品详情
         */
        *getProduct({ message, data, isgetTitle, successCall }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(serviceProductService.get, data);

                if (isgetTitle) {
                    yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, title: result && result.ProductName, location: "/product", message });
                }

                yield put({ type: "getInstitution", id: result.serviceProductBasicFormVM.institutionId, successCall: () => {} });

                yield put({ type: "input", data: { product: result } });
                if (successCall) {
                    yield call(successCall, result);
                }
            } catch (e) {
                if (e?.errcode === NULL_POINTER) {
                    yield call(message!.error, "未找到该产品");
                    yield put(routerRedux.replace(`/${getParkCode()}/product/all`));
                } else {
                    yield call(message!.error, "getProduct：" + e.errmsg);
                }
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(marketDetailModel);
