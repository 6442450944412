import { OSingle } from "@reco/util-func";
import React, { useEffect, useState } from "react";

export function InitWrapper({ children }) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        OSingle.getSingleApi().then(() => {
            setShow(true);
        });
    }, []);

    return <>{show && children}</>;
}
