import { EffectsMapObject, routerRedux } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { clearLocalStorage, CoreEffects, CoreState, CoreReducers, getLocalStorage, setLocalStorage, setCurrentUnit, getCurrentUnitId, getCurrentParkName, setCurrentPark, getCurrentParkId } from "@reco-m/core";
import { app, getParkCode } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models-re";
import { Namespaces as userNamespaces } from "@reco-w/auth-user-models";
import { parkService, searchService, unitService } from "@reco-w/home-service";
import { parkCateService } from "@reco-w/impression-service";
import { ARTICLE_ROOT_CODE } from "@reco-w/article-models";

import { EnumParkTenantType, Namespaces, TIPS } from "./common";

export namespace headerModel {
    export const namespace = Namespaces.header;

    let articleCatalogs = [];

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            dataSource: null,
            currentUser: null,
            parkNames: null,
            radioSelect: getCurrentParkId() ? getCurrentParkId() : "",
            parkId: getCurrentParkId() ? getCurrentParkId() : "",
            parkName: getCurrentParkName() ? getCurrentParkName() : "",
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        /**
         * 初始化页面
         * @param { message, inputData, isPreventGetUser, judgeParkCode }
         * @param { call, put }
         */
        *initPage({ message, inputData, judgeParkCode }, { call, put }) {
            try {
                yield put({ type: "input", data: inputData });
                yield put({ type: "getPark", judgeParkCode, message });
                yield put({ type: "getCertify", message });
                yield put({ type: "getArticleCatalog", message });
                // yield put({ type: "getNotification", message });
                // yield put({ type: "getAppConfig", message });
                // yield put({ type: `${commonNamespaces.configCommon}/getAppConfig`, message });
            } catch (e) {
                yield call(message!.error, "initPage" + e.errmsg);
            }
        },

        /**
         * 获取认证情况
         * @param { message, isRefreshUser }
         * @param { call, put, select }
         */
        *getCertify({ message, isRefreshUser }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message, isRefreshUser });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                const user = memberCommonState.user;
                const member = memberCommonState.member;
                const currentUser = user.currentUser;

                yield put({ type: "input", data: { user: currentUser, member } });
            } catch (e) {
                yield call(message!.error, "getCertify" + e.errmsg);
            }
        },

        /**
         * 获取园区
         * @param { callback, judgeParkCode, message }
         * @param { call, put }
         */
        *getPark({ callback, judgeParkCode, message }, { call, put }) {
            try {
                let units = yield call(unitService.getList, { isEnabled: true });
                const parkPaged = yield call(parkService.getPaged, { tenantType: EnumParkTenantType.managed, pageSize: 99999 }),
                    parks = (parkPaged && parkPaged.items) || [];
                const parkNames: any[] = [];

                for (let i = 0; i < units.length; i++) {
                    const unit = units[i];
                    unit.parks = parks
                        .filter((p) => (p.units || []).findIndex((u) => u.id === unit.id) > -1)
                        .map((park) => ({ label: park.parkName, value: park.id, code: park.parkCode, unitId: unit.id }));
                    unit.parks.forEach((item) => {
                        if (parkNames.findIndex((x) => x.value === item.value) === -1) {
                            parkNames.push(item);
                        }
                    });
                }

                units = units.filter((u) => u.parks && !!u.parks.length);
                // console.log("3333333", parkNames, units);

                let unitId = getCurrentUnitId();

                if (!unitId && units.length) {
                    setCurrentUnit(units[0]);
                    yield put(routerRedux.replace(`/`));

                    return;
                }
                yield put({ type: "input", data: { units } });

                // 判断当前园区和缓存中的园区是否一致
                if (judgeParkCode) {
                    const selectedPark = parkNames && parkNames.length > 0 && parkNames.find((x) => x.code === judgeParkCode);

                    if (!selectedPark) {
                        // 如果当前园区不存在，则把第一条数据设置为当前园区
                        setCurrentPark({
                            id: parkNames[0].value,
                            parkName: parkNames[0].label,
                            parkCode: parkNames[0].code
                        })
                        yield put(routerRedux.replace(`/${parkNames[0].code}`));
                        yield put({ type: "input", data: { radioSelect: parkNames[0].value, parkName: parkNames[0].label, i: Math.random() } });
                    } else {
                        const isRefresh = getParkCode() && selectedPark && selectedPark.value.toString() !== getCurrentParkId();
                        if (isRefresh || !getCurrentParkId()) {
                            setCurrentPark({
                                id: selectedPark.value,
                                parkName: selectedPark.label,
                                parkCode: selectedPark.code
                            })
                        }
                    }
                }

                // 不存在缓存时
                if (parkNames && parkNames.length > 0 && !getCurrentParkId() && !getCurrentParkName()) {
                    setCurrentPark({
                        id: parkNames[0].value,
                        parkName: parkNames[0].label,
                        parkCode: parkNames[0].code
                    })
                    yield put({ type: "input", data: { radioSelect: parkNames[0].value, parkName: parkNames[0].label, i: Math.random() } });
                }

                // 不存在parkcode时
                if (getLocalStorage("parkCode")) {
                    if (getCurrentParkId()) {
                        const park = parkNames.find((x) => x.value.toString() === getCurrentParkId());
                        park && setLocalStorage("parkCode", park?.code);
                    } else {
                        setLocalStorage("parkCode", client["parkCode"]);
                    }
                }
                yield put({ type: "input", data: { parkNames } });

                if (callback) yield call(callback);
            } catch (e) {
                yield call(message!.error, "getPark" + e.errmsg);
            }
        },

        /**
         * 获取服务联盟类型
         * @param { message }
         * @param { call, put, select }
         */
        *getArticleCatalog({ message }, { call, put }) {
            try {
                if (!articleCatalogs?.length) {
                    articleCatalogs = yield call(parkCateService.getList, { authorized: false, rootCode: ARTICLE_ROOT_CODE });
                }

                yield put({ type: "input", data: { articleCatalogs } });
            } catch (e) {
                yield call(message!.error, "getArticleCatalog" + e.errmsg);
            }
        },

        /**
         * 获取全局搜索
         * @param { message, data, callback }
         * @param { call, put }
         */
        *getGlobalSearch({ message, data, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                if (data && data.key) {
                    yield put({ type: "input", data: { globalData: null } });
                    const globalData = yield call(searchService.getSearchData, {
                        ...data,
                        unitId: getCurrentUnitId(),
                        parkId: getCurrentParkId(),
                        pageSize: 1,
                    });
                    let arr: any = [];
                    TIPS.forEach((tip) => {
                        if (globalData[tip.key]) {
                            if (globalData[tip.key].totalItems !== "0" && globalData[tip.key].totalItems !== 0) {
                                arr.push({
                                    type: tip.type,
                                    query: tip.name,
                                    count: globalData[tip.key].totalItems && Number(globalData[tip.key].totalItems),
                                });
                            }
                        } else {
                            return false;
                        }
                    });
                    yield put({ type: "input", data: { globalData: arr } });

                    if (callback) yield call(callback, arr);
                } else {
                    yield put({ type: "input", data: { globalData: [] } });
                }
            } catch (e) {
                yield call(message!.error, "Head-getGlobalSearch：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取消息
         * @param { message }
         * @param { call, put, select }
         */
        *getNotification({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.noticeCommon}/getUnreadNotification`, message });
                const noticeCommonState = yield select((state) => state[commonNamespaces.noticeCommon]),
                    noticeCount = noticeCommonState!.noticeCount;

                yield put({ type: "input", data: { noticeCount } });
            } catch (e) {
                yield call(message!.error, "getNotification：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 登出
         * @param { error, success }
         * @param { call, put }
         */
        *logout({ error, success }, { call, put }) {
            try {
                clearLocalStorage();
                yield yield put({ type: `${commonNamespaces.memberCommon}/clean`, isCleanAll: true });
                yield yield put({ type: `${commonNamespaces.workorderCommon}/clean` });
                yield yield put({ type: `${commonNamespaces.consumerCommon}/clean` });

                yield put({ type: `${userNamespaces.user}/logout`, success });
            } catch (e) {
                yield call(error, "Head-logout" + e.errmsg);
            }
        },

        /**
         * 获取设置
         * @param { message }
         * @param { call, put, select }
         */
        *getAppConfig({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.configCommon}/getAppConfig` });
                const appConfigState: any = yield select((state) => state[commonNamespaces.configCommon]);
                const appConfig = appConfigState.appConfig;

                yield put({ type: "input", data: { appConfig } });
            } catch (e) {
                yield call(message!.error, "getCertify" + e.errmsg);
            }
        },

        /**
         * 获取设置
         * @param { message, callback, isLogin, park}
         * @param { call, put,  }
         */
        *changePark({ message, callback, isLogin, park }, { call, put }) {
            try {
                setCurrentPark({
                    id: park.value,
                    parkName: park.label,
                    parkCode: park.code
                })
                yield put({ type: "input", data: { radioSelect: park?.value, parkName: park?.label, parkId: park?.value } });
                if (isLogin) {
                    yield yield put({ type: `${commonNamespaces.memberCommon}/cleanMemberInfo`, isCleanAll: true }); // 切换园区清除原来认证信息
                    yield yield put({ type: `${commonNamespaces.memberCommon}/cleanLoyalty`, isCleanAll: true }); // 切换园区清除原来积分信息
                    yield yield put({ type: `${commonNamespaces.workorderCommon}/clean` }); // 切换园区清除原来服务机构等信息
                }

                if (callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield call(message!.error, "changePark" + e.errmsg);
            }
        },
    };
}

export function registerPageHeaderModel(model?: any) {
    app.model(model || headerModel);
}
