import { formatDate, setLocalStorage, getLocalStorage } from "@reco-m/core";
import { CommonSearchParamInUrlEnum, getDateDiff } from "@reco-w/common-common";
import { PolicyDeclareStatusEnum, PolicyTagDataTypeEnum } from "./common";

/**
 * 处理Url，获取多选数据的Value
 * @param str
 */
export function getMultiSelectValues(str?: string) {
    if (!str) {
        return [];
    }
    return str.split(",");
}

/**
 * 处理Url，获取多选数据的ID
 * @param values
 * @param datas
 */
export function getMultiSelectIDs(values?: any[], datas?: any[]) {
    if (!values || !datas) return [];
    const ids: any[] = [];

    values?.forEach((x) => {
        const data = datas.find((a) => a.tagValue === x);
        data && ids.push(data.id);
    });

    return ids;
}

/**
 * 获取政策状态
 * @param status
 * @returns
 */
export function getStatusText(status) {
    switch (status) {
        case PolicyDeclareStatusEnum.none:
            return "待处理";
        case PolicyDeclareStatusEnum.approved:
            return "已通过";
        case PolicyDeclareStatusEnum.return:
            return "已退回";
        default:
            break;
    }
}

/**
 * 获取政策标签
 * @param applyTags
 * @param tagClassCode
 * @returns
 */
export function getPolicyTag(applyTags: any[], tagClassCode: string) {
    if (!applyTags || !applyTags.length) {
        return "--";
    }
    const tag = applyTags.filter((x) => x.tagClassCode === tagClassCode).map((x) => x.tagName);
    return tag.join(",") || "--";
}

/**
 * 获取申报状态对象
 * @param startTime 开始时间
 * @param endTime   结束时间
 * @param isAllowDeclare    是否允许申报
 * @param isList    是否是列表（列表上当不足1天时需要展示0天）
 * @returns { text: "", color: "", diffTime, diffUnit }
 */
export function getDeclareStatus(startTime, endTime, isAllowDeclare, isList?) {
    // 当前时间与开始时间的差值
    const startDiffSeconds = getDateDiff(undefined, startTime, "seconds");
    // 结束时间与当前时间的差值
    const diffSeconds = getDateDiff(endTime, undefined, "seconds");

    let obj: any, diffTime, diffUnit;
    if (!isAllowDeclare) {
        obj = { text: "无法申报", color: "grey", tagColor: "" };
    } else if (startDiffSeconds <= 0) {
        obj = { text: "未启动", color: "yellow", tagColor: "" };
    } else if (diffSeconds <= 0) {
        obj = { text: "已截止", color: "grey", tagColor: "" };
    } else {
        if (diffSeconds > 0 && diffSeconds < 60) {
            if (isList) {
                diffTime = diffSeconds;
                diffUnit = "天";
            } else {
                diffTime = diffSeconds;
                diffUnit = "秒";
            }
        } else if (diffSeconds < 60 * 60) {
            if (isList) {
                diffTime = "0";
                diffUnit = "天";
            } else {
                diffTime = "0";
                diffUnit = "分钟";
            }
        } else if (diffSeconds < 60 * 60 * 24) {
            if (isList) {
                diffTime = "0";
                diffUnit = "天";
            } else {
                diffTime = Math.floor(diffSeconds / (60 * 60));
                diffUnit = "小时";
            }
        } else {
            const day = Math.floor(diffSeconds / (60 * 60 * 24));
            diffUnit = "天";
            if (day > 999) {
                diffTime = "999+";
            } else {
                diffTime = day;
            }
        }

        obj = { text: "申报中", color: "red", tagColor: "#cb1b3c" };
    }
    return { ...obj, diffTime, diffUnit };
}

/**
 * 政策标签选中对象转为数组
 * @param tagList
 * @param selectedTagObj
 * @returns
 */
export function policyTagJsonToList(tagList: any[] = [], selectedTagObj: any = {}) {
    let paramTagList: any = [];

    tagList.forEach((element) => {
        let itemSelect = selectedTagObj[`${element.id}Select`];
        if (itemSelect) {
            let tem: any = {};
            tem.tagId = element.id;
            tem.tagName = element.className;
            tem.dateTypeValue = element.dataTypeValue;
            if (element.dataTypeValue === PolicyTagDataTypeEnum.string) {
                if (Array.isArray(itemSelect)) {
                    tem.tagValue = itemSelect.map((x) => x.tagName).join(",");
                    tem.tagItemId = itemSelect.map((x) => x.id).join(",");
                } else {
                    tem.tagValue = itemSelect.tagName;
                    tem.tagItemId = itemSelect.id;
                }
            } else if (element.dataTypeValue === PolicyTagDataTypeEnum.number) {
                tem.tagValue = itemSelect;
            } else if (element.dataTypeValue === PolicyTagDataTypeEnum.date) {
                tem.tagValue = formatDate(itemSelect, "yyyy-MM-dd 00:00:00");
            } else if (element.dataTypeValue === PolicyTagDataTypeEnum.boolean) {
                tem.tagValue = itemSelect[0].tagValue;
            }

            paramTagList.push(tem);
        }
    });

    return paramTagList.filter((x) => x);
}

/**
 * 设置默认目录
 */
export function setDefaultPolicySelectMenu() {
    if (!getLocalStorage("selectedMenu").includes("/policy")) {
        setLocalStorage("selectedMenu", `/policy?${CommonSearchParamInUrlEnum.tabIndex}=1`);
    }
}
