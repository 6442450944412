import { HttpService, resolveService, getCurrentParkId } from "@reco-m/core";

/**
 * 政策
 */
export class PolicyHttpService extends HttpService {
    protected configPromise: any;
    constructor() {
        super("policy/policy");
    }

    getApplyTags(data) {
        return this.httpGet("getApplyTags", this.resolveRequestParams(data));
    }

    /**
     * 获取配置
     * @returns
     */
    getConfig() {
        this.configPromise || (this.configPromise = this.httpGet("getConfig"));
        return this.configPromise || Promise.resolve({});
        // return Promise.resolve({ declareMode: 1 });
    }

    getPolicyAndImplementation(data) {
        return this.httpGet("policy-and-implementation-detail", this.resolveRequestParams(data));
    }
}

export const policyService = resolveService(PolicyHttpService);

/**
 * 政策申报
 */
export class PolicyDeclareHttpService extends HttpService {
    constructor() {
        super("policy/declare");
    }

    getPolicyPaged(data) {
        return this.httpGet("get-policy-paged", this.resolveRequestParams(data));
    }

    /**
     * 获取可申报数量
     * @param [data]
     * @returns
     */
    getDeclareCount(data?) {
        return this.httpGet("declaring-policy-count", this.resolveRequestParams(data));
    }

    /**
     * 判断是否申报过
     * @param customerId
     * @param detailId
     * @returns
     */
    getValidDeclareStatus(customerId, detailId) {
        return this.httpGet("valid-declare-status/" + customerId + "/" + detailId);
    }
}

export const policyDeclareService = resolveService(PolicyDeclareHttpService);

/**
 * 政策实施细则
 */
export class PolicyImplementationHttpService extends HttpService {
    constructor() {
        super("policy/implementation-detail");
    }

    getDeclareImplementationDetailList(data) {
        return this.httpGet("declare-implementation-detail-list", this.resolveRequestParams(data));
    }
}

export const policyImplementationService = resolveService(PolicyImplementationHttpService);

/**
 * 政策计算器
 */
export class PolicyCalculatorHttpService extends HttpService {
    constructor() {
        super("policy/calculator");
    }

    /**
     * 政策计算器模拟计算
     * @param data
     * @returns
     */
    policyMatchPredictResult(data) {
        return this.httpPost("policy-match-predict-result", data);
    }

    /**
     * 获取模拟条件匹配的细则列表
     * @param data
     * @returns
     */
    getMatchImplementationDetailList(data) {
        return this.httpGet("match-implementation-detail-list", this.resolveRequestParams(data));
    }

    /**
     * 获取用户最后一次操作的标签记录
     * @returns
     */
    getLastCalculateTag(customerId) {
        return this.httpGet("last-calculate-tag/" + customerId + "?parkId=" + getCurrentParkId());
    }

    /**
     * 获取政策匹配默认标签
     * @returns
     */
    getPolicyMatchDefaultTagList() {
        return this.httpGet("policy-match-default-tag-list");
    }
}

export const policyCalculatorService = resolveService(PolicyCalculatorHttpService);

/**
 * 政策标签
 */
export class PolicyPortraitTagHttpService extends HttpService {
    constructor() {
        super("policy/portrait-tag");
    }
}

export const policyPortraitTagService = resolveService(PolicyPortraitTagHttpService);

/**
 * 政策标签项
 */
export class PolicyPortraitTagItemHttpService extends HttpService {
    constructor() {
        super("policy/portrait-tag-item");
    }
}

export const policyPortraitTagItemService = resolveService(PolicyPortraitTagItemHttpService);

export class ConsumerHttpService extends HttpService {
    constructor() {
        super("consumer/consumer");
    }

    /**
     * 获取用户信息
     * @returns
     */
    getConsumer(data?: any) {
        return this.httpGet("consumer-by-query", this.resolveRequestParams(data));
    }

    /**
     * 添加企业用户
     * @param data
     * @returns
     */
    postEnterprise(data) {
        return this.httpPost("post-enterprise", data);
    }

    /**
     * 添加个人用户
     * @param data
     * @returns
     */
    postPersonal(data) {
        return this.httpPost("post-personal", data);
    }
}
export const consumerService = resolveService(ConsumerHttpService);

/**
 * 政策标签项
 */
export class PolicySubscribeHttpService extends HttpService {
    constructor() {
        super("policy/policy-subscribe");
    }

    /**
     * 获取当前用户已配置的订阅
     * @returns
     */
    getByUser() {
        return this.httpGet("by-user");
    }

    /**
     * 获取当前用户政策订阅列表
     * @param data
     * @returns
     */
    getSubscribePaged(data?) {
        return this.httpGet("subscribe-paged", this.resolveRequestParams(data));
    }
}

export const policySubscribeService = resolveService(PolicySubscribeHttpService);

/**
 * 政策标签项
 */
export class SpecialHttpService extends HttpService {
    constructor() {
        super("special/special");
    }

    /**
     * 获取当前复杂版政策申报列表
     * @param data
     * @returns
     */
    getSpecialDetailList(data?) {
        return this.httpGet("special-detail-list", this.resolveRequestParams(data));
    }

    /**
     * 检查申报频率
     * @param id
     * @returns
     */
    checkApplyFrequency(id: any, data?) {
        return this.httpGet(`check-apply-frequency/${id}`, this.resolveRequestParams(data));
    }
}

export const policySpecialService = resolveService(SpecialHttpService);
