/****** 无数据 ********/

import React from "react";
import { NoData } from "@reco-w/core-ui";
import { IComp } from "@reco/util-func";

interface IProps extends IComp {
    data?: any[];
    /** 暂无数据的描述 */
    description?;
    /** 是否有边框 */
    border?: boolean;
}
export function UtilNoDataWrapper({ data = [], description, border, className = "", children }: IProps) {
    return (
        <>
            {data.length > 0 ? (
                children
            ) : (
                <div
                    className={`pt20 ${className}`}
                    style={{
                        height: "100%",
                        border: border ? "1px solid #f0f0f0" : undefined,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <NoData.Component description={description} />
                </div>
            )}
        </>
    );
}
