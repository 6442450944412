import { useCallback, useEffect } from "react";
import { formatDateTime } from "@reco-m/core";
import { Row, Col, Modal, Button, Typography, Checkbox } from "antd";

import { LocalStorageEx, OClassName, OFile, OGo, OSingle, checkOverTimes, formatDateStartEnd } from "@reco/util-func";
import moment from "moment";

export namespace SignModal {
    /** 报名弹窗 */
    export function Comp({ that, otherObj, isSelect = false }) {
        const { showModal, signArr, typeRange = [], single = {} } = otherObj.data;

        const disabled = signArr.length === 0;

        const close = useCallback(() => {
            otherObj.update({ showModal: false });
        }, []);

        useEffect(() => {
            Promise.all([OSingle.api.getSingle(), OSingle.api.getSubMeetings()]).then(([single, typeRange]) => {
                otherObj.update({ single: single.activityVM, typeRange });
            });
        }, []);

        return (
            <Modal
                wrapClassName="home-sign-modal-wrapper"
                title="参会报名列表"
                visible={showModal}
                onCancel={close}
                footer={
                    isSelect
                        ? [
                            <Button key="1" onClick={close}>
                                关闭
                            </Button>,
                            <Button
                                key="3"
                                type="primary"
                                onClick={() => {
                                    LocalStorageEx.set({ attr: "signArr", value: signArr });
                                    close();
                                }}
                            >
                                确定
                            </Button>,
                        ]
                        : [
                            <Button key="1" onClick={() => {
                                LocalStorageEx.set({ attr: "signArr", value: signArr });
                                close();
                            }} type="ghost">
                                关闭
                            </Button>,
                            <Button
                                key="2"
                                type="primary"
                                disabled={disabled}
                                onClick={() => {
                                    LocalStorageEx.set({ attr: "signArr", value: signArr });
                                    close();
                                }}
                            >
                                暂存
                            </Button>,
                            <Button
                                key="3"
                                type="primary"
                                disabled={disabled}
                                onClick={() => {
                                    let overtimes = (signArr || []).map((t: any) => [formatDateTime(t.startTime), formatDateTime(t.endTime)]);
                                    let result = checkOverTimes(overtimes);
                                    if (result) {
                                        Modal.confirm({
                                            title: "提示",
                                            content: "您选择的活动在同一时段，请确认是否继续报名",
                                            onOk: () => {
                                                LocalStorageEx.set({ attr: "signArr", value: signArr });
                                                OGo.goApply({ that });
                                            },
                                            onCancel() { },
                                            cancelText: "取消",
                                            okText: "确定",
                                        });
                                    } else {
                                        LocalStorageEx.set({ attr: "signArr", value: signArr });
                                        OGo.goApply({ that });
                                    }
                                }}
                            >
                                立即报名
                            </Button>,
                        ]
                }
            >
                <div className="size-16">主论坛</div>
                <SignItem that={that} item={single} otherObj={otherObj} />
                {typeRange
                    .filter((x) => x.items?.length > 0)
                    .map((item, i) => (
                        <div key={i}>
                            <div className="size-16 mt20">{item.tagName}</div>
                            <Row className="" gutter={10}>
                                {/* <div className="primary-color size-18">{item.dayFormat}</div> */}
                                {item.items.map((t, j) => (
                                    <Col xs={24} lg={6} className="home-tissue-item type2" key={j}>
                                        <SignItem that={that} item={t} otherObj={otherObj} key={j} />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    ))}

            </Modal>
        );
    }

    export function SignItem({ that, item, otherObj }) {
        const { signArr } = otherObj.data;
        const idx = signArr.findIndex((x) => x.id === item.id);
        const isSingle = !item.organizerUndertake;
        let isBetweenTime = moment().isBetween(moment(item.applyStartTime), moment(item.applyEndTime));
        // isBetweenTime = true; // 有bug 接口没返回applyStartTime
        const disabled = item.isAreadyApply || !isBetweenTime || !item.r2323IsApply;
        return (
            <div className={`sign-item`}>
                <Checkbox
                    checked={item.isAreadyApply || idx > -1}
                    disabled={disabled}
                    onChange={(e) => {
                        if (e.target.checked) signArr.push(item);
                        else signArr.splice(idx, 1);
                        otherObj.update({ signArr });
                    }}
                >
                    <Typography.Paragraph ellipsis={{ rows: 1, expandable: false }} className="mb0 size-16 primary-color" title={item.activityName}>
                        {item.activityName}
                    </Typography.Paragraph>
                    {isSingle ? (
                        <>
                            <Typography.Paragraph ellipsis className="mb0 size-12 white-color mt5">
                                <span className="white6">时间：</span>
                                {item.r2323Forum?.replace("时间：2023年", "")}
                            </Typography.Paragraph>
                            <Typography.Paragraph ellipsis className="mb0 size-12 white-color mt5">
                                <span className="white6">地点：</span>
                                {item.address}
                            </Typography.Paragraph>
                        </>
                    ) : (
                        <>
                            {/* <span className="white6">承办单位：</span>
                                {item.organizerUndertake} */}
                            <Typography.Paragraph ellipsis className="mb0 size-12 white-color mt5">
                                <span className="white6">时间：</span>
                                {formatDateStartEnd(item.startTime, item.endTime, "MM月dd日")}
                            </Typography.Paragraph>
                            <Typography.Paragraph className="mb0 size-12 white-color mt5 flex-align start">
                                <span className="white6">地点：</span>
                                <div className="flex-item">
                                    <div className="omit omit-2">{item.activityAddress}</div>
                                </div>
                            </Typography.Paragraph>
                            <Button type="primary"
                                className="mt10 custom-btn2"
                                onClick={() => {
                                    // window.open(`${that.parkCode}/summitmeeting/meeting/${item.id}`)
                                    otherObj.update({ showModal: false });
                                    that.dispatch({ type: "input", data: { meetShowModal: true, meetId: item.id } })
                                }
                                }>查看详情</Button>
                        </>
                    )}

                </Checkbox>
                <SignedIcon item={item} />
            </div>
        );
    }

    export function SignedIcon({ item, center = false }) {
        if (!item.isAreadyApply) return null;
        center = center || !item.organizerUndertake;

        return <img className={OClassName.getStr({ [`signed-icon${center ? "-center" : ""}`]: true })} src={OFile.getAssetsImagesPro("signed.png")} />;
    }
}
