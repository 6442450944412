import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getCurrentParkId } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces, SearchBaseEffects } from "@reco-w/common-models";
import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";
import { policyDeclareService, policyService } from "@reco-w/policy-service";
import { PolicyTabIndexEnum, Namespaces } from "./common";

export namespace newPolicyModel {
    export const namespace = Namespaces.newpolicy;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        ...SearchBaseEffects,

        *initPage({ message, props }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getDeclareMode", message });
                yield put({ type: "getDataByUrl", props, message });
                yield put({ type: "getDeclareCount", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getDataByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl([CommonSearchParamInUrlEnum.tabIndex], props);

                yield put({ type: "input", data: { tabIndex: params[CommonSearchParamInUrlEnum.tabIndex] || PolicyTabIndexEnum.declare } });
            } catch (e) {
                yield call(message!.error, "getDataByUrl：" + e.errmsg);
            }
        },

        /**
         * 获取申报类型
         * @param { message }
         * @param { call, put }
         */
        *getDeclareMode({ message }, { call, put }) {
            try {
                const config = yield call(policyService.getConfig);
                const unitConfig = config.unitConfig;
                const declareMode = unitConfig?.declareMode;
                yield put({ type: "input", data: { declareMode: declareMode} });
            } catch (e) {
                yield call(message!.error, "getDeclareMode：" + e.errmsg);
            }
        },

        /**
         * 获取可申报实施细则数量
         * @param { message }
         * @param { call, put }
         */
        *getDeclareCount({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message, isRefreshUser: true });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    certifyDetail = memberCommonState.member;

                const result = yield call(policyDeclareService.getDeclareCount, {
                    parkId: getCurrentParkId(),
                    isValid: true,
                    isPublish: true,
                    detailIsAllowDeclare: true,
                    customerId: certifyDetail?.companyId,
                });
                yield put({ type: "input", data: { declareCount: result || 0 } });
            } catch (e) {
                yield call(message!.error, "getDeclareCount：" + e.errmsg);
            }
        },

        /**
         * 获取政策列表
         * @param { message, data }
         * @param { call, put }
         */
        *getPolicy({ message, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const result = yield call(policyService.getPolicyAndImplementation, data);

                if (result.items) {
                    yield put({ type: "input", data: { policyData: result.items, totalItems: result.totalItems && Number(result.totalItems) } });
                }
            } catch (e) {
                yield call(message!.error, "getPolicy：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(newPolicyModel);
