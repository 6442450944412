import { EffectsMapObject, routerRedux } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";

import { CoreState } from "@reco-m/core";
import { app, getParkCode } from "@reco-w/core-ui";

import { consumerDetailModel as oldConsumerDetailModel, ConsumerTypeEnum } from "@reco-w/declare-models";
import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { policyService } from "@reco-w/policy-service";
import { Namespaces, PolicyDeclareModeEnum } from "./common";

export namespace consumerDetailModel {
    export const namespace = Namespaces.iparkConsumerDetail;
    export const state: any = freeze({ ...CoreState, showloading: true }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...oldConsumerDetailModel.reducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...oldConsumerDetailModel.effects,

        *initPage({ message, callback }, { put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getDeclareMode", message });
                yield put({ type: "getCurrentConsumer", message, callback });
            } catch (e) {
                message!.error(`${e?.errmsg || e}`);
            }
        },

        /**
         * 获取申报类型
         * @param { message }
         * @param { call, put }
         */
        *getDeclareMode({ message }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const config = yield call(policyService.getConfig);
                const unitConfig = config.unitConfig;
                const declareMode = unitConfig?.declareMode;
                if (declareMode !== PolicyDeclareModeEnum.complex) {
                    message?.error("模式错误");
                    yield put(routerRedux.replace(`/${getParkCode()}/my/myDeclare`));
                    yield put({ type: "hideLoading" });
                }
            } catch (e) {
                yield call(message!.error, "getDeclareMode：" + e.errmsg);
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取当前用户信息
         * @param param0
         * @param param1
         */
        *getCurrentConsumer({ message, callback }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.consumerCommon}/getCurrentConsumer`, message });
                const consumerCommonState: any = yield select((state) => state[commonNamespaces.consumerCommon]);
                let consumerDetail = consumerCommonState.consumerDetail;

                if (!consumerDetail) {
                    yield yield put({ type: `${commonNamespaces.consumerCommon}/getCurrentUser`, message });
                    const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                    const user = memberCommonState.user;
                    const currentUser = user.currentUser;
                    consumerDetail = { consumerType: ConsumerTypeEnum.personal, name: currentUser?.realName };
                }

                yield put({ type: "input", data: { detail: consumerDetail } });

                if (callback) {
                    yield call(callback, consumerDetail);
                }
            } catch (e) {
                yield call(message!.error, "getCurrentConsumer：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(consumerDetailModel);
