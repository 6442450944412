import { isAuth } from "@reco-m/core";
import { OModal } from "./modal";
import { LocalStorageEx } from "./storage";
import { OSetting } from "./setting";
import { r2323ActivityApplyService, r2323ActivityService } from "@reco-w/bi-home-service";
import { OTime } from "./formatTime";
import moment from "moment";
import { message } from "antd";
import { activityService } from "@reco-w/activity-service";
import { getCurrentParkId } from "@reco-m/core";
import { tagService } from "@reco-m/tag-service";
import { FORUM_TYPE_TAG_CODE } from "@reco-w/bi-home-models";
export namespace OGo {
    let isGoing = false;
    let isChecking = false;

    export function go({ url, that, pa = {} }) {
        if (isGoing) return;
        isGoing = true;
        const u = getGoUrl({ url, that, pa });
        that.goTo(u);
        isGoing = false;
    }

    export function getGoUrl({ url, that, pa }) {
        if (url.includes("?")) {
            const newPa = getParamObj(url);
            Object.assign(newPa, pa);
            return `${url.split("?")[0]}${getParamStr(newPa)}`;
        }
        return `/${that.parkCode}/${url}${getParamStr(pa)}`;
    }

    export function getParamStr(parmas) {
        let str = "";
        const arr: string[] = [];
        for (const key in parmas) {
            if (Object.prototype.hasOwnProperty.call(parmas, key)) {
                const v = parmas[key];
                if (v !== undefined && v !== "") {
                    arr.push(`${key}=${v}`);
                }
            }
        }

        if (arr.length > 0) {
            str = `?${arr.join("&")}`;
        }
        return str;
    }
    /** 解析查询参数 */
    export function getParamObj(url = location.href, attr?: string) {
        const arr = url.split("?"),
            res: any = {};
        if (!arr[1]) {
            return attr ? "" : res;
        }
        const tempArr = arr[1].split("#")[0].split("&");
        tempArr.forEach(function (item) {
            const query = item.split("=");
            res[decodeURIComponent(query[0])] = decodeURIComponent(query[1]);
        });

        if (attr) return res[attr];
        return res;
    }

    export function checkLogin({ that }) {
        if (isAuth()) {
            return true;
        } else {
            OModal.alert({ content: "您当前尚未登录，请先进行登录", okText: "去登录", onOk: () => goLogin({ that }) });
            return false;
        }
    }
    export function goLogin({ that }) {
        go({ that, url: "login" });
    }

    export async function checkCompany({ that }) {
        if (isChecking) return;
        isChecking = true;

        if (!checkLogin({ that })) {
            isChecking = false;
            return false;
        }

        await OSetting.companySet();
        if (LocalStorageEx.getLocalStorage("companyName")) {
            const member = LocalStorageEx.get("member");
            if (!member?.r2323AgencyTypeValue) {
                OModal.alert({
                    content: "您未选择机构类型，请先补充信息", okText: "去维护",
                    onOk: () => this.goTo(`/${this.parkCode}/my/certifyDetail`)
                });
                isChecking = false;
                return false;
            }
            isChecking = false;
            return true;
        } else {
            const member = LocalStorageEx.get("member");
            if (member?.id) {
                OModal.alert({ content: "您当前认证尚未通过", okText: "查看", onOk: () => goCompany({ that }) });
            } else {
                OModal.alert({ content: "您当前尚未认证，请先进行认证", okText: "去认证", onOk: () => goCompany({ that }) });
            }
            isChecking = false;
            return false;
        }
    }
    export function goCompany({ that }) {
        const member = LocalStorageEx.get("member");
        if (member?.id) {
            go({ that, url: `my/certifyDetail` });
        } else {
            go({ that, url: "my/mycertifyform" });
        }
    }

    /** 是否报名工会 */
    export async function checkApply({ that }) {
        if (!checkCompany({ that })) return false;
        const isApply = await OSingle.api.getIsApply();
        if (isApply) return true;
        else {
            OModal.alert({
                content: "您当前尚未报名峰会",
                okText: "去报名",
                onOk: () => {
                    const single = OSingle.getSingleActivity();
                    if (moment().isBetween(moment(single.applyStartTime), moment(single.applyEndTime))) {
                        goApply({ that, id: OSingle.getId() });
                    } else {
                        setTimeout(() => {
                            message.error({ content: "不在报名时段内" });
                        }, 300);
                    }
                },
            });
            return false;
        }
    }

    export function goApply({ that, id, pa }: { that; id?; pa?}) {
        go({ that, url: `summitmeeting/form`, pa: { id, ...pa } });
    }
}

/** 峰会 */
export namespace OSingle {
    export const ser = r2323ActivityService;
    export const api = {
        getSingle() {
            return ser.getSingle({ customType: 0, languageType: client["languageType"], isValid: true });
        },

        async getDateRange(id = getId()) {
            const d = await ser.getDateRange(id);
            return d.map((x) => OTime.formatDate(x));
        },
        async getIsApply() {
            return await r2323ActivityApplyService.getIsApply({ activityId: getId(), inputerId: LocalStorageEx.getLocalStorage("userId"), customType: 1 });
        },

        // async getSubMeetings() {
        //     const result = await ser.getDateRange(getId());

        //     const arr = result.map(async (x) => {
        //         const d = await activityService.getPaged({
        //             pageSize: 20,
        //             isValid: true,
        //             status: 3,
        //             customTypes: [1, 2],
        //             languageType: client["languageType"],
        //             parkId: getCurrentParkId(),
        //             activityStartTime: formatDate(x, "yyyy-MM-dd"),
        //             activityEndTime: formatDate(x, "yyyy-MM-dd 23:59:59"),
        //         });
        //         return {
        //             day: x,
        //             dayFormat: moment(x).format("MM/DD ddd"),
        //             items: d.items,
        //         };
        //     });

        //     const dateRange = await Promise.all(arr);
        //     return dateRange;
        // },

        async getSubMeetings() {
            const result = await tagService.getTagByTagClass({
                tagClass: FORUM_TYPE_TAG_CODE,
                parkId: getCurrentParkId(),
            });

            const arr = result.map(async (x) => {
                const d = await activityService.getPaged({
                    pageSize: 80,
                    isValid: true,
                    status: 3,
                    customTypes: [1, 2],
                    languageType: client["languageType"],
                    parkId: getCurrentParkId(),
                    activityTypeValue: x?.tagValue || 0,
                    orderBy: "startTime asc,r2323AddressValue asc",
                });
                return {
                    tag: x,
                    tagName: x.tagName,
                    items: d.items,
                };
            });

            const typeRange = await Promise.all(arr);
            return typeRange;
        },
    };

    export async function getSingleApi() {
        const d = await ser.getSingle();
        LocalStorageEx.set({ attr: "single", value: d });
    }

    export function getSingle() {
        return LocalStorageEx.get("single") || {};
    }
    export function getSingleActivity(): { r2323Year; id; activityName; applyStartTime; applyEndTime; r2323GuestSequenceRemark } {
        return getSingle().activityVM || {};
    }
    export function getYear() {
        return getSingleActivity().r2323Year;
    }
    export function getId() {
        return getSingleActivity().id;
    }
}

export namespace OClassName {
    export function getStr(obj) {
        return Object.keys(obj)
            .filter((key) => obj[key])
            .join(" ");
    }
}

export namespace OTag {
    export const service = tagService;

    export async function getTag({ code, codes }: { code?; codes?}) {
        return await service[code ? "getTagByTagClass" : "getTagByTagClasses"]({
            tagClass: code || codes,
        });
    }

    export interface IGetTagData {
        codes;
        needAll?;
        allText?;
        callback?;
        tagValueAttr?;
    }
    export async function getTagData({ codes, needAll, allText = "不限", callback, tagValueAttr = "tagValue" }: IGetTagData) {
        const tagData = await getTag({ codes });

        if (callback) callback(tagData);

        Object.keys(tagData).forEach((key) => {
            const arr = tagData[key];
            arr.forEach((x) => Object.assign(x, { label: x.tagName, value: x[tagValueAttr] }));
            if (needAll) arr.unshift({ label: allText });
        });
        return tagData;
    }
}