import { EffectsMapObject } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { bankAccountService } from "@reco-w/declare-service";
import { Namespaces } from "./common";

export namespace bankAccountDetailModel {
    export const namespace = Namespaces.bankAccountDetail;
    export const state: any = freeze({ ...CoreState }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        /**
         * Init page
         * @param { message }
         * @param { call, put }
         */
        *initPage({ message, id }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getDetail", message, id });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取更新详情
         * @param { message, id }
         * @param { call, put }
         */
        *getDetail({ message, id }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                let detail = yield call(bankAccountService.get, id);

                yield put({ type: "input", data: { detail } });
            } catch (e) {
                yield call(message!.error, "getDetail：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(bankAccountDetailModel);
