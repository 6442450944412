export enum Namespaces {
    memberCommon = "memberCommon",
    cmsCommon = "cmsCommon",
    workorderCommon = "workorderCommon",
    configCommon = "configCommon",
    noticeCommon = "noticeCommon",
    consumerCommon = "consumerCommon",
}
/**
 * 工单状态枚举
 */
export enum WorkOrderStatusEnum {
    /**
     * 已取消
     */
    cancel = -5,

    /**
     * 待派单
     */
    noConfirm = 0,

    /**
     * 处理中
     */
    processing = 1,

    /**
     * 已退回
     */
    bounced = -1,

    /**
     * 已完成
     */
    completed = 5,

    /**
     * 待评价
     */
    noEvaluate = 6,

    /**
     * 已评价
     */
    evaluate = 7,
}

/**
 * 认证状态枚举
 */
export enum CertifyStatusEnum {
    /**
     * 全部审核状态
     */
    allStatus = 0,

    /**
     * 未认证
     */
    noCertify = 1,

    /**
     * 待审核
     */
    noConfirm = 2,

    /**
     * 审核通过
     */
    allow = 3,

    /**
     * 审核拒绝
     */
    bounced = 4,
}

export enum NotificationTypesEnum {
    unRead = "1",
}

export enum MailBoxTypeEnum {
    mailBox2 = 2,
}

export function getConfigValue(confgList: any[] = [], configCode) {
    if (!confgList || !confgList.length)
        return undefined;
    const config = confgList.find(c => c.configCode === configCode);

    return config ? config.configValue : undefined;
}
/**
 * app 配置映射
 */
export function mapAppSetConfig(configs) {
    let configList = [];

    Object.keys(configs).forEach(key => {
        configList = configList.concat(configs[key]);
    });

    return {
        footerHtml: getConfigValue(configList, 'APPLICATION_WEBFOOTERHTML'),
        gaudesWebKey: getConfigValue(configList, 'GAUDE_H5KEY'),
        gaudesWebOpen: !!getConfigValue(configList, 'GAUDE_H5KEY'),
        privacyPolicy: getConfigValue(configList, 'AGREEMENT_PRIVACYPOLICY'),
        serviceAgreement: getConfigValue(configList, 'AGREEMENT_SERVICE'),
        webName: getConfigValue(configList, 'APPLICATION_WEBNAME'),
        webShare: getConfigValue(configList, 'APPLICATION_WEBSHARE'),
        webLogo: `${server.url}attach/picture/render?id=${getConfigValue(configList, 'APPLICATION_WEBLOGO')}`,
        appDown: getConfigValue(configList, 'ENVIRONMENT_APPDOWN'),
    };
}