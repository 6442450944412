import { EffectsMapObject, routerRedux } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, setLocalStorage, getCurrentParkId } from "@reco-m/core";
import { app, getParkCode } from "@reco-w/core-ui";

import { Namespaces as menuNamespaces } from "@reco-w/my-menumodels";
import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberService, memberCompanyService } from "@reco-w/member-service";
import { IS_GET_ORDER_ON_HEADER } from "@reco-w/common-common";

import { Namespaces, operateSourceEnum } from "./common";
import { authService } from "@reco-w/auth-service";

export namespace certifyDetailModel {
    export const namespace = Namespaces.certifyDetail;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, callback, certifyId, parkId }, { call, put }) {
            put({ type: "showLoading" });
            try {
                yield put({ type: "initState" });
                yield put({ type: "getCertify", certifyId, parkId, message, callback });
                yield put({ type: "getMemberConfig", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取会员配置信息
         */
        *getMemberConfig({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.configCommon}/getMemberConfig`, message });
                const configCommonState: any = yield select((state) => state[commonNamespaces.configCommon]),
                    memberConfig = configCommonState!.memberConfig;

                yield put({ type: "input", data: { memberConfig } });
            } catch (e) {
                yield call(message!.error, "getMemberConfig：" + e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSwitching: false } });
            }
        },

        /**
         * 获取认证信息
         */
        *getCertify({ message, callback, certifyId, parkId, isRefreshMember }, { call, put, select }) {
            try {
                let memberList: any[] = [],
                    certifyDetail;
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message, isRefreshMember });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                if (parkId) {
                    const user = memberCommonState?.user?.currentUser;
                    memberList = yield call(memberService.getMember, user?.id, parkId);
                    certifyDetail = memberList?.length > 0 && memberList[0];
                } else {
                    memberList = memberCommonState!.memberList;
                    certifyDetail = memberCommonState.member;
                }

                if (certifyId) {
                    certifyDetail = memberList?.find((x) => x.id === certifyId);
                    if (!certifyDetail) {
                        message?.error("未找到该认证");
                        yield put(routerRedux.replace(`/${getParkCode()}/my`));
                        return;
                    }
                }

                const selfMemberList = memberList?.filter((x) => x.companyId !== certifyDetail?.companyId) || [];

                yield put({ type: "input", data: { certifyDetail: certifyDetail, memberList: selfMemberList, memberCount: memberList?.length || 0 } });
                if (callback) {
                    yield call(callback!, certifyDetail);
                }
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSwitching: false } });
            }
        },
        /**
         * 取消绑定企业
         */
        *cancelBindCompany({ message, id, parkId, callback }, { put, call }) {
            try {
                const CancelRes = yield call(memberService.certifyCancel, id, operateSourceEnum.web);

                if (!parkId || parkId === getCurrentParkId()) {
                    yield yield put({ type: `${commonNamespaces.memberCommon}/cleanMemberInfo`, message, isCleanAll: true });
                    yield yield put({ type: `getCertify`, message });
                }
                yield call(callback!, CancelRes);
            } catch (e) {
                yield call(message!.error, e.errmsg);
            }
        },
        /**
         * 企业解绑
         */
        *unBindCompany({ id, parkId, accountId, callback, errorback, message, showUnbindError }, { put, call }) {
            try {
                const unBindRes = yield call(memberService.unbindCertify, id, operateSourceEnum.web);
                const user = yield call(authService.getCurrentUser);
                const userId = user?.currentUser?.id;
                if (accountId === userId && (!parkId || parkId === getCurrentParkId())) {
                    yield yield put({ type: `${commonNamespaces.memberCommon}/cleanMemberInfo`, message, isCleanAll: true });
                    yield yield put({ type: `getCertify`, message });
                }

                yield call(callback!, unBindRes);
            } catch (e) {
                if (e?.errcode === "COMPANY_UNBIND_ERROR") {
                    if (showUnbindError) {
                        yield call(message!.error, "企业绑定错误，该用户为企业的唯一管理员，无法进行解绑");
                    } else {
                        yield put({ type: `getSelectCustomer`, errorback, message });
                        yield put({ type: `input`, data: { companyEmployee: e?.errmsg || "0" } });
                    }
                } else {
                    yield call(message!.error, e.errmsg);
                }
            }
        },
        /**
         * 获取企业列表
         */
        *getSelectCustomer({ errorback, message }, { call, put, select }) {
            try {
                const state: any = yield select((state) => state[Namespaces.certifyDetail]),
                    certifyDetail = state!.certifyDetail;
                let params = {
                    pageIndex: 1,
                    pageSize: 999,
                    companyId: certifyDetail.companyId,
                };
                let data = yield call(memberCompanyService.getUserPage, params);
                yield put({ type: "input", data: { CustomerList: data && data.items } });
                errorback && errorback(data.errmsg);
            } catch (e) {
                yield call(message!.error, e.errmsg);
            }
        },
        /**
         * 设为企业管理员
         */
        *setUserManager({ message, companyUser, parkId, callback }, { call, put, select }) {
            try {
                yield put({ type: "input", data: { isSubmit: true } });
                const state: any = yield select((state) => state[Namespaces.certifyDetail]),
                    certifyDetail = state!.certifyDetail;
                let result = yield call(memberCompanyService.setUserManager, {
                    companyId: certifyDetail.companyId,
                    accountId: companyUser.key,
                    oldAccountId: certifyDetail.accountId,
                });
                if (!parkId || parkId === getCurrentParkId()) {
                    yield yield put({ type: `${commonNamespaces.memberCommon}/cleanMemberInfo`, message, isCleanAll: true });
                    yield yield put({ type: `getCertify`, message });
                }
                callback && callback(result);
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubmit: false } });
            }
        },

        /**
         * 切换企业
         */
        *switchCompany({ message, callback }, { call, put, select }) {
            try {
                yield put({ type: "input", data: { isSwitching: true } });
                const state: any = yield select((state) => state[Namespaces.certifyDetail]),
                    selectedSwitchCompany = state!.selectedSwitchCompany;

                setLocalStorage("memberAuthenticationId", selectedSwitchCompany?.id);
                setLocalStorage("companyName", selectedSwitchCompany?.companyName);
                setLocalStorage("companyId", selectedSwitchCompany?.companyId);

                yield yield put({ type: `${commonNamespaces.memberCommon}/switchCertify`, message });
                yield yield put({ type: `getCertify`, message, callback, isRefreshMember: true });
                yield yield put({ type: `${menuNamespaces.myMenu}/getCertify`, isgetOrder: IS_GET_ORDER_ON_HEADER, message });
            } catch (e) {
                yield put({ type: "input", data: { isSwitching: false } });
                yield call(message!.error, "switchCompany：" + e.errmsg);
            }
        },
    };
}
app.model(certifyDetailModel);
