import { EffectsMapObject } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { consumerService } from "@reco-w/declare-service";

import { ConsumerTypeEnum, Namespaces } from "./common";

export namespace consumerEditModel {
    export const namespace = Namespaces.consumerEdit;
    export const state: any = freeze({ ...CoreState }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...CoreReducers,
        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ message }, { put }) {
            put({ type: "showLoading" });
            try {
                yield put({ type: "getTagMultiple", data: "CONSUMER/NASSWS,CONSUMER/QIYJSLY,CONSUMER/DANWXZ", message });
            } catch (e) {
                message!.error(`${e?.errmsg || e}`);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /** 获取用户详情 */
        *getCurrentConsumer({ message, callback }, { call, put }) {
            try {
                const result = yield call(consumerService.getCurrentConsumer);
                yield put({ type: "input", data: { detail: result, ...result } });
                callback && callback(result);
            } catch (e) {
                message!.error(`${e?.errmsg || e}`);
            }
        },
        /** 修改用户信息 */
        *modifyClient({ message, id, data, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                if (data && data.consumerType === ConsumerTypeEnum.enterprise) {
                    yield call(consumerService.putEnterprise, id, data);
                } else {
                    yield call(consumerService.putPersonal, id, data);
                }
                yield call(callback!);
            } catch (e) {
                message!.error(`${e?.errmsg || e}`);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /** 获取标签信息 */
        *getTagMultiple({ data, message }, { put, call }) {
            try {
                const tagClass = yield call(tagService.getTagByTagClasses, { tagClass: data });
                yield put({ type: "input", data: { tagClass } });
            } catch (e) {
                message!.error(`${e?.errmsg || e}`);
            }
        },
    };
}
app.model(consumerEditModel);
