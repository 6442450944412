import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, getCurrentUnitId } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { setMapScript } from "@reco-w/common-common";
import { configService } from "@reco-w/common-service";

import { getConfigValue, mapAppSetConfig, Namespaces } from "./common";

export namespace configCommonModel {
    export const namespace = Namespaces.configCommon;

    export const state: any = freeze({}, !0);

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        *initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        /**
         * 获取高德配置
         * @param { message }
         * @param { call }
         */
        *getAMapConfig({ message }, { call, put, select }) {
            try {
                // const state = yield select((state) => state[Namespaces.configCommon]);
                // let appConfig = state!.appConfig;
                yield yield put({ type: "getAppConfig", message });
                const state = yield select((state) => state[Namespaces.configCommon]);
                let appConfig = state!.appConfig;
                if (!appConfig) {
                    const configList = yield call(configService.getConfigGroups, {
                        configGroup: "APPSET/APPLICATION,APPSET/GAUDE,APPSET/AGREEMENT",
                        unitId: getCurrentUnitId(),
                    });
                    appConfig = mapAppSetConfig(configList);
                    yield put({ type: "input", data: { appConfig } });
                }

                
                const key = appConfig?.gaudesWebKey;

                if (appConfig?.gaudesWebOpen && key) {
                    setMapScript(key);
                }

                yield put({ type: "input", data: { isShowMap: appConfig?.gaudesWebOpen } });
            } catch (e) {
                yield call(message!.error, "getAMapConfig：" + e.errmsg);
            }
        },

        /**
         * 获取配置
         * @param { message }
         * @param { call }
         */
        *getAppConfig({ message }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.configCommon]);
                let appConfig = state!.appConfig;
                if (!appConfig) {
                    const configList = yield call(configService.getConfigGroups, {
                        configGroup: "APPSET/APPLICATION,APPSET/GAUDE,APPSET/AGREEMENT,APPSET/ENVIRONMENT",
                        unitId: getCurrentUnitId(),
                    });
                    appConfig = mapAppSetConfig(configList);
                    if (client["title"] !== appConfig?.webName) {
                        client["title"] = appConfig?.webName;
                        if (client["title"]) {
                            document.title = client["title"];
                        }
                    }

                }

                yield put({ type: "input", data: { appConfig } });
            } catch (e) {
                yield call(message!.error, "getAppConfig：" + e.errmsg);
            }
        },

        /**
         * 获取会员配置
         * @param { message }
         * @param { call }
         */
        *getMemberConfig({ message }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.configCommon]);
                let config = state!.memberConfig;
                if (!config) {
                    const configList = yield call(configService.getConfigGroup, { configGroup: "MEMBER/BASE", unitId: getCurrentUnitId() });
                    config = {
                        parkEnterpriseCertifiedMaxNum: +(getConfigValue(configList, "MEMBER_ENTERPRISECERTIFIEDMAXNUM") || "1"),
                    };
                }

                const isOnlyAllowOneMember = config?.parkEnterpriseCertifiedMaxNum === 1;

                yield put({ type: "input", data: { memberConfig: config, isOnlyAllowOneMember } });
            } catch (e) {
                yield call(message!.error, "getAppConfig：" + e.errmsg);
            }
        },
    };
}

app.model(configCommonModel);
