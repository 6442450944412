import { HttpService, resolveService } from "@reco-m/core";

/**
 * 申报项目银行账户变更记录管理
 */
export class BankAccountHttpService extends HttpService {
    constructor() {
        super("special/projectaccountlog");
    }
}
export const bankAccountService = resolveService(BankAccountHttpService);
