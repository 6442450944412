// 我的左侧导航栏

import React from "react";
import { router } from "dva";

import { Menu, Col, Badge, Drawer } from "antd";
import { template, setLocalStorage, getLocalStorage } from "@reco-m/core";
import { ViewComponent } from "@reco-w/core-ui";

import { WorkOrderSearchParamInUrlEnum } from "@reco-w/common-common";
import { CertifyStatusEnum } from "@reco-w/common-models";
import { MemberRoleNameBuiltInEnum } from "@reco-w/member-models";
import { InstitutionStatusEnum } from "@reco-w/market-models";
import { PolicyDeclareModeEnum } from "@reco-w/policy-models";
import { Namespaces } from "@reco-w/my-menumodels";
import { mymenuModel } from "@reco-w/my-menumodels-re";
import { WorkOrderTypeEnum } from "@reco-w/my-service";
import { getMyMenu } from "./common";

const { SubMenu } = Menu;
export namespace Mymenu {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> { }

    export interface IState extends ViewComponent.IState, mymenuModel.StateType { }
    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.myMenu;

        componentMount() {
            !this.isAuth() && this.goTo(`/${this.parkCode}/login`);
        }

        componentDidMount() {
            this.dispatch({ type: "initPage" });
            // 手机端
            this.menuState();
            $(window).resize(() => {
                this.menuState();
            });
        }
        menuState() {
            let wh: any = $(window).width();
            if (wh < 1200) {
                this.dispatch({ type: "input", data: { menuBoolean: true } });
            } else {
                this.dispatch({ type: "input", data: { menuBoolean: false } });
            }
        }

        /**
         * 获取目录的key值
         * @returns
         */
        getMenuSelectedKeys() {
            const mySelectedMenuText = getLocalStorage("mySelectedMenu"),
                mySelectedMenus = mySelectedMenuText && mySelectedMenuText.split("?");
            let mySelectedMenu = mySelectedMenus && mySelectedMenus.length > 0 && mySelectedMenus[0];

            !mySelectedMenuText && setLocalStorage("mySelectedMenu", "/my");

            return mySelectedMenu ? [mySelectedMenu] : ["/my"];
        }

        /**
         * 渲染 需要管理员身份的 目录
         * @param child
         * @param url
         * @returns
         */
        renderAdminItem(child, url) {
            return (
                <Menu.Item key={child.key}>
                    <router.Link to={`/${this.parkCode}${url}`}></router.Link>
                    <i className={"icon " + child.icon} />
                    <span>{child.title}</span>
                </Menu.Item>
            );
        }

        /**
         * 渲染 普通的 单条目录
         * @param child
         * @param url
         * @returns
         */
        renderCommonMenuItem(child, url) {
            const { state } = this.props;
            return (
                <Menu.Item key={child.key}>
                    <router.Link to={`/${this.parkCode}${url}`}></router.Link>
                    <i className={"icon " + child.icon} />
                    <span>{child.title}</span>
                    {child.badgeMap && (
                        <span className="margin-left-xs">
                            <Badge count={state![child.badgeMap] || 0}></Badge>
                        </span>
                    )}
                </Menu.Item>
            );
        }

        /**
         * 获取是否展示
         * @param child
         * @returns
         */
        getIsShowMenuItem(child) {
            const { state } = this.props,
                declareMode = state!.declareMode;
            if (child?.special === "declareMode") {
                if (declareMode && declareMode !== PolicyDeclareModeEnum.none) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }

        /**
         * 渲染单条目录
         * @param child
         * @returns
         */
        renderMenuItem(child) {
            const { state } = this.props,
                member = state!.member,
                checkOrderId = state!.checkOrderId,
                checkStateId = state!.checkStateId;
            let url = child.url;

            let isShow = this.getIsShowMenuItem(child);

            if (child.special) {
                if (child.special === "checkOrder") {
                    url = checkOrderId
                        ? `/my/myproject?${WorkOrderSearchParamInUrlEnum.specialType}=${WorkOrderTypeEnum.checkIn}&id=${checkOrderId}&${WorkOrderSearchParamInUrlEnum.stateId}=${checkStateId}`
                        : `/workorder/ruzsq`;
                } else if (child.special === "institution") {
                    return this.renderInstitution(child, member, isShow);
                }
            }

            return child.isAdminCertify
                ? member && member.companyUserTypeName === MemberRoleNameBuiltInEnum.manager && member.status === CertifyStatusEnum.allow && this.renderAdminItem(child, url)
                : isShow && this.renderCommonMenuItem(child, url);
        }

        /**
         * 渲染服务机构
         * @param child
         * @param member
         * @param isShow
         * @returns
         */
        renderInstitution(child, member, isShow) {
            const { state } = this.props,
                isOnlyAllowOneMember = state!.isOnlyAllowOneMember,
                institution = state!.institution;
            const { serviceInstitutionBasicFormVM: insBasic = {} } = institution || {};

            const url = [InstitutionStatusEnum.waitAudit, InstitutionStatusEnum.pass, InstitutionStatusEnum.notPass].contains(insBasic.status) ? `/my/mymarket` : `/market/all`;
            if (isOnlyAllowOneMember) {
                return child.isAdminCertify
                    ? member && member.companyUserTypeName === MemberRoleNameBuiltInEnum.manager && member.status === CertifyStatusEnum.allow && this.renderAdminItem(child, url)
                    : isShow && this.renderCommonMenuItem(child, url);
            } else {
                return typeof insBasic?.status !== "undefined" && isShow && this.renderCommonMenuItem(child, url);
            }
        }

        /**
         * 渲染单条展开目录
         * @param item
         * @returns
         */
        renderSubMenuItem(item) {
            return (
                <SubMenu key={item.key} icon={<i className={"icon " + item.icon} />} title={item.title}>
                    {item.children.map((child) => this.renderMenuItem(child))}
                </SubMenu>
            );
        }

        /**
         * 点击目录时
         * @param e
         */
        onClickMenu(e) {
            if (e?.keyPath?.length > 1) {
                this.dispatch({ type: "input", data: { openKey: [e.keyPath[1]] } });
            } else {
                this.dispatch({ type: "input", data: { openKey: null } });
            }
        }

        /**
         * 改变展开时
         * @param e
         */
        onChangeOpen(e) {
            this.dispatch({ type: "input", data: { openKey: e, isClick: true } });
        }

        /**
         * 获取目录的展开关键字
         * @returns
         */
        getMenuOpenKeys() {
            const { state } = this.props,
                isClick = state!.isClick;
            let openKey = state!.openKey || [];

            if (!openKey.length && !isClick) {
                const mySelectedMenu = this.getMenuSelectedKeys()[0];
                const myMenu = getMyMenu();

                if (myMenu.some((x) => x.key === mySelectedMenu)) {
                    openKey = [];
                } else {
                    for (let i = 0; i < myMenu.length; i++) {
                        const child: any = myMenu[i].children;
                        if (child?.length && child.some((x) => x.key === mySelectedMenu)) {
                            openKey = [myMenu[i].key];
                            break;
                        }
                    }
                }
            }

            return openKey;
        }

        showDrawer = () => {
            this.dispatch({ type: "input", data: { menuVisible: true } })
        };
        onClose = () => {
            this.dispatch({ type: "input", data: { menuVisible: false } })
        };

        render() {
            const myMenu = getMyMenu();
            const { state } = this.props,
                menuVisible = state!.menuVisible,
                menuBoolean = state!.menuBoolean;
            return (
                <Col xs={24} sm={24} xl={5} className="gutter-row">
                    <div className="phone-menu" onClick={this.showDrawer}><i className="icon icongengduo" /></div>
                    {
                        menuBoolean ? <Drawer
                            className="menu-drawer"
                            title=""
                            placement="left"
                            closable={false}
                            onClose={this.onClose}
                            visible={menuVisible}
                        >
                            <div className="side-box">
                                <div className="side-box-title">
                                    <img src="assets/images/shengwu/center-title.png" />
                                </div>
                                <Menu
                                    onClick={(e) => this.onClickMenu(e)}
                                    onOpenChange={(e) => this.onChangeOpen(e)}
                                    openKeys={this.getMenuOpenKeys()}
                                    className="my-menu"
                                    selectedKeys={this.getMenuSelectedKeys()}
                                    mode="inline"
                                    theme="light"
                                >
                                    {myMenu?.length > 0 && myMenu.map((item: any) => (item.children?.length > 0 ? this.renderSubMenuItem(item) : this.renderMenuItem(item)))}
                                </Menu>
                            </div>
                        </Drawer> : <>
                            <div className="side-box">
                                <div className="side-box-title">
                                    <img src="assets/images/shengwu/center-title.png" />
                                </div>
                                <Menu
                                    onClick={(e) => this.onClickMenu(e)}
                                    onOpenChange={(e) => this.onChangeOpen(e)}
                                    openKeys={this.getMenuOpenKeys()}
                                    className="my-menu"
                                    selectedKeys={this.getMenuSelectedKeys()}
                                    mode="inline"
                                    theme="light"
                                >
                                    {myMenu?.length > 0 && myMenu.map((item: any) => (item.children?.length > 0 ? this.renderSubMenuItem(item) : this.renderMenuItem(item)))}
                                </Menu>
                            </div>
                        </>
                    }
                </Col>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.myMenu]);
}
