import React from "react";
import { Pagination } from "antd";

import { changePage } from "./utils";

export interface IPageMapObj {
    currentPage?: string;
    pageSize?: string;
    totalItems?: string;
}

export function renderPagination(self, pageSizeOptions: any[] = ["6", "9", "12", "15", "18", "21", "24"], mapObj?: IPageMapObj) {
    let { state } = self.props;
    const totalItems = Number(state![mapObj?.totalItems || "totalItems"] || 0),
        currentPage = Number(state![mapObj?.pageSize || "currentPage"] || 1),
        pageSize = Number(state![mapObj?.currentPage || "pageSize"] || pageSizeOptions[0]);

    return (
        <Pagination
            showSizeChanger={true} // 是否可以改变 pageSize
            showQuickJumper={true} // 是否可以快速跳转至某页
            onShowSizeChange={(currentPage, pageSize) => changePage(currentPage, pageSize, self)} // pageSize 变化的回调
            onChange={(currentPage, pageSize) => changePage(currentPage, pageSize, self)} // 页码改变的回调，参数是改变后的页码及每页条数
            current={currentPage} // 默认的页码
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            total={totalItems} // 数据总数
        />
    );
}
