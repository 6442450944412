import { freeze } from "immer";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { app } from "@reco-w/core-ui";
import { CoreEffects, CoreState, CoreReducers, getLocalStorage, removeLocalStorage, formatDateTime, formatDate, getDate, getCurrentParkId } from "@reco-m/core";
import { tagService } from "@reco-m/tag-service";

import { IParkBindTableNameEnum } from "@reco-w/common-common";
import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { newCouponHttpService } from "@reco-w/coupon-service";
import { CouponStatusEnum } from "@reco-w/coupon-models";
import { myOrderService, resourceService, spaceService } from "@reco-w/order-service";

import { END_DATE_TIME_FORMAT, MaxDayBookingTypeEnum, Namespaces, OrderStatusEnum, ResourceTypeEnum, START_DATE_TIME_FORMAT, START_TIME } from "./common";
import { getPrice, getResourceName, getSceneCode, getTimeType, isRoom } from "./utils";

export namespace roomDetailModel {
    export const namespace = Namespaces.roomDetail;
    export const state: any = freeze({ ...CoreState, showloading: true }, !0);
    export type StateType = typeof state;

    const now = new Date();

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, id, resourceType, tagData, resourceDetailCallback, resourceStatusCallback }, { call, put }) {
            try {
                yield put({ type: "initState" });

                const isRoom = resourceType === ResourceTypeEnum.meetingRoom || resourceType === ResourceTypeEnum.venue;

                yield put({ type: "getUserInfo", message });
                yield put({ type: "getResourceStartDate", id, resourceType, message });
                yield put({ type: "getResourceDetailAction", id, resourceType, isRoom, callback: resourceDetailCallback, resourceStatusCallback, message });
                yield put({ type: "getRoomResource", resourceType, resourceId: id, message });
                yield put({ type: "getCouponPage", resourceType, message });
                if (isRoom) {
                    yield put({ type: "getTagByTagClassAction", data: tagData.data, map: tagData.map, message });
                }

                yield put({ type: "input", data: { isRoom } });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取资源详情
         */
        *getResourceDetailAction({ message, id, resourceType, isRoom, callback, resourceStatusCallback }, { call, put }) {
            try {
                const result = yield call(resourceService.get, id);
                if (result && !result.resource.isValid) {
                    message?.error("数据暂未发布");
                    history.go(-1);
                    return;
                }
                yield put({
                    type: `${commonNamespaces.cmsCommon}/getHeadJson`,
                    location: "/resource/" + getResourceName(resourceType),
                    title: result?.resource?.resourceName,
                    message,
                });
                yield put({ type: "getResourceSpaceLocationAction", spaceId: result && result.resource && result.resource.roomId });

                let roomprice = "";
                let roompriceUnit = "";
                let meetingroomOrVenuePrice = "";
                let roomPriceObj: any = {};
                if (result) {
                    roomPriceObj = result.price[0];
                    if (result.price.length === 1) {
                        if (result.price[0].price === 0) {
                            roomprice = "免费";
                            meetingroomOrVenuePrice = `免费`;
                        } else {
                            roomprice = `${result.price[0].price}`;
                            roompriceUnit = getPrice(result.price[0], "unit");
                            meetingroomOrVenuePrice = `<strong class="color-red">${result.price[0].price}</strong>${roompriceUnit}`;
                        }
                    } else if (result.price.length > 1) {
                        let minprice = result.price[0].price,
                            maxprice = result.price[0].price;
                        let unit = getPrice(result.price[0], "unit");
                        result.price.forEach((item) => {
                            if (roomPriceObj.price === 0) {
                                roomPriceObj = item;
                            } else if (item.price < roomPriceObj.price && item.price !== 0) {
                                roomPriceObj = item;
                            }

                            if (item.price > maxprice) {
                                maxprice = item.price;
                                unit = getPrice(item, "unit");
                            }
                            if (item.price < minprice) {
                                minprice = item.price;
                            }
                        });
                        roomprice = `${minprice}-${maxprice}`;
                        roompriceUnit = unit;
                        meetingroomOrVenuePrice = `<strong class="color-red">${minprice}-${maxprice}</strong>${unit}`;

                        result.price.forEach((p) => ((p.startTimeT = getDate("1998-01-01 " + p.startTime)), (p.endTimeT = getDate("1998-01-01 " + p.endTime))));
                        result.price.sort((a, b) => a.startTimeT.getTime() - b.startTimeT.getTime());
                    }
                }

                if (isRoom) {
                    const startDate = getLocalStorage("resourceStartDate") || formatDate(now, START_DATE_TIME_FORMAT);
                    const endDate = getLocalStorage("resourceEndDate") || formatDate(now, END_DATE_TIME_FORMAT);
                    yield put({
                        type: "getResourceStatusAction",
                        params: {
                            startDate: formatDate(startDate, START_DATE_TIME_FORMAT),
                            endDate: formatDate(endDate, END_DATE_TIME_FORMAT),
                            resourceType: resourceType,
                            roomId: result?.resource && [result?.resource.roomId],
                        },
                        detailid: id,
                        callback: resourceStatusCallback,
                        maxDayBookingType: result?.config?.maxDayBookingType,
                        message,
                    });
                }

                yield put({ type: "input", data: { detail: result, roomPriceObj, roomPrice: roomprice, meetingroomOrVenuePrice, roompriceUnit } });
                if (callback) {
                    yield call(callback, result, isRoom);
                }
            } catch (e) {
                yield call(message!.error, "getResourceDetailAction：" + e.errmsg);
            }
        },

        /**
         * 获取资源空间位置
         */
        *getResourceSpaceLocationAction({ message, spaceId }, { call, put }) {
            try {
                const result = yield call(spaceService.getLocation, spaceId);

                yield put({ type: "input", data: { spaceLocation: result } });
            } catch (e) {
                yield call(message!.error, "getResourceSpaceLocationAction：" + e.errmsg);
            }
        },

        /**
         * 获取资源开始预订日期
         */
        *getResourceStartDate({ message, resourceType }, { call, put }) {
            try {
                if (isRoom(resourceType)) {
                    const startDate = getLocalStorage("resourceStartDate") || formatDate(now) + START_TIME;

                    yield put({ type: "input", data: { startDay: formatDate(startDate), roomstartDate: formatDate(startDate) + START_TIME, timeType: getTimeType(startDate) } });
                }
            } catch (e) {
                yield call(message!.error, "getUserInfo：" + e.errmsg);
            }
        },

        /**
         * 获取预订资源状态
         */
        *getResourceStatusAction({ message, params, callback, detailid, maxDayBookingType }, { call, put, select }) {
            try {
                const result = yield call(resourceService.getResourceStatus, params);

                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentMemberInfo` });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    member = memberCommonState!.member;

                let extraParams: any = {};

                if (maxDayBookingType === MaxDayBookingTypeEnum.person) {
                    extraParams.inputerId = member && member.accountId;
                } else if (maxDayBookingType === MaxDayBookingTypeEnum.company) {
                    extraParams.customerId = member && member.companyId;
                }

                const todayRemainingTime = yield call(myOrderService.todayRemainingTime, {
                    bindTableId: detailid,
                    bindTableName: IParkBindTableNameEnum.resource,
                    startDate: params.startDate,
                    endDate: params.endDate,
                    orderStatus: [OrderStatusEnum.unpaid, OrderStatusEnum.unconfirmed, OrderStatusEnum.used, OrderStatusEnum.refunded],
                    ...extraParams,
                });

                let resultArr = result.items.length > 0 ? result.items[0] : [];
                let startDate = formatDate(params.startDate, "yyyy-MM-dd") + "T23:30:00";
                let endDate = formatDate(params.startDate, "yyyy-MM-dd") + "T23:59:59";

                resultArr.items &&
                    resultArr.items.push({
                        endTime: endDate,
                        itemCode: formatDate(params.startDate, "yyyy-MM-dd") + " 23:59:00",
                        startTime: startDate,
                        status: 0,
                        time: formatDate(params.startDate, "yyyy-MM-dd"),
                    });

                // 如果存在已预订时间段，就赋值
                if (resultArr.items && getLocalStorage("roomOrderStartTime_" + detailid) && getLocalStorage("roomOrderEndTime_" + detailid)) {
                    // const indexForm = resultArr.items.findIndex((x) => x.itemCode === formatDate(getLocalStorage("roomOrderStartTime_" + detailid), "yyyy-MM-dd hh:mm:00"));
                    // const indexTo = resultArr.items.findIndex((x) => x.itemCode === formatDate(getLocalStorage("roomOrderEndTime_" + detailid), "yyyy-MM-dd hh:mm:00"));
                    // yield put({ type: "input", data: { indexForm, indexTo } });
                    removeLocalStorage("roomOrderStartTime_" + detailid);
                    removeLocalStorage("roomOrderEndTime_" + detailid);
                }

                yield put({
                    type: "input",
                    data: {
                        resourceStatus: resultArr,
                        todayRemainingTime: todayRemainingTime,
                        maxDayBookingType,
                    },
                });

                if (callback) {
                    yield call(callback, resultArr);
                }
            } catch (e) {
                console.log(e);
                yield call(message!.error, "getResourceStatusAction：" + e.errmsg);
            }
        },

        /**
         * 获取用户信息
         */
        *getUserInfo({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser`, message });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState!.user;

                yield put({ type: "input", data: { user } });
            } catch (e) {
                yield call(message!.error, "getUserInfo：" + e.errmsg);
            }
        },

        /**
         * 获取优惠券
         */
        *getCouponPage({ message, resourceType }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                yield put({ type: "input", data: { isGainLoading: true } });
                const reslut = yield call(newCouponHttpService.getAllCoupon, {
                    parkId: getCurrentParkId(),
                    status: CouponStatusEnum.inUse,
                    isShowInBusinessDetail: true,
                    pageSize: 500,
                    sceneValueList: [getSceneCode(resourceType), "ALL"],
                    orderBy: "isReceived,inputTime",
                });

                yield put({ type: "input", data: { couponData: reslut } });
            } catch (e) {
                yield call(message!.error, "getCouponPage：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
                yield put({ type: "input", data: { isGainLoading: false } });
            }
        },

        /**
         * 获取标签
         */
        *getTagByTagClassAction({ message, data, map }, { call, put }) {
            try {
                let result = yield call(tagService.getTagByTagClass.bind(tagService), data);
                yield put({ type: "input", data: { [map]: result } });
            } catch (e) {
                yield call(message!.error, "getTagByTagClassAction：" + e.errmsg);
            }
        },

        /**
         * 获取侧边资源信息
         */
        *getRoomResource({ message, resourceId, resourceType }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                let roomResourceData = {
                    resourceType,
                    startDate: formatDateTime(now, "yyyy/MM/dd 00:00:00"),
                    endDate: formatDateTime(now, "yyyy/MM/dd 23:59:59"),
                };

                const data = yield call(resourceService.getAllResourcePaged, {
                    pageIndex: 1,
                    pageSize: 3,
                    parkId: getCurrentParkId(),
                    isFilterEffective: true,
                    orderBy: "id desc",
                    ...roomResourceData,
                });
                let otherResourceData: any = [],
                    resourceTwoData: any = [];

                // 侧边栏资源信息，获取与当前页面资源不重复的两条
                otherResourceData = data.items.filter((item) => item.resource && item.resource.id !== resourceId);
                resourceTwoData = otherResourceData.slice(0, 2);

                yield put({ type: "getProjectName", resources: resourceTwoData });
            } catch (e) {
                yield call(message!.error, "getRoomResource：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 处理项目名称
         */
        *getProjectName({ message, resources }, { call, put, select }) {
            try {
                yield yield put({ type: `${Namespaces.room}/getProjectsAction` });
                const roomState = yield select((state) => state[Namespaces.room]);

                if (resources && resources.length > 0 && roomState?.projectsData) {
                    resources.forEach((item) => {
                        const project = roomState?.projectsData?.find((x) => x.spaceId === item.resource.projectId);
                        item.resource.projectName = project?.spaceName;
                    });
                }

                yield put({ type: "input", data: { otherResourceData: resources } });
            } catch (e) {
                yield call(message!.error, "getProjectName：" + e.errmsg);
            }
        },
    };
}

app.model(roomDetailModel);
