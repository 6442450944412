import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreReducers, CoreState, getDate } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { specialService } from "@reco-w/declare-service";
import { getTime } from "@reco-w/home-common";

import { Namespaces, SPECIAL_TAG_LIST } from "./common";

export namespace specialModel {
    export const namespace = Namespaces.special;

    export const state: any = freeze(
        {
            ...CoreState,
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, tagData, callback }, { call, put }) {
            try {
                yield put({ type: "init", message });
                yield put({ type: "getTagData", message, tagt: tagData.tagt });

                if (callback) yield call(callback);
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },
        /**
         * 获取专项
         * @param param0
         * @param param1
         */
        *getSpecialList({ message, props, state }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                const tagUrlMaps = SPECIAL_TAG_LIST.map((x) => x.urlMap);
                const params = getSearchUrl(["k", "pi", "ps", "sd", "ed", ...tagUrlMaps], props);
                let startDate: any = params["sd"] ? getTime(params["sd"]) : null,
                    endDate: any = params["ed"] ? getTime(params["ed"]) : null;
                let applyTagIds: any[] = [];
                for (let item of SPECIAL_TAG_LIST) {
                    let values: any[] = [];
                    if (params[item.urlMap]) {
                        values = params[item.urlMap].split(",");
                    }
                    let ids: any[] = [];
                    if (state![item.tagCode] && values.length) {
                        ids = state![item.tagCode].filter((t: any) => values.some((v: any) => t.tagValue === v)).map((t: any) => t.id);
                    }
                    applyTagIds = [...applyTagIds, ...ids];
                    yield put({ type: "input", data: { [item.valueMap]: values } });
                }
                let user = yield select((state) => state[Namespaces.nav]);
                if (!user!.consumer) {
                    yield yield put({ type: `${Namespaces.nav}/getCurrentConsumer` });
                    user = yield select((state) => state[Namespaces.nav]);
                }
                yield put({
                    type: "input",
                    data: {
                        key: params["k"],
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 10,
                        applyTagIds,
                        startDate: startDate ? getDate(startDate) : null,
                        endDate: endDate ? getDate(endDate) : null,
                        user: user?.consumer,
                    },
                });
                const data = {
                    key: params["k"],
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || 10,
                    startDate,
                    endDate,
                    applyTagIds,
                    consumerId: user!.consumer && user!.consumer.id,
                    orderBy: "id desc",
                };
                const result = yield call(specialService.getPaged, data);
                result.items?.forEach((item, index) => {
                    item.number = index + 1;
                });
                yield put({ type: "input", data: { specials: result.items, totalItems: result.totalItems && Number(result.totalItems) } });
            } catch (e) {
                yield call(message!.error, "getSpecialList" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取标签数据
         * @param { message, tagt }
         * @param { call, put }
         */
        *getTagData({ message, tagt }, { call, put }) {
            try {
                const tagter = yield call(tagService.getTagByTagClasses, { ...tagt });
                yield put({ type: "input", data: { ...tagter } });
            } catch (e) {
                yield call(message!.error, "getTagData：" + e.errmsg);
            }
        },
        /**
         * 检查申报频率
         * @param param0
         * @param param1
         */
        *checkApplyFrequency({ message, id, callback }, { call, put }) {
            try {
                const result = yield call(specialService.checkApplyFrequency, id);
                yield put({ type: "input", data: { frequency: result } });
                callback && callback(result);
            } catch (e) {
                yield call(message!.error, e.errmsg);
            }
        },
    };
}

app.model(specialModel);
