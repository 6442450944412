import { CoreState, CoreEffects,  CoreReducers } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";

import { Namespaces } from "./common";
export namespace policyAskModel {
    export const namespace = Namespaces.policyAsk;

    export const state: any = freeze({
        ...CoreState
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        }
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects
    };
}
app.model(policyAskModel);
