import { freeze } from "immer";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { CoreEffects, CoreState, CoreReducers, getCurrentParkId } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { Namespaces as commonNamespaces, SearchBaseEffects } from "@reco-w/common-models";
import { resourceService } from "@reco-w/order-service";

import { Namespaces, ResourceTypeEnum, SpaceTypeEnum } from "./common";

export namespace roomModel {
    export const namespace = Namespaces.room;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            isHide: true,
            pageSize: 8,
            currentPage: 1,
        },
        !0
    );
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        ...SearchBaseEffects,

        *initPage({ message, tagData, projectId, roomname }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getProjectsAction" });
                yield put({ type: "getTagByTagClassAction", data: tagData });
                yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, location: "/resource/" + roomname, message });

                if (projectId) {
                    yield put({ type: "getBuildsAction", projectId });
                }
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取项目
         */
        *getProjectsAction({ message }, { call, put }) {
            try {
                let project = yield call(resourceService.getSpace, { spaceIdList: getCurrentParkId(), spaceTypeList: SpaceTypeEnum.project });

                project.forEach((item) => {
                    item.tagName = item.spaceName;
                    item.tagValue = item.spaceId;
                });

                yield put({ type: "input", data: { projectsData: project } });
            } catch (e) {
                yield call(message!.error, "getProjectsAction：" + e.errmsg);
            }
        },

        /**
         * 获取楼宇
         */
        *getBuildsAction({ message, projectId }, { call, put }) {
            try {
                const build = yield call(resourceService.getSpace, { spaceIdList: projectId, spaceTypeList: SpaceTypeEnum.building });

                build.forEach((item) => {
                    item.tagName = item.spaceName;
                    item.tagValue = item.spaceId;
                });

                yield put({ type: "input", data: { buildingsData: [...build] } });
            } catch (e) {
                yield call(message!.error, "getBuildsAction：" + e.errmsg);
            }
        },

        /**
         * 获取资源
         */
        *getResourceAction({ message, data, searchData }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                let result: any;
                if (data && data.startDate && data.endDate) {
                    result = yield call(resourceService.getPaged, data);
                } else {
                    result = yield call(resourceService.getAllResourcePaged, { ...data });
                }

                yield put({ type: "input", data: { resourceData: result.items, totalItems: Number(result.totalItems || 0) } });

                if (searchData && searchData.resourceType && searchData.resourceType === ResourceTypeEnum.meetingRoom)
                    yield put({ type: "input", data: { meetingData: result.items, totalMeetings: Number(result.totalItems || 0) } });
                else if (searchData && searchData.resourceType && searchData.resourceType === ResourceTypeEnum.venue)
                    yield put({ type: "input", data: { areaData: result.items, totalAreas: Number(result.totalItems || 0) } });
                else if (searchData && searchData.resourceType && searchData.resourceType === ResourceTypeEnum.cubicleRoom)
                    yield put({ type: "input", data: { workingData: result.items, totalWorkings: Number(result.totalItems || 0) } });
                else if (searchData && searchData.resourceType && searchData.resourceType === ResourceTypeEnum.advertisingSpace)
                    yield put({ type: "input", data: { advData: result.items, totalAdvs: Number(result.totalItems || 0) } });
            } catch (e) {
                yield call(message!.error, "getResourceAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取标签
         */
        *getTagByTagClassAction({ message, data }, { call, put }) {
            try {
                const result = yield call(tagService.getTagByTagClasses.bind(tagService), { tagClass: data });

                yield put({ type: "input", data: { tagClasses: result } });
            } catch (e) {
                yield call(message!.error, "getTagByTagClassAction：" + e.errmsg);
            }
        },
    };
}
app.model(roomModel);
