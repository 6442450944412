import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getCurrentParkId } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { policySubscribeService } from "@reco-w/policy-service";
import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";
import { Namespaces } from "./common";

export namespace mySubscriptionModel {
    export const namespace = Namespaces.mySubscription;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, props }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield put({ type: "getDataByUrl", props, message });
                yield put({ type: "getSubscription", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取当前用户政策订阅
         * @param { message }
         * @param { call, put }
         */
        *getSubscription({ message }, { call, put }) {
            try {
                const result = yield call(policySubscribeService.getByUser);

                if (result) {
                    const implementationSubscribeTags = result.implementationSubscribeTags?.split(",") || [];
                    const policySubscribeTags = result.policySubscribeTags?.split(",") || [];
                    const selectedTags = [...implementationSubscribeTags, ...policySubscribeTags].filter((x) => x);

                    yield put({ type: "input", data: { tagList: selectedTags } });
                }

                yield put({ type: "input", data: { subscription: result } });
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getDataByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl([CommonSearchParamInUrlEnum.pageIndex, CommonSearchParamInUrlEnum.pageSize], props);

                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params[CommonSearchParamInUrlEnum.pageIndex]) || 1,
                        pageSize: Number(params[CommonSearchParamInUrlEnum.pageSize]) || 10,
                    },
                });

                const data = {
                    parkId: getCurrentParkId(),
                    pageIndex: params[CommonSearchParamInUrlEnum.pageIndex] || 1,
                    pageSize: params[CommonSearchParamInUrlEnum.pageSize] || 10,
                };

                yield put({ type: "getData", data, message });
            } catch (e) {
                yield call(message!.error, "getDataByUrl：" + e.errmsg);
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getData({ message, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(policySubscribeService.getSubscribePaged, {
                    ...data,
                });

                yield put({ type: "input", data: result });
            } catch (e) {
                yield call(message!.error, "getData：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(mySubscriptionModel);
