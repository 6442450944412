export function getLanguageType() {
    return client["languageType"];
}


export function getName(name) {
    if (name.length === 2) {
        return name.slice(0, 1) + 'ㅤ' + name.slice(1);
    } else return name;
}

export function getTitleName(name) {
    if (name)
        return name.replace(/\n/g, '<br />');
    else
        return "";
}

export function getTitleNames(name) {
    if (name)
        return name.split(/\n/g);
    else
        return [];
}