import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { PureComponent, browser } from "@reco-m/core";

export namespace View {
    export interface IProps extends PureComponent.IProps {
        component?;
        ignoreClass?: boolean;
        isRoot?: boolean;
        swipeRight?: boolean;
        className?: any;
        vertical?: boolean;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends View.IProps = View.IProps, S extends View.IState = View.IState> extends PureComponent.Base<P, S> {
        static contextTypes = {
            goBack: PropTypes.func,
        };
    
        static defaultProps: any = {
            component: "div",
            ignoreClass: false,
            swipeRight: true,
            vertical: false,
        };
    
        time;
        viewRef;
    
        componentDidMount(): void {
            this.componentDidUpdate();
        }
    
        goBack(animate) {
            this.context.goBack && this.context.goBack(animate);
        }
    
        animate() {
            if (browser.versions.android) {
                return "animated zoomIn faster";
            }
            if (browser.versions.ios) {
                return "animated slideInRight faster";
            }
        }
    
        animateGoBack(e) {
            const evt = $(ReactDOM.findDOMNode(this) as any);
    
            if (!evt.hasClass("home-web")) {
                if (e.type === "touchstart") {
                    evt.removeClass("slideInRight");
                    evt.prev().css({ transition: "none" });
                    evt.prev().prev().css({ transition: "none" });
                    if (browser.versions.android) {
                        evt.prev(".container-page").addClass("android-accelerate");
                    }
                } else if (e.type === "touchend") {
                    const width = Math.round(($(window).width() as any) / 6);
                    if (Math.round(e.changedTouches[0].clientX) <= width) {
                        evt.css({ transform: "translate3d(0,0,0)", transition: "transform .2s" });
                        evt.prev().css({ transform: "translate3d(0,0,0)", transition: "transform .2s" });
                        evt.prev().prev().css({ transform: "translate3d(0,0,0)", transition: "transform .2s" });
                        setTimeout(() => {
                            evt.removeAttr("style");
                            evt.prev().removeAttr("style");
                            evt.prev().prev().removeAttr("style");
                        }, 400);
                    }
                    if (Math.round(e.changedTouches[0].clientX) > width) {
                        evt.css({ transform: "translate3d(" + $(window).width() + "px,0,0)", transition: "transform .2s" });
                        evt.prev().css({ transform: "translate3d(0,0,0)", transition: "transform .2s" });
                        evt.prev().prev().css({ transform: "translate3d(0,0,0)", transition: "transform .2s" });
                        setTimeout(() => {
                            this.goBack(false);
                        }, 200);
                    }
                } else if (e.type === "touchmove") {
                    e.preventDefault();
                    const size = e.changedTouches[0].clientX < 0 ? 0 : e.changedTouches[0].clientX;
                    evt.css({ transform: "translate3d(" + size + "px,0,0)" });
                    evt.prev().css({ transform: "translate3d(" + (-80 + size * 0.2) + "px,0,0)" });
                    if (browser.versions.android) {
                        evt.prev().css({ display: "flex", opacity: 1 });
                    }
                } else if (e.type === "touchcancel") {
                    evt.css({ transform: "translate3d(0)", transition: "transform .3s" });
                    evt.prev().css({ transform: "translate3d(0,0,0)", transition: "transform .2s" });
                }
            }
        }
    
        componentDidUpdate() {
            const back = this;
            this.time && clearTimeout(this.time);
            $(ReactDOM.findDOMNode(back) as any).addClass("accelerate");
            if (browser.versions.android) {
                $(ReactDOM.findDOMNode(back) as any).addClass("android-accelerate");
            }
            this.time = setTimeout(() => {
                if (back) {
                    $(".root").find(".view").last().prevAll().removeClass("zoomIn slideInRight android-accelerate");
                }
                // $("input[readonly]")
                //     .off("focus")
                //     .on("focus", function () {
                //         $(this).blur();
                //         /*解决ios readonly后还有光标*/
                //     });
            }, 500);
        }
    
        render(): React.ReactNode {
            let { component, className = "", ignoreClass, isRoot, vertical, ...props } = this.props as any;
    
            className = this.classnames(className);
    
            delete (props as any).classPrefix;
            delete (props as any).swipeRight;
    
            return browser.versions.android ? (
                <div
                    className={this.classnames("view container-page android", className, isRoot || className.includes("home-web") ? "home-page" : this.animate())}
                    ref={(el) => (this.viewRef = el)}
                >
                    {React.createElement(component, {
                        ...props,
                        className: `${vertical ? "container-props" : "container-column container-fill"}`,
                    })}
                    {isRoot ? null : (
                        <div
                            className="goBack"
                            onTouchStart={this.animateGoBack.bind(this)}
                            onTouchMove={this.animateGoBack.bind(this)}
                            onTouchEnd={this.animateGoBack.bind(this)}
                            onTouchCancel={this.animateGoBack.bind(this)}
                        />
                    )}
                </div>
            ) : (
                <div
                    className={this.classnames("view container-page ios", className, isRoot || className.includes("home-web") ? "home-page" : this.animate())}
                    ref={(el) => (this.viewRef = el)}
                >
                    {React.createElement(component, {
                        ...props,
                        className: `${vertical ? "container-props" : "container-column container-fill"}`,
                    })}
                    {isRoot ? null : (
                        <div
                            className="goBack"
                            onTouchStart={this.animateGoBack.bind(this)}
                            onTouchMove={this.animateGoBack.bind(this)}
                            onTouchEnd={this.animateGoBack.bind(this)}
                            onTouchCancel={this.animateGoBack.bind(this)}
                        />
                    )}
                </div>
            );
        }
    }
}


