import React from "react";
import { router } from "dva";
import { Affix, Menu, Modal, Badge, Row } from "antd";
import { BellFilled } from "@ant-design/icons";

import { template, setLocalStorage, getLocalStorage, getObjectProp, getCurrentParkId } from "@reco-m/core";
import { changeUrlParams, ViewComponent, mtaH5Click, statisticsEvent } from "@reco-w/core-ui";

import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";
import { Namespaces } from "@reco-w/layout-models";
import { headerModel } from "@reco-w/bi-layout-models";
import { LocalStorageEx, OSetting } from "@reco/util-func";

export namespace PageHeader {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> { }

    export interface IState extends ViewComponent.IState, headerModel.StateType {
        navState?: any;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.header;

        excludeSplit = ["/policy?"];

        get logoutRouter() {
            return getObjectProp(client, "logoutRouter", "/login");
        }

        get parkcode() {
            return this.props.match!.params.parkcode;
        }

        get defaultTitle() {
            return getObjectProp(client, "title", "--");
        }

        componentDidMount() {
            this.dispatch("input", { headerOpen: false, mode: "horizontal" });
            this.dispatch({
                type: "initPage",
                inputData: { radioSelect: getCurrentParkId() },
                judgeParkCode: this.parkcode,
            });
            // 手机端
            this.menuState();
            window.addEventListener("resize", this.menuState);
        }
        menuState = () => {
            let wh: any = $(window).width();
            if (wh < 1200) {
                this.dispatch({ type: "input", data: { mode: "inline" } });
            } else {
                this.dispatch({ type: "input", data: { mode: "horizontal" } });
            }
        };

        componentReceiveProps(nextProps: P) {
            let isLogin =
                (nextProps.location?.search?.includes("isLogin=") && !this.props.location?.search?.includes("isLogin=")) ||
                (this.props.location?.search?.includes("isLogin=") && !nextProps.location?.search?.includes("isLogin="));

            if (isLogin) {
                this.dispatch({ type: "getCertify" });
            }

            if (nextProps.location?.hash?.includes(this.logoutRouter)) {
                this.goTo(this.logoutRouter);
                window.location.reload();
            }
        }

        componentWillUnmount() {
            this.setState = () => {
                return;
            };
            window.removeEventListener("resize", this.menuState);
        }

        /**
         * 渲染已登录时的展示按钮
         * @returns auth login
         */
        renderAuthLogin(): React.ReactNode {
            const { state } = this.props,
                user = state!.user;

            return (
                <>
                    <router.Link
                        className="name-width"
                        to={`/${this.parkCode}/my/certifyDetail`}
                        onClick={() => {
                            setLocalStorage("selectedMenu", "/my");
                            setLocalStorage("mySelectedMenu", "/my/certifyDetail");
                        }}
                    >
                        {user?.realName || user?.nickName || "请设置昵称"}
                    </router.Link>
                    丨
                    <router.Link
                        to={`/${this.parkCode}/login`}
                        onClick={() => {
                            this.dispatch({
                                type: `logout`,
                                success: () => this.goTo(`/${this.parkCode}/login`),
                                error: (e) => Modal.error({ title: "消息提醒", content: e }),
                            });
                            OSetting.logoutSet();
                        }}
                    >
                        注销
                    </router.Link>
                </>
            );
        }

        /**
         * 渲染未登录时的展示按钮
         * @returns unauth login
         */
        renderUnauthLogin(): React.ReactNode {
            return (
                <>
                    <router.Link to={`/${this.parkCode}/login`}> 登录</router.Link>
                </>
            );
        }

        /**
         * 渲染登录按钮
         * @returns
         */
        renderLogin(): React.ReactNode {
            return this.isAuth() ? this.renderAuthLogin() : this.renderUnauthLogin();
        }

        /**
         * 渲染消息数量
         */
        renderNotice() {
            const { state } = this.props,
                noticeCount = state!.noticeCount;
            return this.isAuth() ? (
                <a className="margin-right-xs header-notice-a" onClick={() => this.goTo(`/${this.parkCode}/my/mynotice`)}>
                    <Badge className="mr10" count={noticeCount} size="small">
                        <BellFilled />
                    </Badge>
                </a>
            ) : null;
        }

        /**
         * logo
         */
        renderWebLogo(): React.ReactNode {
            // const { state } = this.props,
            //     appConfig = state!.appConfig;

            // return appConfig ? <img style={{ width: 115, height: 41 }} src={(appConfig?.webLogo && transformAssetsUrl(appConfig?.webLogo)) || "assets/images/shengwu/logo.png"} /> : null;
            return <img className="header-logo" src="assets/images/shengwu/logo.png" />;
        }

        changeMenu(headerOpen) {
            this.dispatch({ type: "input", data: { headerOpen: !headerOpen } });
        }

        /**
         * 获取选中的目录
         * @param menuKey
         * @returns
         */
        getSelectedKeys(menuKey) {
            if (menuKey) {
                return [menuKey];
            }

            const selectedMenuText = getLocalStorage("selectedMenu");

            let selectedMenu = selectedMenuText;

            if (!this.excludeSplit.some((x) => selectedMenuText?.includes(x))) {
                let selectedMenus = selectedMenuText && selectedMenuText.split("?");
                selectedMenu = (selectedMenus && selectedMenus.length > 0 && selectedMenus[0]) || "";
            }

            return [selectedMenu || ""];
        }

        /**
         * 渲染单条目录样式
         * @param key
         * @param link
         * @param event
         * @param title
         * @param [flag]
         * @returns menu item
         */
        renderMenuItem(key, link, event, title, flag?): React.ReactNode {
            return (
                <Menu.Item key={key}>
                    <router.Link
                        to={link}
                        className={flag ? "size-18" : ""}
                        onClick={() => {
                            if (["/peakdynamic", "/mediareport", "/summitlive", "/sidelights", "/previousreview"].contains(key)) {
                                this.dispatch({ type: "pageMenu/input", data: { openKey: [key] } });
                            }
                            mtaH5Click(event);
                        }}
                    >
                        {title}
                    </router.Link>
                </Menu.Item>
            );
        }

        /**
         * 渲染下拉目录标题
         * @param title
         * @returns
         */
        renderSubMenuTitle(title) {
            return <span className="size-18">{title}</span>;
        }

        /**
         * 点击目录时
         * @param e
         */
        onClickMenu(e) {
            if (e?.keyPath?.length > 0 && e.keyPath[0].includes("?")) {
                this.dispatch({ type: "input", data: { menuKey: e.keyPath[0] } });
            } else {
                this.dispatch({ type: "input", data: { menuKey: null } });
            }
        }
        /**
         * 渲染目录栏
         * @returns
         */
        renderMenu(): React.ReactNode {
            const { state } = this.props,
                menuKey = state!.menuKey,
                parkCode = this.parkCode,
                selectedKeys: any[] = this.getSelectedKeys(menuKey),
                mode = state!.mode;

            return (
                <Menu selectedKeys={selectedKeys} mode={mode} className="index-header" onClick={(e) => this.onClickMenu(e)}>
                    {this.renderMenuItem("/home", `/${parkCode}`, statisticsEvent.navigationBarHome, "首页", true)}
                    {this.renderMenuItem("/agendals", `/${parkCode}/agendals`, statisticsEvent.navigationBarHome, "峰会议程", true)}
                    {this.renderMenuItem("/peakdynamic", `/${parkCode}/peakdynamic`, statisticsEvent.navigationBarHome, "峰会动态", true)}
                    {this.renderMenuItem("/mediareport", `/${parkCode}/mediareport`, statisticsEvent.navigationBarHome, "媒体报道", true)}
                    {this.renderMenuItem("/summitlive", `/${parkCode}/summitlive`, statisticsEvent.navigationBarHome, "峰会直播", true)}
                    {this.renderMenuItem("/sidelights", `/${parkCode}/sidelights`, statisticsEvent.navigationBarHome, "花絮集锦", true)}
                    {this.renderMenuItem("/previousreview", `/${parkCode}/previousreview`, statisticsEvent.navigationBarHome, "往届回顾", true)}
                    {this.renderMenuItem("/my", `/${parkCode}/my/summitregistration`, statisticsEvent.navigationBarMy, "个人中心", true)}
                </Menu>
            );
        }

        /**
         * 关键字搜索
         * @param value
         */
        searchKey(value: string) {
            if (value && value.trim()) {
                mtaH5Click(statisticsEvent.globalSearch);
                this.goTo(changeUrlParams(`/${this.parkCode}/heardsearch`, { [CommonSearchParamInUrlEnum.globalKey]: value }));
            } else {
                if (location.pathname.indexOf("heardsearch") > -1) {
                    this.goTo(`/${this.parkCode}`);
                } else {
                    window.location.reload();
                }
            }
        }

        render(): React.ReactNode {
            const { state } = this.props,
                headerOpen = state!.headerOpen,
                mode = state!.mode;
            return (
                <>
                    <Affix offsetTop={0} className="header-affix">
                        <div className={headerOpen ? "nav open" : "nav"}>
                            {mode === "inline" && (
                                <div className="header-mobile-menu" onClick={() => this.changeMenu(headerOpen)}>
                                    <em />
                                    <em />
                                    <em />
                                </div>
                            )}
                            <div className="container">
                                <div className="flex">
                                    {this.renderWebLogo()}
                                    <div className="flex-item">
                                        <Row justify="end">
                                            <div className="header">
                                                <div className="content-right">
                                                    {/* {this.renderNotice()} */}
                                                    {this.renderLogin()}
                                                    <div className="ml15">
                                                        <i className="icon iconyingwen size-14 mr3" />
                                                        <span
                                                            className="hand"
                                                            onClick={() => {
                                                                LocalStorageEx.remove("signArr");
                                                                location.href = server["enWebsiteUrl"];
                                                            }}
                                                        >
                                                            English
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="custom-menu">{this.renderMenu()}</div>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Affix>
                </>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.header]);
}
