import { freeze } from "immer";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { CoreEffects, CoreState, CoreReducers, formatNow, getDate, formatDateTime, formatDate, getLocalStorage, getCurrentParkId } from "@reco-m/core";
import { app, formatTextareaContent } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { IParkBindTableNameEnum } from "@reco-w/common-common";
import { integralConfigService, integralintegralService } from "@reco-w/member-service";
import { resourceService, myOrderService, spaceService } from "@reco-w/order-service";

import { getRequestParams, isRoom } from "./utils";
import {
    Namespaces,
    ResourceOrderIntergralTypeEnum,
    PayWayEnum,
    OrderStatusEnum,
    ResourceTypeEnum,
    ResourceStatusEnum,
    PriceUnitEnum,
    START_TIME,
    END_TIME,
    OrderItemSkuTypeEnum,
} from "./common";

export namespace roomReserveModel {
    export const namespace = Namespaces.roomReserve;
    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            /**
             * 当前选中时间
             */
            choseDatenow: new Date(),
            /**
             * 当前步骤
             */
            currentStep: 0,
            /**
             * 支付方式
             */
            payType: PayWayEnum.alipay,
            /**
             * 是否显示发票
             */
            invoiceOpen: null,
            /**
             * 总时间
             */
            totalHour: 0,
            /**
             * 开始时间
             */
            startTime: null,
            /**
             * 结束时间
             */
            endTime: null,
            /**
             * 选中的发票信息
             */
            invoice: null,
            /**
             * 选中的优惠券类型
             */
            selectDucType: ResourceOrderIntergralTypeEnum.none,
            /**
             * 积分优惠值
             */
            integralValue: 0,
        },
        !0
    );
    export type StateType = typeof state;

    let isMeetingRoomOrVenue = false;
    let selfresourceType;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, id, resourceType, props }, { call, put }) {
            try {
                isMeetingRoomOrVenue = isRoom(resourceType);
                selfresourceType = resourceType;
                yield put({ type: "initState" });
                yield put({ type: "getUserInfo" });
                yield put({ type: "getResourceCanBook", message, resourceId: id });
                yield put({ type: "loyalGetConfig" });
                yield put({ type: "getResourceConfig", id });
                yield put({ type: "getResourceDetailAction", id, props });

                if (resourceType === ResourceTypeEnum.advertisingSpace) {
                    yield put({ type: "getResourceOrderListAction", id });
                }
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取资源详情
         */
        *getResourceDetailAction({ message, id, props, isRefreshResourceStatus = true }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(resourceService.get, id);
                const dayPriceType = result?.resource?.priceUnit;
                yield put({ type: "input", data: { detail: result, dayPriceType } });

                let startDate, endDate;

                if (isMeetingRoomOrVenue) {
                    startDate = getLocalStorage("roomOrderStartTime_" + id);
                    endDate = getLocalStorage("roomOrderEndTime_" + id);

                    yield put({ type: "input", data: { choseDatenow: startDate } });
                } else {
                    startDate = formatNow() + START_TIME;
                    if (dayPriceType === PriceUnitEnum.yuanPerDay) {
                        // 元/个/天
                        endDate = formatNow() + END_TIME;
                    } else if (dayPriceType === PriceUnitEnum.yuanPerMonth) {
                        // 元/个/月
                        endDate = formatDate(getDate(startDate)!.dateAdd("m", 1).dateAdd("d", -1), "yyyy-MM-dd") + END_TIME;
                    }
                }

                if (isRefreshResourceStatus) {
                    yield put({
                        type: "getResourceStatusAction",
                        startDate,
                        endDate,
                        props: props,
                    });
                }

                yield put({
                    type: `${commonNamespaces.cmsCommon}/getHeadJson`,
                    location: "/resource/" + props.match!.params.roomname,
                    title: result?.resource?.resourceName,
                    message,
                });

                if (isMeetingRoomOrVenue) {
                    yield put({ type: "getResourceSpaceLocationAction", spaceId: result.resource && result.resource.roomId });
                }
                yield put({ type: "getResourceConfig", id, dayPriceType });
            } catch (e) {
                yield call(message!.error, "getResourceRoomDetailAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取资源有没有权限预订
         */
        *getResourceCanBook({ message, resourceId }, { call, put }) {
            try {
                const result = yield call(resourceService.getCanBook, resourceId);

                if (!result) {
                    // 无法预订
                    yield call(message?.error, "您没有预订该资源的权限");
                }

                yield put({ type: "input", data: { canBook: result } });
            } catch (e) {
                yield call(message!.error, "getResourceSpaceLocationAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取资源空间位置
         */
        *getResourceSpaceLocationAction({ message, spaceId }, { call, put }) {
            try {
                const result = yield call(spaceService.getLocation, spaceId);
                yield put({ type: "input", data: { spaceLocation: result } });
            } catch (e) {
                yield call(message!.error, "getResourceSpaceLocationAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取资源配置
         */
        *getResourceConfig({ message, id, dayPriceType }, { call, put }) {
            try {
                const result = yield call(resourceService.getResourceConfig, id);

                let maxDate = new Date();
                if (result) {
                    if (Number(dayPriceType) === PriceUnitEnum.yuanPerMonth) {
                        maxDate = maxDate.dateAdd("m", result.bookingDay);
                    } else {
                        maxDate = maxDate.dateAdd("d", result.bookingDay - 1);
                    }
                } else {
                    maxDate = maxDate.dateAdd("d", 365); // 可预订一年
                }
                yield put({
                    type: "input",
                    data: { resourceConfigure: result, maxDate },
                });
            } catch (e) {
                yield call(message!.error, "getResourceConfig：" + e.errmsg);
            }
        },

        /**
         * 获取预订资源状态
         */
        *getResourceStatusAction({ message, startDate, endDate, props, isAdvertiseForce }, { call, put }) {
            try {
                let param = getRequestParams(props, startDate, endDate);
                const result = yield call(resourceService.getResourceStatus, param);

                if (isMeetingRoomOrVenue) {
                    yield put({
                        type: "input",
                        data: {
                            startDate: startDate,
                            endDate: endDate,
                            startTime: formatDateTime(startDate, "hh:mm"),
                            endTime: formatDateTime(endDate, "hh:mm"),
                            resourceStatus: result.items.length > 0 && result.items[0],
                        },
                    });
                } else {
                    let list = result && result.items && result.items.length > 0 && result.items[0];
                    if (list && list.items) {
                        list = list.items.filter((item) => {
                            return item.status === ResourceStatusEnum.free;
                        });
                    }

                    yield put({ type: "input", data: { resourceStatus: list } });

                    if (selfresourceType !== ResourceTypeEnum.advertisingSpace || isAdvertiseForce) {
                        yield put({
                            type: "input",
                            data: {
                                startDate: formatDate(startDate),
                                endDate: formatDate(endDate),
                            },
                        });
                    }
                }
            } catch (e) {
                console.log(e);
                yield call(message!.error, "getResourceStatusAction：" + e.errmsg);
            }
        },

        /**
         * 获取资源订单列表
         */
        *getResourceOrderListAction({ message, id }, { call, put }) {
            try {
                const result = yield call(myOrderService.getList, {
                    bindTableName: IParkBindTableNameEnum.resource,
                    bindTableId: id,
                    orderStatus: [
                        OrderStatusEnum.comment,
                        OrderStatusEnum.unpaid,
                        OrderStatusEnum.succeeded,
                        OrderStatusEnum.unconfirmed,
                        OrderStatusEnum.used,
                        OrderStatusEnum.unRefunded,
                        OrderStatusEnum.inuse,
                    ],
                });

                let now = new Date();

                let orderTimeArr =
                    (result?.length > 0 &&
                        result.map((x) => ({
                            startTime: getDate(formatDate(x.serviceStartDate, "yyyy-MM-dd 00:00:00")),
                            endTime: getDate(formatDate(x.serviceEndDate, "yyyy-MM-dd 23:59:59")),
                        }))) ||
                    [];
                orderTimeArr = orderTimeArr.filter((x) => x.endTime > now);

                yield put({ type: "input", data: { orderTimeArr } });
            } catch (e) {
                yield call(message!.error, "getResourceOrderListAction：" + e.errmsg);
            }
        },

        /**
         * 获取用户信息
         */
        *getUserInfo({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify` });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user && memberCommonState.user.currentUser,
                    certifyDetail = memberCommonState.certifyDetail;

                yield put({
                    type: "input",
                    data: {
                        companyId: certifyDetail && certifyDetail.companyId,
                        company: certifyDetail && certifyDetail.companyName,
                        name: user && (user.name || user.nickName),
                        mobile: user && user.mobile,
                        email: user && user.email,
                    },
                });

                yield put({ type: "getUserLoyal" });
            } catch (e) {
                yield call(message!.error, "getUserInfo：" + e.errmsg);
            }
        },

        /**
         * 积分配置
         */
        *loyalGetConfig({ message }, { call, put }) {
            try {
                const result = yield call(integralConfigService.getConfig);

                if (result) {
                    yield put({ type: "input", data: { IntegralWorth: result.integralWorth } });
                }
            } catch (e) {
                yield call(message!.error, "loyalGetConfig：" + e.errmsg);
            }
        },

        /**
         * 获取积分
         */
        *getUserLoyal({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getUserLoyalty` });
                const memberState = yield select((state) => state[commonNamespaces.memberCommon]),
                    userLoyalty = memberState.userLoyalty;

                if (userLoyalty) {
                    let personalTotalLoyalty = 0,
                        companyTotalLoyalty = 0;

                    const personalSetId = userLoyalty.personalSetId;
                    if (personalSetId) {
                        const personalLoyalty = yield call(integralintegralService.get, personalSetId);
                        personalTotalLoyalty = personalLoyalty ? personalLoyalty.availableIntegral : 0;
                    }
                    const companySetId = userLoyalty.companySetId;
                    if (companySetId) {
                        const companyLoyalty = yield call(integralintegralService.get, companySetId);
                        companyTotalLoyalty = companyLoyalty ? companyLoyalty.availableIntegral : 0;
                    }

                    let availableIntegral = {
                        CompanyAvailableIntegral: companyTotalLoyalty,
                        PersonalAvailableIntegral: personalTotalLoyalty,
                    };
                    yield put({ type: "input", data: { userIntergral: availableIntegral } });
                } else {
                    yield put({ type: "input", data: { userIntergral: 0 } });
                }
            } catch (e) {
                yield call(message!.error, "getUserLoyal：" + e.errmsg);
            }
        },

        /**
         * 提交订单
         */
        *roomSubmitAction({ message, state, id, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                yield put({ type: "input", data: { isSubmit: true } });
                let selectDucType = state!.selectDucType;
                let startDate = state!.startDate && formatDateTime(state!.startDate);
                let endDate = state!.endDate && formatDateTime(state!.endDate);
                let invoice = state!.invoice;
                let orderRemark = state!.orderRemark;

                if (orderRemark) {
                    orderRemark = formatTextareaContent(orderRemark);
                }

                let orderItem: any = [];

                if (Number(selfresourceType) === ResourceTypeEnum.cubicleRoom) {
                    let resourceStatus = state!.resourceStatus;
                    let num = state!.num;
                    let detail = state!.detail;

                    resourceStatus &&
                        resourceStatus.forEach((item, index) => {
                            if (index < num) {
                                let itm = {
                                    skuId: item.itemId,
                                    skuName: item.itemCode,
                                    skuType: OrderItemSkuTypeEnum.goods,
                                    quantity: 1,
                                    price: detail.resource.price,
                                    priceUnitName: detail.resource.priceUnitName,
                                };
                                orderItem.push(itm);
                            }
                        });
                } else {
                    orderItem = undefined;
                }

                if (invoice) {
                    invoice = { ...invoice };
                    delete invoice.id;
                    delete invoice.bindTableId;
                    invoice.bindTableName = IParkBindTableNameEnum.order;
                }

                let intergralParams: any, resourceParams: any;

                if (selectDucType !== ResourceOrderIntergralTypeEnum.none) {
                    intergralParams = {
                        integralType: selectDucType,
                        integralUsed: state!.DeductionIntegral,
                    };
                }

                if (isMeetingRoomOrVenue) {
                    resourceParams = {
                        serviceStartDate: getDate(startDate.split(" ")[0] + " " + state!.startTime),
                        serviceEndDate: getDate(endDate.split(" ")[0] + " " + state!.endTime),
                    };
                } else {
                    resourceParams = {
                        serviceStartDate: getDate(formatDate(startDate, "yyyy-MM-dd 00:00:00")),
                        serviceEndDate: getDate(formatDate(endDate, "yyyy-MM-dd 23:59:59")),
                    };
                }

                const params = {
                    resourceId: id,
                    contactPerson: state!.name,
                    contactMobile: state!.mobile && state!.mobile.replace(" ", ""),
                    contactEmail: state!.email,
                    customerId: state!.companyId,
                    customerName: state!.company,
                    remarks: orderRemark,
                    isCashPay: state!.check,
                    invoice: invoice ? invoice : null,
                    couponUsed: state!.couponSelect && state!.couponSelect.couponIDs,
                    orderItem: orderItem,
                    parkId: getCurrentParkId(),
                    ...intergralParams,
                    ...resourceParams,
                };

                const result = yield call(myOrderService.post, params);
                yield put({ type: "input", data: { submitSuccess: result } });
                if (callback) {
                    callback(result);
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubmit: false } });
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(roomReserveModel);
