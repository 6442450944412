import { formatDate as formatDateO, formatDateTime as formatDateTimeO, getDate } from "@reco-m/core";
import moment from "moment";

export function formatDateStartEnd(date1, date2, fmt = "yyyy年MM月dd日", and = " - ") {
    const value1 = formatDateO(date1);
    const value2 = formatDateO(date2);
    if (value1 === value2) return formatDateO(date1, fmt + " hh:mm") + and + formatDateO(date2, "hh:mm");
    return formatDateO(date1, fmt + " hh:mm") + and + formatDateO(date2, fmt + " hh:mm");
}

export namespace OTime {
    export const formatDate = formatDateO;
    export const formatDateTime = formatDateTimeO;
    export function formatDateCom(d) {
        return d && formatDate(d, "yyyy.MM.dd");
    }
    export function formatDateTimeCom(d) {
        return d && formatDateTime(d, "yyyy/MM/dd hh:mm");
    }

    export const thisYear: any = new Date().getFullYear() + "";

    export function getYearArr({ yearNum = 5, labelSuffix = "年" } = {}) {
        const arr = new Array(yearNum).fill({}).map((_x, i) => {
            const year = thisYear - i;
            return { label: `${year}${labelSuffix}`, value: String(year) };
        });
        return arr;
    }
}

export function getWeekStrO(date, days) {
    let week = moment(getDate(date)).add(days, "days").day();
    let str;
    if (week === 0) {
        str = "星期日";
    }
    else if (week === 1) {
        str = "星期一";
    }
    else if (week === 2) {
        str = "星期二";
    }
    else if (week === 3) {
        str = "星期三";
    }
    else if (week === 4) {
        str = "星期四";
    }
    else if (week === 5) {
        str = "星期五";
    }
    else if (week === 6) {
        str = "星期六";
    }
    return str;
}

// 时间周期冲突判断
export function isOverlap(startTime1, endTime1, startTime2, endTime2) {
    if (startTime1 >= endTime2 || startTime2 >= endTime1) {
        return false;
    } else {
        return true;
    }
}

// 多个周期冲突判断
export function checkOverTimes(arr?: any) {
    arr = arr || [];
    //判断是否有重复日期
    let flag = false;
    for (let i = 0; i < arr.length; i++) {
        if (flag) break;
        for (let j = 1 + i; j < arr.length; j++) {
            if (isOverlap(arr[i][0], arr[i][1], arr[j][0], arr[j][1])) {
                flag = true;
                break;
            }
        }
    }
    return flag;
}
