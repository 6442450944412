import { EffectsMapObject } from "dva";

import { getSearchUrl } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { specialProjectService } from "@reco-w/declare-service";

import { PROJECT_TAG_LIST, PROJECT_APPLY_YEAR_TAG_CODE, Namespaces } from "./common";

export const ProjectBaseEffects: EffectsMapObject = {
    *initPage({ message, tagData, callback }, { call, put }) {
        try {
            yield put({ type: "init", message });
            yield put({ type: "getTagData", message, tagt: tagData.tagt });

            if (callback) yield call(callback);
        } catch (e) {
            yield call(message!.error, "initPage：" + e.errmsg);
        }
    },
    /**
     * 获取申报列表
     * @param param0
     * @param param1
     */
    *getApplyList({ message, props, state, param }, { call, put, select }) {
        try {
            yield put({ type: "showLoading" });
            const tagUrlMaps = PROJECT_TAG_LIST.map((x) => x.urlMap);
            const params = getSearchUrl(["k", "pi", "ps", "st", ...tagUrlMaps], props);
            let applyTagIds: any[] = [];
            let year: any, stateTagValue: any;
            stateTagValue = param?.stateTagValue || params["st"];
            for (let item of PROJECT_TAG_LIST) {
                let values: any[] = [];
                if (params[item.urlMap]) {
                    values = params[item.urlMap].split(",");
                }
                let ids: any[] = [];
                if (state![item.tagCode] && values.length && item.tagCode !== PROJECT_APPLY_YEAR_TAG_CODE) {
                    ids = state![item.tagCode].filter((t: any) => values.some((v: any) => t.tagValue === v)).map((t: any) => t.id);
                }
                applyTagIds = [...applyTagIds, ...ids];
                if (item.tagCode === PROJECT_APPLY_YEAR_TAG_CODE && values?.length) {
                    year = values[0];
                }
                yield put({ type: "input", data: { [item.valueMap]: values } });
            }
            yield put({
                type: "input",
                data: {
                    key: params["k"],
                    currentPage: Number(params["pi"]) || 1,
                    pageSize: Number(params["ps"]) || 10,
                    applyTagIds,
                    stateTagValue,
                    st: params["st"] ? [params["st"]] : [],
                    year,
                },
            });
            let user = yield select((state) => state[Namespaces.nav]);
            if (!user!.consumer) {
                yield yield put({ type: `${Namespaces.nav}/getCurrentConsumer` });
                user = yield select((state) => state[Namespaces.nav]);
            }
            const data = {
                key: params["k"],
                pageIndex: params["pi"] || 1,
                pageSize: params["ps"] || 10,
                applyTagIds,
                year,
                stateTagValue,
                ...param,
                consumerId: user!.consumer && user!.consumer.id,
                orderBy: "id desc",
            };
            const result = yield call(specialProjectService.getApplyList, data);
            result.items?.forEach((item, index) => {
                item.number = index + 1;
            });
            yield put({ type: "input", data: { projects: [] } });
            yield put({ type: "input", data: { projects: result.items, totalItems: result.totalItems && Number(result.totalItems) } });
        } catch (e) {
            yield call(message!.error, "getApplyList" + e.errmsg);
        } finally {
            yield put({ type: "hideLoading" });
        }
    },
    /**
     * 获取标签数据
     * @param { message, tagt }
     * @param { call, put }
     */
    *getTagData({ message, tagt }, { call, put }) {
        try {
            const tagter = yield call(tagService.getTagByTagClasses, { ...tagt });
            yield put({ type: "input", data: { ...tagter } });
        } catch (e) {
            yield call(message!.error, "getTagData：" + e.errmsg);
        }
    },
    /**
     * 删除
     * @param param0
     * @param param1
     */
    *deleteProject({ message, id, callback }, { call }) {
        try {
            const result = yield call(specialProjectService.delete, id);
            callback && callback(result);
        } catch (e) {
            yield call(message!.error, "deleteProject" + e.errmsg);
        }
    },
};
