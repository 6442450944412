import { HttpService, resolveService } from "@reco-m/core";
export class SpecialHttpService extends HttpService {
    constructor() {
        super("special/special");
    }

    /**
     * 检查申报频率
     * @param id
     * @returns
     */
    checkApplyFrequency(id: any) {
        return this.httpGet(`check-apply-frequency/${id}`);
    }
}
export const specialService = resolveService(SpecialHttpService);
