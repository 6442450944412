import { useState, useEffect } from "react";
import { activityService } from "@reco-w/activity-service";
import { Modal, List, Row, Col, Typography } from "antd";
import { ImageAuto, HtmlContent, NoData, Attach } from "@reco-w/core-ui";
import { EnumTableName, getName, getTitleName } from "@reco/util-func";
import { transformUrl, attachService } from "@reco-m/core";
import { activityAgendaService, r2323ActivityContributorService } from "@reco-w/bi-home-service";
const { Paragraph } = Typography;

export namespace MeetingModal {
    export function Comp({ that, otherObj }) {
        const { state } = that.props,
            meetShowModal = state!.meetShowModal,
            meetId = state!.meetId;

        const handleVideoChange = (e) => {
            otherObj.update({ showModal: true });
            that.dispatch("update", { meetShowModal: e });
        }

        const [detail, setDetail] = useState<any>({});
        const [agendaList, setAgendaList] = useState<any>([]);
        const [contributors, setContributors] = useState<any>([]);
        const [summitIndex, setSummitIndex] = useState<any>();
        const [attachCount, setAttachCount] = useState<any>();
        useEffect(() => {
            if (meetId) {
                activityService.get(meetId).then(d => setDetail(d));
                activityAgendaService.getList({ activityId: meetId }).then(d => setAgendaList(d));
                r2323ActivityContributorService.getList({ activityId: meetId, queryDetail: true }).then(d => setContributors(d));
                that.loadAttach(meetId);
                attachService.getPaged({
                    pageSize: 10, pageIndex: 1, bindTableName: "bi_activity",
                    bindTableId: meetId,
                    customType: 2
                }).then(d => setAttachCount(d.totalItems && Number(d.totalItems)));
                setSummitIndex(0);
            }
        }, [meetId]);
        return (
            <Modal
                wrapClassName="home-sign-modal-wrapper"
                title={detail?.activityVM?.activityName}
                visible={meetShowModal}
                onOk={() => handleVideoChange(false)}
                onCancel={() => handleVideoChange(false)}
                footer={false}
            >
                <div className="container-scrollable container-fill body">
                    <div className="content">
                        <div className="ph20 pv10">
                            <div className="container">
                                {contributors && contributors.length > 0 ? <div className="dynamic-tabs">
                                    {["会议议程", "嘉宾介绍"].map((item, i) => {
                                        return (
                                            <span key={i} className={summitIndex === i ? "active" : ""} onClick={() => setSummitIndex(i)}>
                                                {item}
                                            </span>
                                        );
                                    })}
                                </div> : <></>}
                                {summitIndex === 0 ? (
                                    <>
                                        <div className="mt20">
                                            <div className="size-24 primary-color ant-text-left">{detail?.activityVM?.activityName}</div>
                                            <div className="size-18 white-color">
                                                <HtmlContent.Component html={getTitleName(detail?.activityDetailVM?.activityContent)}></HtmlContent.Component>
                                            </div>
                                        </div>
                                        {detail?.activityDetailVM?.organizerUndertake && <div className="mb0 size-16 white-color mt5 flex-align start">
                                            <span className="white6">承办单位：</span>
                                            <div className="flex-item">
                                                <HtmlContent.Component html={getTitleName(detail?.activityDetailVM?.organizerUndertake)} />
                                            </div>
                                        </div>}
                                        {detail?.activityDetailVM?.organizerSupporter && <div className="mb0 size-16 white-color mt5 flex-align start">
                                            <span className="white6">支持单位：</span>
                                            <div className="flex-item">
                                                <HtmlContent.Component html={getTitleName(detail?.activityDetailVM?.organizerSupporter)} />
                                            </div>
                                        </div>}
                                        {attachCount && attachCount > 0 ? <div className="mb0 size-16 white-color mt5 attach-box">
                                            <Attach.Component tableName={EnumTableName.activity} customType={2} readonly={true} />
                                        </div> : <></>}
                                        {agendaList && agendaList.length > 0 && <div className="summit-content-box mt30">
                                            <div className="summit-step">
                                                {agendaList.map((item, i) => {
                                                    return (
                                                        <div className={`summit-step-item ${item.agendaTypeValue === AgendaTypeEnum.group ? "active" : ""}`} key={i}>
                                                            <div className="summit-step-date">{`${item.startSlot}-${item.endSlot}`}</div>
                                                            <div className="summit-step-dot" />
                                                            <div className="summit-step-content">
                                                                <div><HtmlContent.Component html={getTitleName(item.title)} /></div>
                                                                {item.agendaTypeValue === AgendaTypeEnum.common && renderAgendaOne(item.hosts, item.guests)}
                                                                {item.agendaTypeValue === AgendaTypeEnum.group && renderAgendaTwo(item.hosts, item.guests)}
                                                                {item.agendaTypeValue === AgendaTypeEnum.discuss && renderAgendaThree(item.hosts, item.guests)}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>}
                                    </>
                                ) : contributors ? (
                                    <List className="person-list mt30" itemLayout="vertical"
                                        dataSource={contributors} renderItem={(item: any, i) => renderOneItem(item, i)} />
                                ) : (
                                    <NoData.Component />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    // 联系类型
    export enum AgendaTypeEnum {
        // 议程项
        common = "1",
        // 议程分组
        group = "2",
        // 圆桌讨论
        discuss = "3",
    }

    export function renderOneItem(item, i) {
        return (
            <List.Item key={i}>
                <Row gutter={30}>
                    <Col xs={24} sm={24} xl={5} className="jb">
                        <ImageAuto.Component src={transformUrl(item.pictureUrl || "assets/images/fenghui.png")} width="180px" height="180px" radius={10} />
                    </Col>
                    <Col xs={24} sm={24} xl={19}>
                        <Paragraph ellipsis className="mb0 size-22 primary-color mt10">
                            {getName(item.name)}
                        </Paragraph>
                        <div className="white-color size-16 mt10">
                            {item.positions?.map((t, j) => {
                                return (
                                    <div key={j}>
                                        {/* {t.unitName}-{t.position} */}
                                        {[t.unitName, t.position].filter(x => x).filter(x => (x as String).trim().length).join('-')}
                                    </div>
                                );
                            })}
                        </div>
                        <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: '查看更多' }} className="mb0 size-16 white4 mt10">
                            {item.introduce}
                        </Paragraph>
                    </Col>
                </Row>
            </List.Item>
        );
    }


    export function renderAgendaOne(hosts?: any, guests?: any) {
        return (
            <>
                {hosts?.map((h, i) => {
                    return (
                        <div className="summit-grid" key={i}>
                            <div className="summit-grid-name">
                                {getName(h?.name)}
                            </div>
                            <div className="summit-grid-content">
                                <div>
                                    {h?.companyName} {h?.position}
                                </div>
                            </div>
                        </div>
                    );
                })}
                {guests?.map((h, i) => {
                    return (
                        <div className="summit-grid" key={i}>
                            <div className="summit-grid-name">
                                {getName(h?.name)}
                            </div>
                            <div className="summit-grid-content">
                                <div>
                                    {h?.companyName} {h?.position}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }

    export function renderAgendaTwo(hosts?: any, guests?: any) {
        return (
            <>
                {hosts?.map((h, i) => {
                    return (
                        <div className="summit-grid" key={i}>
                            <div className="summit-grid-name">主持人：
                                {getName(h?.name)}
                            </div>
                            <div className="summit-grid-content">
                                <div>
                                    {h?.companyName} {h?.position}
                                </div>
                            </div>
                        </div>
                    );
                })}
                {guests?.map((h, i) => {
                    return (
                        <div className="summit-grid" key={i}>
                            <div className="summit-grid-name">&emsp;&emsp;&emsp;&emsp;
                                {getName(h?.name)}
                            </div>
                            <div className="summit-grid-content">
                                <div>
                                    {h?.companyName} {h?.position}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }

    export function renderAgendaThree(hosts?: any, guests?: any) {
        return (
            <>
                {hosts?.map((h, i) => {
                    return (
                        <div className="summit-grid" key={i}>
                            <div className="summit-grid-name">主持人：</div>
                            <div className="summit-grid-content">
                                <div className="flex">
                                    <div className="summit-grid-name">
                                        {h?.guest?.guestType ? `【${h?.guest?.guestType}】` : ""}
                                        {getName(h?.name)}
                                        {h?.guest?.invateStatus ? `【${h?.guest?.invateStatus}】` : ""}
                                    </div>
                                    <div className="summit-grid-content">
                                        {h?.position?.unitName} {h?.position?.position}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                {guests && guests.length > 0 ? (
                    <div className="summit-grid">
                        <div className="summit-grid-name">嘉&emsp;宾：</div>
                        <div className="summit-grid-content">
                            {guests.map((h, j) => {
                                return (
                                    <div className="flex" key={j}>
                                        <div className="summit-grid-name">
                                            {h?.guest?.guestType ? `【${h?.guest?.guestType}】` : ""}
                                            {getName(h?.name)}
                                            {h?.guest?.invateStatus ? `【${h?.guest?.invateStatus}】` : ""}
                                        </div>
                                        <div className="summit-grid-content">
                                            {h?.position?.unitName} {h?.position?.position}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </>
        );
    }

}
