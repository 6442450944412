import { HttpService, resolveService } from "@reco-m/core";

// 园区管理  接口  organization/Park
export class OrganizationParkHttpService extends HttpService {
    constructor() {
        super("organization/park");
    }
}

export const organizationParkHttpService = resolveService(OrganizationParkHttpService);

// 空间-园区管理
export class SpaceParkHttpService extends HttpService {
    constructor() {
        super("space/park");
    }
}

export const spaceParkHttpService = resolveService(SpaceParkHttpService);

export class ParkBaseHttpService extends HttpService {
    constructor() {
        super("ParkBase/Index");
    }

    // 获取我的里面的收藏列表
    getUserFollow(data?: any) {
        return this.httpGet("GetUserFollow", this.resolveRequestParams(data));
    }

    // 获取收藏列表数量
    getFollowCount(data: any) {
        return this.httpGet("GetUserFollowCount", this.resolveRequestParams(data));
    }

    search(data?: any) {
        return this.httpGet("GetGlobalSearch", this.resolveRequestParams({ key: data }));
    }

    GetPaged(data?: any) {
        return this.httpGet("GetPaged", this.resolveRequestParams(data));
    }
}

export class ParkCateHttpService extends HttpService {
    constructor() {
        super("organization/parkcatalog");
    }

    getCateByCode(code: string) {
        return this.httpGet("by-code/" + code);
    }
}

export const parkCateService = resolveService(ParkCateHttpService);

export const ImpressionService = resolveService(ParkBaseHttpService);
