import React from "react";

import { PureComponent, transformImageUrl } from "@reco-m/core";

export namespace Banner {
    export interface IProps extends PureComponent.IProps {
        bimg?: any;
        cimg?: any;
        cimgShow?: boolean;
        text?: string;
    }

    export interface IState extends PureComponent.IState {
        bimg?: string;
        cimg?: string;
        cimgShow?: boolean;
        text?: string;
    }

    export class Component<P extends IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            cimgShow: true,
        };

        render(): React.ReactNode {
            const { bimg, cimg, cimgShow, text } = this.props;

            if (!bimg) {
                return null;
            }

            return (
                <div
                    className="banner-box"
                    style={{
                        background: `url(${bimg}) no-repeat`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    {cimgShow === true ? <img src={transformImageUrl("" + cimg, 100, 100)} alt="" /> : ""}
                    {text && <div className="banner-text">{text}</div>}
                </div>
            );
        }
    }
}
