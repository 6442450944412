import { HttpService, resolveService } from "@reco-m/core";

export class ParkHttpService extends HttpService {
    protected parkPromise: any;

    constructor() {
        super("organization/park");
    }

    getPaged(data?: any) {
        this.parkPromise || (this.parkPromise = this.httpGet("", this.resolveRequestParams(data, { headers: { ignoreUnitId: true } } as any)));
        return this.parkPromise || Promise.resolve({});
    }

    list(data: any) {
        return this.httpGet("list", this.resolveRequestParams(data, { headers: { ignoreUnitId: true } } as any));
    }
}

export const parkService = resolveService(ParkHttpService);

export class SearchTopicHttpService extends HttpService {
    constructor() {
        super("universalsearch");
    }
    getSearchData(data: any) {
        return this.httpGet("search", this.resolveRequestParams(data));
    }
}

export const searchService = resolveService(SearchTopicHttpService);
