import { EffectsMapObject } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { policyService } from "@reco-w/policy-service";

import { Namespaces } from "./common";
import { DeclareBaseEffects } from "./declare.base.model";

export namespace policyDetailModel {
    export const namespace = Namespaces.policyDetail;
    export const state: any = freeze({ ...CoreState }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...CoreReducers,
        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        ...DeclareBaseEffects,

        /**
         * 获取政策详情
         * @param param0
         * @param param1
         */
        *getPolicyDetail({ message, id, callback }, { call, put, select }) {
            try {
                let user = yield select((state) => state[Namespaces.nav]);
                if (!user!.consumer) {
                    yield yield put({ type: `${Namespaces.nav}/getCurrentConsumer` });
                    user = yield select((state) => state[Namespaces.nav]);
                }
                yield put({ type: "input", data: { user: user } });
                const result = yield call(policyService.get, id);
                yield put({ type: "input", data: { policyDetail: result } });
                callback && callback(result);
            } catch (e) {
                yield call(message!.error, "getPolicyDetail" + e.errmsg);
            }
        },
    };
}
app.model(policyDetailModel);
