import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, getLocalStorage, getCurrentParkId } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { parkCateService } from "@reco-w/impression-service";
import { marketService } from "@reco-w/market-service";
import { workOrderService } from "@reco-w/workorder-service";

import { WorkOrderStatusEnum, Namespaces } from "./common";

export namespace workorderCommonModel {
    export const namespace = Namespaces.workorderCommon;

    export const state: any = freeze({}, !0);

    export type StateType = typeof state;

    const checkInCache = new Map();
    const institutionCache = new Map();

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        /**
         * 获取 入驻申请
         * @param { data 搜索参数 , parkId = getCurrentParkId(), userId = getLocalStorage("userId"), isRefresh = false  是否刷新 }
         * @param { call, put, select }
         */
        *getCheckInDetail({ message, data, parkId = getCurrentParkId(), userId = getLocalStorage("userId"), isRefresh = false }, { call, put, select }) {
            try {
                let checkInData = isRefresh ? null : checkInCache.get(parkId);

                if (!checkInData) {
                    const catalogue = yield call(parkCateService.getCateByCode, "ruzsq");

                    if (catalogue) {
                        let inputerId = userId;
                        if (!inputerId) {
                            yield yield put({ type: `${Namespaces.memberCommon}/getCurrentUser` });
                            const memberCommonState: any = yield select((state) => state[Namespaces.memberCommon]),
                                user = memberCommonState.user;

                            inputerId = user.user?.currentUser?.id;
                        }

                        const applyDetailData = yield call(workOrderService.getByUser, {
                            catalogueId: catalogue.id,
                            parkId: getCurrentParkId(),
                            showHidCatalogs: true,
                            inputerId: inputerId,
                            orderBy: "id desc",
                            ...data,
                        });

                        const checkDetailData = applyDetailData.items;

                        // 入驻申请状态
                        const checkStatus = checkDetailData?.length > 0 && checkDetailData[0]?.order?.status;
                        // 入驻申请工单id
                        const checkOrderId = checkDetailData?.length > 0 && checkDetailData[0]?.order?.id;
                        // 入驻申请状态机stateid
                        const checkStateId = checkOrderId ? checkDetailData[0]?.order?.flowStateId : "";
                        // 入驻申请状态是否存在
                        const isNoCheckStatus = typeof checkStatus === "undefined";
                        // 入驻申请状态是否是积极地（不是 已取消/已退回）（在表单页，当状态是已取消/已退回时，不跳表单页）
                        const isCheckPositive = typeof checkStatus !== "undefined" && ![WorkOrderStatusEnum.cancel, WorkOrderStatusEnum.bounced].contains(checkStatus);
                        // 入驻申请状态是否不是已取消（从首页、头部、我的目录 中点击入驻项目，当 未提交过 或状态是 已取消 时，才跳转到表单页，否则跳详情）
                        const isCheckCancel = typeof checkStatus !== "undefined" && ![WorkOrderStatusEnum.cancel].contains(checkStatus);

                        checkInData = { checkOrderId, checkStateId, checkStatus, isNoCheckStatus, isCheckPositive, isCheckCancel };

                        checkInCache!.set(parkId, checkInData);
                    }
                }

                yield put({ type: `input`, data: { checkInData } });
            } catch (e) {
                yield call(message!.error, "getCheckInDetail：" + e.errmsg);
            }
        },

        /**
         * 清除入驻申请信息
         * @param {}
         * @param { put }
         */
        *cleanCheckIn({}, { put }) {
            checkInCache.clear();
            yield put({ type: "input", data: { checkInData: null } });
        },

        /**
         * 获取我的服务机构
         * @param { parkId = getCurrentParkId(), isRefresh = false 是否刷新  }
         * @param { put, call }
         */
        *getInstitution({ message, parkId = getCurrentParkId(), isRefresh = false }, { put, call }) {
            try {
                if (isRefresh) {
                    yield put({ type: "input", data: { institution: null } });
                }

                let institution = isRefresh ? null : institutionCache.get(parkId);

                if (!institution) {
                    institution = yield call(marketService.getMyInstitution);
                    if (!institution) {
                        institution = {};
                    }

                    institutionCache!.set(parkId, institution);
                }

                yield put({ type: "input", data: { institution } });
            } catch (e) {
                yield call(message!.error, "getInstitution：" + e.errmsg);
            }
        },

        /**
         * 清除服务机构
         * @param {}
         * @param { put }
         */
        *cleanInstitution({}, { put }) {
            institutionCache.clear();
            yield put({ type: "input", data: { institution: null } });
        },

        /**
         * 清除入驻申请信息、服务机构
         * @param {}
         * @param { put }
         */
        *clean({}, { put }) {
            yield put({ type: "cleanCheckIn" });
            yield put({ type: "cleanInstitution" });
        },
    };
}

app.model(workorderCommonModel);
