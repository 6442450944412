import React from "react";
import { formatDateTime } from "@reco-m/core";
import { SortNumberEnum, SeqEnum, EnumWordEnum } from ".";
import { changeUrlParams } from "@reco-w/core-ui";
import { router } from "dva";
import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";

/**
 * 关键字高亮显示(匹配整个关键词 不拆分)
 * @param text
 * @param keyword
 * @param [htmlTag]
 * @returns
 */
export function getKeyWord(text, keyword, htmlTag = "span") {
    let re;
    //匹配每一个特殊字符 ，进行转义
    const specialStr = ["*", ".", "?", "+", "$", "^", "[", "]", "{", "}", "|", "\\", "(", ")", "/", "%"];
    specialStr.forEach((item) => {
        if (keyword.indexOf(item) != -1) {
            keyword = keyword.replace(new RegExp("\\" + item, "g"), "\\" + item);
        }
    });
    //匹配整个关键词
    re = new RegExp(keyword, "g");
    if (re.test(text)) {
        text = text.replace(re, `<${htmlTag} class="color-waiting">${keyword}</${htmlTag}>`);
    }
    return <span dangerouslySetInnerHTML={{ __html: text }}></span>;
}

/**
 * 时间初始化
 * @returns
 */
export function initTime() {
    const now = new Date(),
        h = now.getHours(),
        m = now.getMinutes();
    const nextH = h !== 24 ? h + 1 : 0;

    if (m < 30) return { startDate: formatDateTime(now, "yyyyMMddhh3000"), endDate: formatDateTime(now, "yyyyMMdd" + nextH + "0000") };
    else return { startDate: formatDateTime(now, "yyyyMMdd" + nextH + "0000"), endDate: formatDateTime(now, "yyyyMMdd" + nextH + "3000") };
}

/**
 * 处理Url，获取开始时间与结束时间参数
 * @param time
 * @returns
 */
export function getTime(time: string) {
    const newTime = new Date(+time.substring(0, 4), +time.substring(4, 6) - 1, +time.substring(6, 8), +time.substring(8, 10), +time.substring(10, 12), +time.substring(12, 14));
    return formatDateTime(newTime, "yyyy/MM/dd hh:mm:ss");
}

/**
 * 处理Url，获取orderBy参数
 * @param sf
 * @param sq
 */
export function getOrderBy(sortType, sf?, sq?) {
    let sortField: string, sequence: string, orderWord: string;

    switch (Number(sf)) {
        case SortNumberEnum.inputTime:
            orderWord = "isInputTimeDesc";
            sortField = sortType.inputTime;
            break;
        case SortNumberEnum.popularity:
            orderWord = "isPopularityDesc";
            sortField = sortType.popularity;
            break;
        default:
            orderWord = "isInputTimeDesc";
            sortField = "";
            break;
    }
    switch (Number(sq)) {
        case SeqEnum.asc:
            sequence = EnumWordEnum.asc;
            break;
        case SeqEnum.desc:
            sequence = EnumWordEnum.desc;
            break;
        default:
            sequence = "";
            break;
    }
    return { orderBy: sortField + sequence, orderWord };
}
/**
 * 渲染排序按钮
 */
export function sortButton(index, inputTime, popularity, callback) {
    return (
        <div className="sortBox">
            <router.Link
                className={"first sortButton " + (index === SortNumberEnum.inputTime ? "active" : "")}
                to={changeUrlParams(window.location.search, {
                    [CommonSearchParamInUrlEnum.sortField]: SortNumberEnum.inputTime,
                    [CommonSearchParamInUrlEnum.sortSequence]: inputTime ? SeqEnum.asc : SeqEnum.desc,
                })}
                onClick={() => callback(SortNumberEnum.inputTime, "isInputTimeDesc")}
            >
                最新发布{inputTime ? "↓" : "↑"}
            </router.Link>
            <router.Link
                className={"other sortButton last " + (index === SortNumberEnum.popularity ? "active" : "")}
                to={changeUrlParams(window.location.search, {
                    [CommonSearchParamInUrlEnum.sortField]: SortNumberEnum.popularity,
                    [CommonSearchParamInUrlEnum.sortSequence]: popularity ? SeqEnum.asc : SeqEnum.desc,
                })}
                onClick={() => callback(SortNumberEnum.popularity, "isPopularityDesc")}
            >
                热门参与{popularity ? "↓" : "↑"}
            </router.Link>
        </div>
    );
}
