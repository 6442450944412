import React from "react";

import { List } from "antd";

import { PureComponent, getObjectProp } from "@reco-m/core";

export namespace GDMap {
    export interface IProps extends PureComponent.IProps {
        address?: string;
        title?: string;
        mapStyle?: string;
        titleStyle?: string;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends GDMap.IProps = GDMap.IProps, S extends GDMap.IState = GDMap.IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            address: "浦东新区纳贤路800号",
            title: "地图",
            /*mapStyle: "acti_comment",
            titleStyle: "acticobt"*/
        };

        componentDidMount() {
            const { address } = this.props;
            const id = getObjectProp(client, "amap.script.id", `amap1`);
            const element = document.getElementById(id);

            setTimeout(() => {
                element && address && this.renderMapStyle(address!);
            }, 500);
        }

        renderMapStyle(address: string) {
            new AMap.plugin("AMap.Geocoder", () => {
                let geocoder = new AMap.Geocoder();
                geocoder.getLocation(address, (status: any, result: any) => {
                    
                    if (status === "complete" && result.geocodes.length) {
                        let lnglat = result.geocodes[0].location,
                            map = new AMap.Map("spacemap", {
                                zoom: 15,
                                center: [lnglat.lng, lnglat.lat],
                            }),
                            marker = new AMap.Marker({
                                position: new AMap.LngLat(lnglat.lng, lnglat.lat),
                                title: address,
                            });

                        map.add(marker);
                    }
                });
            });
        }

        render(): React.ReactNode {
            return (
                <List.Item>
                    <div className="spacemap" style={{ width: "100%", height: "300px" }} id="spacemap" />
                </List.Item>
            );
        }
    }
}
