import React from "react";

import { template } from "@reco-m/core";
import { ViewComponent } from "@reco-w/core-ui";
import { Namespaces, footerModel } from "@reco-w/layout-models";
import { Col, Row } from "antd";

export namespace PageFooter {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> { }
    export interface IState extends ViewComponent.IState, footerModel.StateType { }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.footer;

        componentDidMount() { }

        render() {
            return (
                <div className="footer">
                    <div className="container">
                        <Row gutter={[20, 20]} align="middle">
                            <Col xs={24} sm={24} xl={6} offset={1}>
                                <div className="footer-logo">
                                    <img src="assets/images/shengwu/footer-logo.png" />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} xl={13}>
                                {/* <div className="footer-link"><a>联系我们</a><span className="mh10">|</span><a>友情链接</a></div> */}
                                <div className="size-16 white-color mt10">
                                    参会报名及咨询热线：<span className="footer-tel mr40">021-58551691</span>
                                    技术支持热线：<span className="footer-tel">021-20231080-887</span>
                                </div>
                                <div className="size-14 white6 mt10">Copyright © 2022 张江生命科学国际创新峰会 All rights reserved 沪ICP备16035220号-1&nbsp;&nbsp;沪公网安备31011502020181号</div>
                            </Col>
                            <Col xs={24} sm={24} xl={4}>
                                {/* <div className="footer-code">
                                    <img src="assets/images/shengwu/code2.jpg" />
                                    <div className="size-14 white6 mt10">上海生物医药产业周</div>
                                </div> */}
                            </Col>
                        </Row>
                    </div>
                </div>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.footer]);
}
