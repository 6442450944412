// 我的服务记录
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, getCurrentParkId } from "@reco-m/core";
import { app, distinct, getSearchUrl } from "@reco-w/core-ui";

import { CommonSearchParamInUrlEnum, WorkOrderSearchParamInUrlEnum } from "@reco-w/common-common";
import { Namespaces as userNamespaces } from "@reco-w/auth-user-models";
import { workOrderService } from "@reco-w/workorder-service";
import { stateFlowService } from "@reco-w/approval-service";
import { FlowDisplayModeEnum } from "@reco-w/approval-models";
import { parkCateService } from "@reco-w/impression-service";
import { RateAuditStatusEnum } from "@reco-w/comment-models";
import { rateService } from "@reco-w/comment-service";

import { Namespaces } from "./common";
import { catalogCodeArr } from "./utils";

export namespace myserviceModel {
    export const namespace = Namespaces.myService;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
        evaluateModal: false,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, props, applyTabs }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getDataListByUrl", message, props, applyTabs });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        *getOrderCatalogs({ message }, { call, put }) {
            try {
                let catalogs = yield call(workOrderService.getStatisticsByCatalogue);
                catalogs = catalogs.filter((item) => catalogCodeArr.indexOf(item.CatalogueCode) > -1);
                catalogs.unshift({ CatalogueCode: "QB", CatalogueName: "全部工单类型", id: null });

                catalogs.forEach((x) => {
                    x.label = x.CatalogueName;
                    x.value = x.CatalogueCode;
                });

                yield put({ type: "input", data: { catalogs: catalogs } });
            } catch (e) {
                yield call(message!.error, "getOrderCatalogs：" + e.errmsg);
            }
        },

        *getDataListByUrl({ message, props, applyTabs }, { call, put }) {
            try {
                const params = getSearchUrl(
                    [
                        CommonSearchParamInUrlEnum.pageIndex,
                        CommonSearchParamInUrlEnum.pageSize,
                        WorkOrderSearchParamInUrlEnum.listcode,
                        CommonSearchParamInUrlEnum.statusIndex,
                        CommonSearchParamInUrlEnum.key,
                    ],
                    props
                );

                const status = applyTabs.find((x) => x.value === Number(params[CommonSearchParamInUrlEnum.statusIndex])) || { status: null, topicStatus: null };

                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params[CommonSearchParamInUrlEnum.pageIndex]) || 1,
                        pageSize: Number(params[CommonSearchParamInUrlEnum.pageSize]) || 10,
                        key: params[CommonSearchParamInUrlEnum.key],
                        code: params[WorkOrderSearchParamInUrlEnum.listcode],
                        status: status?.status,
                        statusIndex: Number(params[CommonSearchParamInUrlEnum.statusIndex]),
                    },
                });

                const data = {
                    parkId: getCurrentParkId(),
                    pageIndex: params[CommonSearchParamInUrlEnum.pageIndex] || 1,
                    pageSize: params[CommonSearchParamInUrlEnum.pageSize] || 10,
                    key: params[CommonSearchParamInUrlEnum.key],
                    isStateOrder: true,
                    status: status?.status,
                    topicStatus: status?.topicStatus,
                };
                yield put({ type: "getOrderList", message, data });
            } catch (e) {
                yield call(message!.error, "getDataListByUrl：" + e.errmsg);
            }
        },

        *getOrderList({ message, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                let func = (all, allRouteList, route, d) => {
                    if (all && all.length > 1) {
                        allRouteList[d] = all;
                        route.All = true;
                    }
                };
                const result = yield call(workOrderService.getByUser, {
                    ...data,
                });

                if (result.items && result.items.length > 0) {
                    for (let i = 0; i < result.items.length; i++) {
                        let item = result.items[i];
                        let routes = item.state && item.state.flow && item.state.flow.process && item.state.flow.process.routes;
                        let routeList = routes && routes.filter((r) => r.fromNodeId === item.state.project.currentNodeId);
                        let allRouteList = {};
                        if (routeList && routeList.some((x) => x.postConditions && x.postConditions.length > 0)) {
                            let routeByApi = yield call(stateFlowService.getStateRoutes, {
                                flowProjectId: item.state.project.id,
                                componentData: item.state.projectContent.componentData,
                            });
                            let routeNames = distinct(
                                routeByApi.map((d: any) => d.routeName),
                                []
                            );

                            let projectRouteList: any[] = [];

                            for (let j = 0; j < routeNames.length; j++) {
                                let d = routeNames[j];
                                let route = routeList.find((r: any) => r.routeName === d);
                                let all = routeList.filter((r: any) => r.routeName === d);
                                func(all, allRouteList, route, d);
                                projectRouteList.push(route);
                            }

                            routeList = projectRouteList;
                        }
                        item.RouteList = routeList;
                        item.AllRoute = allRouteList;
                    }
                }

                yield put({ type: "input", data: { Infos: result.items, totalPage: result.totalItems } });
            } catch (e) {
                yield call(message!.error, "getOrderList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *getOrder({ message, id, callback }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(workOrderService.get, id);

                if (result?.order?.catalogueId) yield put({ type: "getCatalog", id: result.order.catalogueId });

                yield yield put({ type: `${userNamespaces.user}/getCurrentUser` });
                const user: any = yield select((state) => state[userNamespaces.user]);

                yield put({ type: "input", data: { order: result, isInputer: result?.order?.inputerId === user?.currentUser?.id } });
                yield call(callback, result);
            } catch (e) {
                yield call(message!.error, "getOrder：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *getCatalog({ id, isJudgeFlow = true, callback, message }, { call, put }) {
            try {
                const result = yield call(parkCateService.get, id);

                if (isJudgeFlow) yield put({ type: "getCatalogDTO", data: result.catalogCode });

                yield put({
                    type: "input",
                    data: { catalogue: result },
                });

                if (callback) yield call(callback, result);
            } catch (e) {
                yield call(message!.error, "getCatalog：" + e.errmsg);
            }
        },

        /**
         * 获取加载进度
         */
        *getApplyLog({ data, message }, { call, put }) {
            try {
                let applyLogData = yield call(workOrderService.getLog, data);
                const billLogData = yield call(workOrderService.getBill, data);

                let applyLogArr = [] as any,
                    applyBackArr = [] as any;
                applyLogData.forEach((item) => {
                    if (item.description === "工单反馈") {
                        applyBackArr.push(item);
                    } else {
                        applyLogArr.push(item);
                    }
                });

                if (billLogData && billLogData.length) {
                    let sum = 0;
                    billLogData.forEach((r) => (sum += r.totalAmount));

                    applyBackArr.push({
                        inputTime: billLogData[0].inputTime,
                        inputer: billLogData[0].inputer,
                        type: "费用评估",
                        costDetail: billLogData,
                        sum,
                    });
                }

                yield put({
                    type: "input",
                    data: { applyLogData: applyLogArr.reverse(), applyBackData: applyBackArr },
                });
            } catch (e) {
                yield call(message!.error, "getApplyLog：" + e.errmsg);
            }
        },

        /**
         * 获取评价信息
         */
        *getCommentsAction({ message, id }, { call, put }) {
            try {
                let params = { bindTableName: "std_workorder", bindTableId: id };
                const result = yield call(rateService.getPaged.bind(rateService, { ...params, auditStatus: RateAuditStatusEnum.pass, isShowWaitAudit: true }));
                yield put({ type: "input", data: { comments: result.items } });
            } catch (e) {
                yield call(message!.error, "getCommentsAction：" + e.errmsg);
            }
        },

        *getCatalogDTO({ data, message }, { call, put }) {
            try {
                const result = yield call(workOrderService.getCatalogDTO, data);

                yield put({
                    type: "input",
                    data: {
                        code: data,
                        isForm: result && result.htmlModel === FlowDisplayModeEnum.form ? true : false,
                    },
                });
            } catch (e) {
                yield call(message!.error, e.errmsg);

                console.error(e);
            }
        },
    };
}
app.model(myserviceModel);
