import { EffectsMapObject, routerRedux } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers } from "@reco-m/core";
import { app, getParkCode, getSearchUrl } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";
import { policyDeclareService, policyImplementationService, policyService, policySpecialService } from "@reco-w/policy-service";
import { Namespaces, PolicyDeclareDetailTabIndexEnum, PolicyDeclareModeEnum } from "./common";

export namespace newPolicyDeclareDetailModel {
    export const namespace = Namespaces.newpolicyDeclareDetail;

    export const state: any = freeze({
        ...CoreState,
        showLoading: true,
        tabIndex: PolicyDeclareDetailTabIndexEnum.declare,
    });

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, id, props, callback }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getDeclareMode", message });
                yield put({ type: "getDataByUrl", message, props });
                yield put({ type: "getDetail", message, id, callback });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getDataByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl([CommonSearchParamInUrlEnum.tabIndex], props);

                yield put({ type: "input", data: { tabIndex: params[CommonSearchParamInUrlEnum.tabIndex] || PolicyDeclareDetailTabIndexEnum.declare } });
            } catch (e) {
                yield call(message!.error, "getDataByUrl：" + e.errmsg);
            }
        },

        /**
         * 获取详情
         * @param { message, id }
         * @param { call, put }
         */
        *getDetail({ message, id, callback }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(policyImplementationService.get, id);
                yield put({ type: "getPolicy", message, policyId: result?.policyId });
                yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, location: "/policy", title: result?.implementationDetailTitle, message });

                const state = yield select((state) => state[Namespaces.newpolicyDeclareDetail]),
                    declareMode = state!.declareMode;

                if (declareMode === PolicyDeclareModeEnum.complex && result) {
                    const specialList = yield call(policySpecialService.getSpecialDetailList, {
                        policyImplementationDetailId: result.id,
                        isValid: true,
                    });
                    result.special = specialList.find((x) => x.policyImplementationDetailId === result.id);
                }

                if (result?.special) {
                    result.declareStartTime = result.special.startDate;
                    result.specialId = result.special.id;
                    result.declareEndTime = result.special.endDate;
                }

                yield put({ type: "input", data: { detail: result } });

                if (callback) {
                    yield call(callback, result);
                }
            } catch (e) {
                yield call(message!.error, e.errmsg || "未找到该政策");
                yield put(routerRedux.replace(`/${getParkCode()}/policy`));
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取政策
         * @param { message, id }
         * @param { call, put }
         */
        *getPolicy({ message, policyId }, { call, put }) {
            try {
                const result = yield call(policyService.get, policyId);

                yield put({ type: "input", data: { policy: result } });
            } catch (e) {
                yield call(message!.error, "getPolicy：" + e.errmsg);
            }
        },

        /**
         * 获取申报类型
         * @param { message }
         * @param { call, put }
         */
        *getDeclareMode({ message }, { call, put }) {
            try {
                const config = yield call(policyService.getConfig);
                const unitConfig = config.unitConfig;
                yield put({ type: "input", data: { declareMode: unitConfig?.declareMode } });
            } catch (e) {
                yield call(message!.error, "getDeclareMode：" + e.errmsg);
            }
        },

        /**
         * 获取当前个人用户信息
         */
        *getCurrentConsumer({ message, callback }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.consumerCommon}/getCurrentConsumer`, message });
                const consumerCommonState: any = yield select((state) => state[commonNamespaces.consumerCommon]),
                    consumerDetail = consumerCommonState.consumerDetail;

                yield put({ type: "input", data: { consumerDetail } });

                if (callback) {
                    yield call(callback, consumerDetail);
                }
            } catch (e) {
                yield call(message!.error, "getCurrentConsumer：" + e.errmsg);
                yield put({ type: "input", data: { isSubmit: false } });
            }
        },

        /**
         * 获取简单版用户提交信息
         * @param { message }
         * @param { call, put,select }
         */
        *getValidDeclareStatus({ message, callback }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message });
                const memberState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    member = memberState.member;

                const state = yield select((state) => state[Namespaces.newpolicyDeclareDetail]),
                    detail = state!.detail,
                    customerId = member?.companyId;

                const isUnvalidDeclare = yield call(policyDeclareService.getValidDeclareStatus, customerId, detail?.id);

                if (callback) {
                    yield call(callback, isUnvalidDeclare);
                }
            } catch (e) {
                yield call(message!.error, "getValidDeclareStatus：" + e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubmit: false } });
            }
        },

        /**
         * 检查复杂版申报频率
         * @param { message }
         * @param { call, put,select }
         */
        *getCheckApplyFrequency({ message, callback }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                const state = yield select((state) => state[Namespaces.newpolicyDeclareDetail]),
                    detail = state!.detail,
                    consumerDetail = state!.consumerDetail;

                yield call(policySpecialService.checkApplyFrequency, detail?.special?.id, { consumerId: consumerDetail?.id });

                if (callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubmit: false } });
            }
        },
    };
}
app.model(newPolicyDeclareDetailModel);
