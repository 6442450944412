import { HttpService, resolveService } from "@reco-m/core";

export class AppBannerHttpService extends HttpService {
    constructor() {
        super("app/banner");
    }

    getParkBanner(data: any) {
        return this.httpGet("park-banner", this.resolveRequestParams(data));
    }
}

export const appBannerService = resolveService(AppBannerHttpService);
