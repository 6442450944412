import React from "react";
import { Prompt } from "react-router-dom";
import { __RouterContext as RouterContext } from "react-router";
import { ViewComponent } from "./view";

export namespace EditComponent {
    export interface IProps<S = any> extends ViewComponent.IProps<S> {}

    export interface IState extends ViewComponent.IState {}

    export abstract class Base<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        static pageType = "edit";
        static defaultProps = {
            ...ViewComponent.Base.defaultProps,
            classPrefix: "edit",
        };
        /**
         * 是否有编辑过,用于判断是否需要弹出确认框
         */
        hasModified = false;

        constructor(props: P, context: any) {
            super(props, context);
        }

        /**
         * 初始化离开时的方法
         */
        initConfirm() {
            // 刷新
            window.onbeforeunload = (event) => {
                if (this.hasModified) {
                    event.returnValue = "系统可能不会保存您所做的更改";
                }
            };
            // 关闭页面
            window.onunload = () => {
                if (this.hasModified) {
                    alert("系统可能不会保存您所做的更改。");
                }
            };
            // // 回退/前进
            // window.onpopstate = () => {
            //     if (this.hasModified) {
            //         alert("系统可能不会保存您所做的更改。");
            //     }
            // };
        }

        /**
         * 获取Prompt组件的when属性
         * @returns
         */
        getPromptWhen() {
            return true;
        }

        /**
         * 获取Prompt组件的message属性
         * @returns
         */
        getPromptMessage(..._args: any[]) {
            return "当前编辑的内容还没有保存，您确定要离开吗？";
        }

        /**
         * 渲染prompt组件，用于展示路由改变时的模态框
         * @returns prompt
         */
        renderPrompt(): React.ReactNode {
            return this.hasModified && <Prompt  when={this.getPromptWhen()} message={(location, action) => this.getPromptMessage(location, action)}></Prompt>;
        }

        render(): React.ReactNode {
            return (
                <RouterContext.Provider value={this.props as any}>
                    {super.render()}
                    {this.renderPrompt()}
                </RouterContext.Provider>
            );
        }
    }
}
