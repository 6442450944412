import { HttpService, resolveService } from "@reco-m/core";

/**
 * 评论
 */
export class CommentHttpService extends HttpService {
    constructor() {
        super("comment/comment");
    }
    /**
     * 获取评论个数
     */
    getCommentCount(data: any) {
        return this.httpGet("count", this.resolveRequestParams(data));
    }
    /**
     * 删除评论
     */
    delete(id) {
        return this.httpDelete("user-delete/" + id);
    }
    /**
     * 获取评论列表
     */
    getCommentList(data: any) {
        return this.httpGet("getCommentList", this.resolveRequestParams(data));
    }
}
export const commentService = resolveService(CommentHttpService);

/**
 * 评价
 */
export class RateHttpService extends HttpService {
    constructor() {
        super("rate/rate");
    }
    /**
     * 获取点赞数
     * @param id
     * @param name
     * @returns
     */
    getAgreeCount(id: any, name: any) {
        return this.httpGet("agree-info/" + id + "/" + name);
    }

    /**
     * 取消点赞
     * @param bindTableId
     * @param bindTableName
     */
    cancelAgree(bindTableId, bindTableName) {
        return this.httpDelete("cancel-agree/" + bindTableId + "/" + bindTableName);
    }
}

export const rateService = resolveService(RateHttpService);

/**
 * 举报
 */
export class InteractiveTipOffHttpService extends HttpService {
    constructor() {
        super("report/report");
    }
}
export const interactiveTipOffService = resolveService(InteractiveTipOffHttpService);
