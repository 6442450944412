import { EffectsMapObject } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { specialProjectService } from "@reco-w/declare-service";

import { Namespaces } from "./common";
import { DeclareBaseEffects } from "./declare.base.model";

export namespace declareFormModel {
    export const namespace = Namespaces.declareForm;
    export const state: any = freeze({ ...CoreState }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...CoreReducers,
        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        ...DeclareBaseEffects,
        /**
         * 提交申报
         * @param param0
         * @param param1
         */
        *submit({ message, data, callback }, { call }) {
            try {
                const result = yield call(specialProjectService.post, data);
                callback && callback(result);
            } catch (e) {
                yield call(message!.error, "submit" + e.errmsg);
            }
        },
    };
}
app.model(declareFormModel);
