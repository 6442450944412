import React from "react";
import { getObjectProp } from "../utils/get.object.prop";

const delayTime = getObjectProp(client, "sys.loadLazyModule.delayTime", 8000);

export function RouteLazyWaiting(Component: any, fallback: React.ReactNode) {
    return (props) => (
        <React.Suspense fallback={fallback!}>
            <Component {...props} />
        </React.Suspense>
    );
}

let quantity = 0;

export function createLoadLazyModule<T extends React.ComponentType<any>>(fallback: React.ReactNode) {
    return (factory: () => Promise<{ routes: T }>) => {
        let promise: Promise<{ routes: T }>;

        const time = delayTime ? setTimeout(() => (promise = factory()), delayTime + 3000 * quantity++) : 0;

        return RouteLazyWaiting(
            React.lazy(() => (promise || (time && clearTimeout(time), factory())).then(({ routes }) => ({ default: routes }))),
            fallback
        );
    };
}
