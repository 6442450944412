import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";

import { CoreState, CoreEffects, CoreReducers } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { notificationService } from "@reco-w/declare-service";

import { Namespaces, NOTIFICATION_IN_BOX } from "./common";

export namespace messageModel {
    export const namespace = Namespaces.message;
    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, callback }, { call, put, select }) {
            try {
                yield put({ type: "init", message });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser` });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState!.user,
                    currentUser = user && user!.currentUser;

                yield yield put({ type: `${Namespaces.nav}/getMessageUnReadCount` });
                const nav = yield select((state) => state[Namespaces.nav]);
                yield put({ type: "input", data: { unReadCount: nav?.messageCount, sceneId: nav?.sceneId, ownerId: currentUser.id } });
                callback && callback();
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        *getNotificationList({ message, props, param }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const params = getSearchUrl(["pi", "ps"], props);
                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 10,
                    },
                });
                const result = yield call(notificationService.getPaged, {
                    mailbox: NOTIFICATION_IN_BOX,
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || 10,
                    orderBy: "id desc",
                    ...param,
                });
                result.items?.forEach((item, index) => {
                    item.number = index + 1;
                });
                yield put({ type: "input", data: { messages: result.items, totalItems: result.totalItems && Number(result.totalItems) } });
            } catch (e) {
                yield call(message!.error, "getNotificationList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *readNotification({ message, id }, { call, put }) {
            try {
                yield call(notificationService.readNotification, id);
            } catch (e) {
                yield call(message!.error, "readNotification" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *readAllNotification({ message, data, callback }, { call, put }) {
            try {
                yield call(notificationService.readAllNotification, data);
                callback && callback();
            } catch (e) {
                yield call(message!.error, "readAllNotification" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(messageModel);
