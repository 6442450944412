import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { Namespaces } from "./common";

export namespace footerModel {
    export const namespace = Namespaces.footer;

    export const state: any = freeze(
        {
            ...CoreState,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        /**
         * 初始化页面
         * @param { message }
         * @param { call, put }
         */
        *initPage({ message, callback }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield put({ type: "getAppConfig", message, callback });
            } catch (e) {
                yield call(message!.error, "initPage" + e.errmsg);
            }
        },

        /**
         * 获取设置
         * @param { message }
         * @param { call, put, select }
         */
        *getAppConfig({ message, callback }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.configCommon}/getAppConfig` });
                const appConfigState: any = yield select((state) => state[commonNamespaces.configCommon]);
                const appConfig = appConfigState.appConfig;

                yield put({ type: "input", data: { appConfig } });

                if (callback) {
                    yield call(callback, appConfig);
                }
            } catch (e) {
                yield call(message!.error, "getCertify" + e.errmsg);
            }
        },
    };
}

export function registerPageFooterModel(model?: any) {
    app.model(model || footerModel);
}
