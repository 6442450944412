import { freeze } from "immer";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { CoreEffects, CoreState, CoreReducers, formatNow } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { rateService } from "@reco-w/comment-service";
import { cashInvoiceService, cashPayService } from "@reco-w/invoice-service";
import { IParkBindTableNameEnum } from "@reco-w/common-common";
import { myOrderService, resourceService } from "@reco-w/order-service";

import { Namespaces, OrderItemSkuTypeEnum, PayWayEnum } from "./common";
import { isRoom } from "./utils";
import { RateAuditStatusEnum } from "@reco-w/comment-models";

export namespace myOrderDetailModel {
    export const namespace = Namespaces.myOrderDetail;

    export const state: any = freeze({ ...CoreState, showloading: true, payway: PayWayEnum.alipay }, !0);
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, id, callback }, { put, call }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "refreshData", message, id, callback });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 刷新数据
         */
        *refreshData({ message, id, callback }, { call, put }) {
            try {
                yield put({ type: "getOrderDetailAction", message, id, callback });
                yield put({ type: "getOrderLogAction", message, id });
                yield put({ type: "getCommentsAction", message, id });
                yield put({ type: "getPayMessageAction", message, id });
                yield put({ type: "getInvoiceMessageAction", message, id });
            } catch (e) {
                yield call(message!.error, "refreshData：" + e.errmsg);
            }
        },

        /**
         * 获取订单详情
         */
        *getOrderDetailAction({ message, id, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(myOrderService.get, id);
                yield put({ type: "input", data: { order: result } });

                if (!isRoom(result?.orderSubType)) {
                    const orderItemCount = result?.orderItem?.map((x) => x.skuType === OrderItemSkuTypeEnum.goods)?.length || 0;
                    yield put({ type: "input", data: { orderItemCount } });
                }

                yield put({ type: "getResourceConfig", id: result?.bindTableId });
                let startDate = formatNow() + "  00:00:00";
                let endDate = formatNow() + " 23:59:00";
                let params = {
                    ResourceType: result?.resourceType,
                    StartDate: startDate,
                    EndDate: endDate,
                    ResourceIDs: [result?.bindTableId],
                };

                if (callback) {
                    yield call(callback, result);
                }

                yield put({ type: "getResourceDetailAction", id: result?.bindTableId, params, message });
            } catch (e) {
                yield call(message!.error, "getOrderDetailAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取资源配置
         */
        *getResourceConfig({ message, id }, { call, put }) {
            try {
                const result = yield call(resourceService.getResourceConfig, id);
                yield put({ type: "input", data: { resourceConfigure: result } });
            } catch (e) {
                yield call(message!.error, "getResourceConfig：" + e.errmsg);
            }
        },

        /**
         * 获取资源详情
         */
        *getResourceDetailAction({ message, id }, { call, put }) {
            try {
                const result = yield call(resourceService.get, id);

                yield put({ type: "input", data: { resourceDetail: result } });
            } catch (e) {
                yield call(message!.error, "getResourceDetailAction：" + e.errmsg);
            }
        },

        /**
         * 获取订单日志
         */
        *getOrderLogAction({ message, id }, { call, put }) {
            try {
                const result = yield call(myOrderService.getOrderLog.bind(myOrderService), id);
                yield put({ type: "input", data: { logs: result } });
            } catch (e) {
                yield call(message!.error, "getOrderLogAction：" + e.errmsg);
            }
        },

        /**
         * 获取评论
         */
        *getCommentsAction({ message, id }, { call, put }) {
            try {
                let params = { bindTableName: IParkBindTableNameEnum.order, bindTableId: id, auditStatus: RateAuditStatusEnum.pass, isShowWaitAudit: true };
                const result = yield call(rateService.getPaged, params);
                yield put({ type: "input", data: { comment: result.items && result.items.length > 0 && result.items[0] } });
            } catch (e) {
                yield call(message!.error, "getCommentsAction：" + e.errmsg);
            }
        },

        /**
         * 订单支付信息
         */
        *getPayMessageAction({ message, id }, { call, put }) {
            try {
                let params = { bindTableName: IParkBindTableNameEnum.order, bindTableId: id };
                const result = yield call(cashPayService.getList, params);

                yield put({ type: "input", data: { paymessage: result && result.length > 0 && result[0] } });
            } catch (e) {
                yield call(message!.error, "getPayMessageAction：" + e.errmsg);
            }
        },

        /**
         * 获取发票信息
         */
        *getInvoiceMessageAction({ message, id }, { call, put }) {
            try {
                let params = { bindTableName: IParkBindTableNameEnum.order, bindTableId: id };
                const result = yield call(cashInvoiceService.getPaged, params);
                yield put({ type: "input", data: { invoiceMessage: result && result.items && result.items.length > 0 && result.items[0] } });
            } catch (e) {
                yield call(message!.error, "getInvoiceMessageAction：" + e.errmsg);
            }
        },

        /**
         * 取消订单
         */
        *cancelOrder({ message, callBack }, { call, select }) {
            try {
                const state = yield select((state) => state[Namespaces.myOrderDetail]),
                    order = state.order;
                if (order && order.id) {
                    const result = yield call(myOrderService.cancelOrder, order.id);
                    if (result) {
                        callBack();
                    }
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            }
        },

        /**
         * 申请重新退款
         */
        *reRefundOrder({ message, callBack }, { call, select }) {
            try {
                const state = yield select((state) => state[Namespaces.myOrderDetail]),
                    order = state.order;
                if (order && order.id) {
                    const result = yield call(myOrderService.reRefundOrder, order.id);
                    if (result) {
                        callBack();
                    }
                }
            } catch (e) {
                yield call(message!.error, "reRefundOrder：" + e.errmsg);
            }
        },
    };
}

app.model(myOrderDetailModel);
