import { ArticleCatalogueEnum } from "@reco-w/article-models";
import { SearchTypeEnum } from "@reco-w/common-common";
import { getResourceName, ResourceTypeEnum } from "@reco-w/order-models";

export enum Namespaces {
    header = "header",
    footer = "footer",
}

export enum EnumParkTenantType {
    /**
     * 下属园区
     */
    managed = 1,

    /**
     * 直属园区
     */
    direct = 2
}

export const TIPS = [
    { type: SearchTypeEnum.activity, key: "Activity", name: "活动", menukey: `/activity` },
    { type: SearchTypeEnum.notice, key: "Notice", name: "通知", menukey: `/article/${ArticleCatalogueEnum.notification}` },
    { type: SearchTypeEnum.article, key: "News", name: "园区动态", menukey: `/article/${ArticleCatalogueEnum.article}` },
    { type: SearchTypeEnum.meeting, key: "MeetingRoom", name: "会议室", menukey: `/resource/${getResourceName(ResourceTypeEnum.meetingRoom)}` },
    { type: SearchTypeEnum.working, key: "CubicleRoom", name: "工位", menukey: `/resource/${getResourceName(ResourceTypeEnum.cubicleRoom)}` },
    { type: SearchTypeEnum.advertisement, key: "AdvertisingSpace", name: "广告位", menukey: `/resource/${getResourceName(ResourceTypeEnum.advertisingSpace)}` },
    { type: SearchTypeEnum.square, key: "Venue", name: "场地", menukey: `/resource/${getResourceName(ResourceTypeEnum.venue)}` },
    { type: SearchTypeEnum.service, key: "ServiceInstitution", name: "服务联盟", menukey: `/market/all` },
    { type: SearchTypeEnum.policy, key: "Policy", name: "政策", menukey: `/policy` },
    { type: SearchTypeEnum.circle, key: "Topic", name: "话题", menukey: `/circle` },
    { type: SearchTypeEnum.product, key: "ServiceProduct", name: "服务产品", menukey: `/market/all` },
    { type: SearchTypeEnum.survey, key: "Questionnaire", name: "问卷", menukey: `/survey` },
    { type: SearchTypeEnum.post, key: "Post", name: "动态", menukey: `/circle` },
    { type: SearchTypeEnum.companyHome, key: "CompanyHomePage", name: "企业主页", menukey: `/recruit` },
    { type: SearchTypeEnum.recruit, key: "CompanyRecruitment", name: "企业招聘", menukey: `/recruit` },
];
