import React from "react";

import {Upload, message, Modal, Button} from "antd";
import {UploadProps} from "antd/lib/upload";
import {InfoCircleOutlined, PlusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {Swiper, SwiperSlide} from "swiper/react";

import {UploadrWrap} from "@reco-m/core";

export namespace UploadWrap {
    export interface IProps extends UploadrWrap.IProps, UploadProps {
        id?: string;
        customUpload?: boolean;
        picture?: boolean;
        accept?: /* WebUploader.FilePicker.accept |*/ string;
        isShowTip?: boolean;
        tipText?: string;
        uploadText?: string | React.ReactNode;
        prefix?: React.ReactNode;
        suffix?: React.ReactNode;
    }

    export interface IState extends UploadrWrap.IState {
        files: any[];
        percentage?: number;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends UploadrWrap.Base<P, S> {
        showErrorMessage(content: string): void {
            message.error(content, 3);
        }

        onChange({file}: { file: any; fileList: any[] }) {
            if (file.isRemove) {
                this.removeFile(file);
            } else {
                const {files} = this.state;

                this.checkUploadFiles(
                    () => files.length + 1,
                    () => file.size + files.reduce((a, file) => a + file.size, 0)
                ) && this.uploadFiles([file]);
            }
        }

        onRemove(file: any) {
            file.isRemove = true;
        }

        getShowUploadList() {
            const {readonly} = this.props as any;

            return {
                showPreviewIcon: true,
                showRemoveIcon: !readonly,
                showDownloadIcon: true,
            };
        }

        renderUpload(): React.ReactNode {
            const {attachUploadService: uploadService, props: rawProps} = this,
                {className, multiple, children, readonly, isShowTip, tipText, prefix, suffix, ...props} = rawProps as any;

            return (
                <>
                    {prefix}

                    {isShowTip && !readonly && (
                        <div className="size-12 text-red margin-bottom-xs">
                            <InfoCircleOutlined/>
                            {tipText || "支持上传JPG、PNG、GIF格式图片"}
                        </div>
                    )}

                    <Upload
                        {...props}
                        accept={uploadService?.mimeTypes}
                        className={this.classnames(className, this.getClassSet(), readonly ? "readonly" : "")}
                        beforeUpload={this.beforeUpload.bind(this)}
                        fileList={this.state.files}
                        multiple={multiple}
                        onPreview={this.onPreview.bind(this)}
                        onChange={this.onChange.bind(this)}
                        onDownload={this.onDownload.bind(this)}
                        showUploadList={this.getShowUploadList()}
                        disabled={readonly}
                        onRemove={this.onRemove.bind(this)}
                    >
                        {this.renderUploadBtn()}
                        {children}
                        {suffix}
                    </Upload>
                </>
            );
        }

        renderUploadText() {
            const {uploadText = "上传"} = this.props as any;

            return uploadText;
        }

        renderUploadBtn(): React.ReactNode {
            const {customUpload, readonly, picture} = this.props as any;

            return customUpload || readonly ? null : picture ? (
                <div>
                    <PlusOutlined/>
                    <div>{this.renderUploadText()}</div>
                </div>
            ) : (
                <Button>
                    <PlusCircleOutlined/>
                    {this.renderUploadText()}
                </Button>
            );
        }

        renderModalBody(): React.ReactNode {
            return (
                <Swiper initialSlide={this.attachDataService.previewImageIndex} zoom={true} keyboard={true} navigation={true} pagination={false}>
                    {this.attachDataService.previewImages
                        .filter((f) => f.url)
                        .map((data, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <div className="reco-upload-item swiper-zoom-container">
                                        <img src={data.url} alt={data.fileName || data.name}/>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            );
        }

        renderModal() {
            return (
                <Modal
                    title={
                        <>
                            预览 <span className="size-12 text-error">(温馨提示：双击可放大，拖动滚动条预览大图)</span>
                        </>
                    }
                    wrapClassName="upload-modal"
                    footer={null}
                    width={"100%"}
                    visible={this.attachDataService.previewVisible}
                    destroyOnClose={true}
                    onCancel={() => this.onCancel()}
                >
                    {this.renderModalBody()}
                </Modal>
            );
        }
    }
}
