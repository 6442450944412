import { getLocalStorage, setLocalStorage, removeLocalStorage, getSessionStorage, setSessionStorage, removeSessionStorage } from "@reco-m/core";

type TypeLocalStorageAttr = "signArr" | "member" | "single";
/** 标准版用到的属性 */
type TypeLocalStorageAttrEx = "selectedMenu" | "mySelectedMenu" | "companyName" | "userId" | "userName" | "mobile";
interface IStorageSet {
    attr: TypeLocalStorageAttr;
    value;
    /** 存储时长 单位ms */
    timeout?: number;
}
export const LocalStorageEx = {
    set({ attr, value, timeout }: IStorageSet) {
        if (_non(value)) {
            setLocalStorage(attr, JSON.stringify({ value, timeout, inputTime: new Date().getTime() }));
        } else {
            this.remove(attr);
        }
    },
    get(attr: TypeLocalStorageAttr) {
        const jsonStr = getLocalStorage(attr);
        const json = jsonStr ? JSON.parse(jsonStr) : {};
        if (json.timeout && new Date().getTime() - json.inputTime > json.timeout) {
            this.remove(attr);
            return;
        }
        return json.value;
    },
    remove(attr: TypeLocalStorageAttr | TypeLocalStorageAttrEx) {
        removeLocalStorage(attr);
    },

    setLocalStorage(attr: TypeLocalStorageAttrEx, value) {
        setLocalStorage(attr, value);
    },
    getLocalStorage(attr: TypeLocalStorageAttrEx) {
        return getLocalStorage(attr);
    },
};

type TypeSessionStorageAttr = "attr1" | "attr2";
interface ISessionStorageSet {
    attr: TypeSessionStorageAttr;
    value;
    /** 存储时长 单位ms */
    timeout?: number;
}
export const SessionStorageEx = {
    set({ attr, value, timeout }: ISessionStorageSet) {
        if (_non(value)) {
            setSessionStorage(attr, JSON.stringify({ value, timeout, inputTime: new Date().getTime() }));
        } else {
            this.remove(attr);
        }
    },

    get(attr: TypeSessionStorageAttr) {
        const jsonStr = getSessionStorage(attr);
        const json = jsonStr ? JSON.parse(jsonStr) : {};
        if (json.timeout && new Date().getTime() - json.inputTime > json.timeout) {
            this.remove(attr);
            return;
        }
        return json.value;
    },

    remove(attr: TypeSessionStorageAttr) {
        removeSessionStorage(attr);
    },
};

function _non(value) {
    return value !== null && value !== void 0 && value !== "";
}
