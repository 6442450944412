import { HttpService, resolveService } from "@reco-m/core";
export class FlowDictsHttpService extends HttpService {
    constructor() {
        super("authorization/profile");
    }

    getDicts() {
        return this.httpGet("oa/mydict_custom");
    }
}
export const flowDictsService = resolveService(FlowDictsHttpService);
