import { EffectsMapObject } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { bankAccountService, specialProjectService } from "@reco-w/declare-service";

import { Namespaces, SpecialStateTagValueEnum, SpicialStateEnum } from "./common";

export namespace bankAccountEditModel {
    export const namespace = Namespaces.bankAccountEdit;
    export const state: any = freeze({ ...CoreState }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        /**
         * Init page
         * @param { message }
         * @param { call, put }
         */
        *initPage({ message }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield yield put({ type: "getUserId", message });
                yield put({ type: "getProjects", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取申报项目
         * @param { message, params }
         * @param { call, put, select }
         */
        *getProjects({ message }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.bankAccountEdit]);
                const { inputerId } = state;
                let data = yield call(specialProjectService.getList, {
                    userId: inputerId,
                    isAppropriation: true,
                    stateTagValue: SpecialStateTagValueEnum.waitFunding,
                    state: SpicialStateEnum.appropriationWait,
                });
                yield put({ type: "input", data: { projects: data } });
            } catch (e) {
                yield call(message!.error, "getProjects：" + e.errmsg);
            }
        },

        /**
         * 添加变更记录
         * @param { message, callback }
         * @param { call, put, select }
         */
        *add({ message, callback }, { call, put, select }) {
            try {
                yield put({ type: "input", data: { isSubmiting: true } });

                const state = yield select((state) => state[Namespaces.bankAccountEdit]);
                let data = {
                    specialProjectId: state!.specialProjectId,
                    projectName: state!.projectName,
                    projectCode: state!.projectCode,
                    projectDate: state!.projectDate,
                    oldAccountName: state!.oldAccountName,
                    oldBankName: state!.oldBankName,
                    oldBankAccount: state!.oldBankAccount,
                    newAccountName: state!.newAccountName,
                    newBankName: state!.newBankName,
                    newBankAccount: state!.newBankAccount,
                };

                const log = yield call(bankAccountService.post, data);

                if (callback) {
                    yield call(callback!, log);
                }
            } catch (e) {
                yield call(message!.error, "getProjects：" + e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubmiting: false } });
            }
        },

        /**
         * 获取用户Id
         * @param { fail }
         * @param { put, select }
         */
        *getUserId({ message }, { put, call, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser` });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user?.currentUser;
                yield put({ type: "input", data: { inputerId: user?.id } });
            } catch (e) {
                yield call(message!.error, "getUserId：" + e?.errmsg);
            }
        },
    };
}
app.model(bankAccountEditModel);
