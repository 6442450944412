/**
 * 命名空间
 */
export enum Namespaces {
    room = "room",
    roomDetail = "roomDetail",
    roomReserve = "roomReserve",
    position = "position",
    positionDetail = "positionDetail",
    positionReserve = "positionReserve",
    myOrder = "myOrder",
    myOrderDetail = "myOrderDetail",
    orderSubmit = "orderSubmit",
}

/**
 * 搜索栏参数枚举
 */
export enum SearchParamInUrlEnum {
    /**
     * 资源类型
     */
    resourceType = "ro",
    /**
     * 资源订单状态
     */
    resourceOrderStatus = "ros",
    /**
     * 项目Id
     */
    projectId = "pid",
    /**
     * 楼宇Id
     */
    buildingId = "bid",
    /**
     * 设备标签值
     */
    equipmentTagValue = "ev",
    /**
     * 容纳人数标签值
     */
    capacityTagTagValue = "ct",
    /**
     * 是否付费
     */
    isCharge = "c",
}

/**
 * 支付方式枚举
 */
export enum PayWayEnum {
    /**
     * 支付宝
     */
    alipay = 1,
    /**
     * 微信
     */
    wechat = 2,
    /**
     * 现金
     */
    cash = 3,
    /**
     * 积分
     */
    integral = 4,
}

/**
 * 订单状态枚举
 */
export enum OrderStatusEnum {
    /**
     * 待评论
     */
    comment = -1,
    /**
     * 待支付
     */
    unpaid = 2,
    /**
     * 待审核
     */
    unconfirmed = 1,
    /**
     * 已完成
     */
    succeeded = 4,
    /**
     * 待使用
     */
    used = 512,
    /**
     * 审核失败
     */
    failed = 8,
    /**
     * 订单取消
     */
    closed = 32,
    /**
     * 待退款
     */
    unRefunded = 64,
    /**
     * 退款成功
     */
    refunded = 128,
    /**
     * 退款失败
     */
    refundFail = 256,
    /**
     * 使用中
     */
    inuse = 1024,
}

/**
 * 点评状态枚举
 */
export enum OrderCommentTypeEnum {
    /**
     * 不能评论
     */
    none = 1,
    /**
     * 待评论
     */
    noComment = 2,
    /**
     * 已评论
     */
    comment = 3,
}

/**
 * 规格类型枚举
 */
export enum OrderItemSkuTypeEnum {
    /**
     * 主品
     */
    goods = 1,
    /**
     * 赠品
     */
    gift = 2,
    /**
     * 服务
     */
    service = 3,
    /**
     * 折扣
     */
    discount = 4,
    /**
     * 积分
     */
    integral = 5,
    /**
     * 卡券
     */
    coupon = 6,
}

/**
 * 收费单价枚举
 */
export enum PriceUnitEnum {
    /**
     * 元/半小时
     */
    yuanPerHalfHour = 1,
    /**
     * 元/小时
     */
    yuanPerHour = 2,
    /**
     * 元/天
     */
    yuanPerDay = 3,
    /**
     * 元/月
     */
    yuanPerMonth = 4,
    /**
     * 元/时段
     */
    yuanPerTimeSlot = 15,
}

/**
 * 服务类型枚举
 */
export enum ResourceServiceTypeEnum {
    /**
     * 提供设备
     */
    freeDevice = 1,

    /**
     * 可选服务
     */
    paymentService = 2,
}

/**
 * 资源状态枚举
 */
export enum ResourceStatusEnum {
    /**
     * 空闲
     */
    free = 0,

    /**
     * 已预订
     */
    booked = 1,

    /**
     * 未开放
     */
    unOpened = 3,

    /**
     * 未设置
     */
    notSet = 4,
}

/**
 * 资源类型枚举
 */
export enum ResourceTypeEnum {
    /**
     * 工位
     */
    cubicleRoom = 64,
    /**
     * 会议室
     */
    meetingRoom = 32,
    /**
     * 广告位
     */
    advertisingSpace = 2048,
    /**
     * 停车位
     */
    parkingLot = 256,
    /**
     * 场地
     */
    venue = 1024,
}

/**
 * 空间类型枚举
 */
export enum SpaceTypeEnum {
    /**
     * 园区
     */
    park = 1,
    /**
     * 项目
     */
    project = 2,
    /**
     * 楼宇
     */
    building = 4,
    /**
     * 楼层
     */
    floor = 8,
}

/**
 * 预订时积分类型枚举
 */
export enum ResourceOrderIntergralTypeEnum {
    /**
     * 不选
     */
    none = 0,
    /**
     * 个人
     */
    person = 1,
    /**
     * 企业
     */
    company = 2,
}

/**
 * 预订时的步数枚举
 */
export enum ResourcePositionOrderStepTypeEnum {
    /**
     * 确认订单
     */
    confirm = 0,
    /**
     * 提交成功
     */
    orderSuccess = 1,
}

/**
 * 支付方式
 */
export enum PaymentTypeEnum {
    /**
     * PC
     */
    native = 0,
    /**
     * 手机
     */
    app = 1,
    /**
     * 服务号
     */
    jsapi = 2,
    /**
     * H5
     */
    h5 = 3,
    /**
     * 小程序
     */
    miniProgram = 4,
}

/** 排序处枚举 */
/**
 * 排序枚举
 */
export enum SortRoomWordEnum {
    /**
     * 最新发布
     */
    newest = "id",
    /**
     * 占地面积
     */
    area = "estimateArea",
    /**
     * 容纳人数
     */
    people = "items",
    /**
     * 设备最全
     */
    device = "devices",
    /**
     * 价格
     */
    price = "price",
}

/**
 * 排序数字枚举
 */
export enum SortRoomNumberEnum {
    /**
     * 最新发布
     */
    newest = 1,
    /**
     * 占地面积
     */
    area = 2,
    /**
     * 容纳人数
     */
    people = 3,
    /**
     * 设备最全
     */
    device = 4,
    /**
     * 价格
     */
    price = 5,
}
/** END 排序处枚举 */
/**
 * 订单类型
 */
export enum OrderTypeEnum {
    /**
     * 工位预订
     */
    station = 2,
    /**
     * 会议室预订
     */
    meetingRoom = 3,
    /**
     * 广告位预订
     */
    advertising = 4,
    /**
     * 场地预订
     */
    venue = 6,
}

/**
 * 交易状态
 */
export enum TradeStatusEnum {
    /**
     * 交易创建
     */
    created = 1,
    /**
     * 交易关闭
     */
    closed = 2,
    /**
     * 交易挂起
     */
    pending = 3,
    /**
     * 交易成功
     */
    success = 4,
    /**
     * 交易完成
     */
    finished = 5,
}

/**
 * 会议室 时间选择类型枚举
 */
export enum MeetingRoomTimeEnum {
    /**
     * 今天
     */
    today = 1,
    /**
     * 明天
     */
    tomorrow = 2,
    /**
     * 自定义
     */
    custom = 3,
}

/**
 * 订单提交成功页展示类型
 */
export enum OrderSubmitSuccessPageShowType {
    /**
     * 取消
     */
    cancel,
    /**
     * 待支付
     */
    unpaid,
    /**
     * 已支付
     */
    paid,
    /**
     * 待审核
     */
    unconfirmed,
    /**
     * 无需审核
     */
    noconfirmed,
}

/**
 * 价格单位
 */
export enum MaxDayBookingTypeEnum {
    /**
     * 个人
     */
    person = 1,
    /**
     * 企业
     */
    company = 2,
}

/**
 * 会议室可预订范围 时间单位枚举
 */
export enum ResourceIntendedScopeUnitEnum {
    /**
     * 天
     */
    day = 1,

    /**
     * 周
     */
    week = 2,
    /**
     * 月
     */
    month = 3,
    /**
     * 年
     */
    year = 4,
}

export const START_DATE_TIME_FORMAT = "yyyy/MM/dd 00:00:00";
export const END_DATE_TIME_FORMAT = "yyyy/MM/dd 23:59:59";

export const START_TIME = " 00:00:00";
export const END_TIME = " 23:59:59";

/**
 * 我的订单tab，根据资源类型
 */
export const MY_ORDER_TABS_BY_RESOURCE_TYPE = [
    { label: "全部资源类型", value: "QB" },
    { label: "会议室", value: String(ResourceTypeEnum.meetingRoom) },
    { label: "广告位", value: String(ResourceTypeEnum.advertisingSpace) },
    { label: "场地", value: String(ResourceTypeEnum.venue) },
    { label: "工位", value: String(ResourceTypeEnum.cubicleRoom) },
];

/**
 * 我的订单tab，根据订单状态
 */
export const MY_ORDER_TABS_BY_STATUS = [
    { label: "全部订单状态", value: "QB", commentStatus: "" },
    { label: "待支付", value: OrderStatusEnum.unpaid, commentStatus: "", class: "color-waiting-pay" },
    { label: "待审核", value: OrderStatusEnum.unconfirmed, commentStatus: "", class: "color-failure", color: "orange" },
    { label: "待使用", value: OrderStatusEnum.used, commentStatus: "", class: "color-waiting-use" },
    { label: "使用中", value: OrderStatusEnum.inuse, commentStatus: "", class: "color-waiting-use" },
    { label: "待评价", value: OrderStatusEnum.succeeded + OrderCommentTypeEnum.noComment, commentStatus: OrderCommentTypeEnum.noComment, class: "color-failure" },
    { label: "已评价", value: OrderStatusEnum.succeeded + OrderCommentTypeEnum.comment, commentStatus: OrderCommentTypeEnum.comment, class: "color-green", color: "green" },
    { label: "已取消", value: OrderStatusEnum.closed, commentStatus: "", class: "color-success" },
    { label: "审核失败", value: OrderStatusEnum.failed, commentStatus: "", class: "color-red" },
    { label: "待退款", value: OrderStatusEnum.unRefunded, commentStatus: "", class: "color-success" },
    { label: "退款成功", value: OrderStatusEnum.refunded, commentStatus: "", class: "color-green" },
    // { label: "退款失败", value: OrderStatusEnum.refundFail, commentStatus: "", class: "color-red" },
];

/**
 * 会议室/场地，时间类型选择
 */
export const MEETINGROOM_TIME_TYPE = [
    { label: "今天", value: MeetingRoomTimeEnum.today },
    { label: "明天", value: MeetingRoomTimeEnum.tomorrow },
    { label: "选择时间", value: MeetingRoomTimeEnum.custom },
];
