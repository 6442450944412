import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { articleService } from "@reco-w/article-service";
import { NULL_POINTER } from "@reco-w/common-common";
import { Namespaces } from "./common";

export namespace previousreviewModel {
    export const namespace = Namespaces.previousreview;

    export type StateType = typeof state;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            open: false,
        },
        !0
    );

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

         /**
        * 初始化页面
        * @param { message, callback, id, listData }
        * @param { call, put }
        */
         *initPage({ message, callback, id }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield put({ type: "getArticleDetail", message, id, callback });
            } catch (e) {
                yield call(message!.error, "initPage" + e.errmsg);
            }
        },
        /**
         * 获取文章详情
         * @param { message, listData, id, callback }
         * @param { call, put }
         */
        *getArticleDetail({ message, id, callback }, { call, put }) {
            try {
                const articleDetail = yield call(articleService.get, id);
                let articleVM = articleDetail?.articleVM;

                yield put({
                    type: "input",
                    data: { articleDetail: articleDetail, articleId: id },
                });

                callback && callback(articleVM);
            } catch (e) {
                if (e?.errcode === NULL_POINTER) {
                    yield call(message!.error, "未找到该数据");
                } else {
                    yield call(message!.error, "getArticleDetail：" + e.errmsg);
                }
            }
        },
    };
}

app.model(previousreviewModel);
