import { IComp } from "@reco/util-func";
import { Pagination } from "antd";
// import React from 'react'

interface IProps extends IComp {
    list;
    onChange;
}
export function UtilPagination({ list = {}, onChange }: IProps) {
    return list.totalPages > 1 ? (
        <div className="list-paging">
            <Pagination current={list.currentPage} pageSize={list.itemsPerPage} total={list.totalItems} showSizeChanger={false} onChange={onChange} />
        </div>
    ) : null;
}
