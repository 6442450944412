import { EffectsMapObject } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { authService } from "@reco-w/auth-service";
import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { consumerService, specialProjectService, notificationSceneService, notificationService } from "@reco-w/declare-service";

import { Namespaces, SCENE_CODE, NOTIFICATION_IN_BOX } from "./common";

export namespace declareNavModel {
    export const namespace = Namespaces.nav;
    export const state: any = freeze({ ...CoreState }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...CoreReducers,
        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        /**
         * 获取当前用户信息
         * @param param0
         * @param param1
         */
        *getCurrentConsumer({ message, callback }, { call, put }) {
            try {
                const userData = yield call(authService.getCurrentUser),
                    user = userData && userData.currentUser;
                const result = yield call(consumerService.getCurrentConsumer);
                yield put({ type: "input", data: { user, consumer: result } });
                callback && callback(result);
            } catch (e) {
                yield call(message!.error, "getCurrentConsumer" + e.errmsg);
            }
        },
        /**
         * 设置用户类型
         * @param param0
         * @param param1
         */
        *setConsumerType({ message, consumerType, callback }, { call, put }) {
            try {
                const result = yield call(consumerService.setConsumerType, consumerType);
                yield put({ type: "input", data: { consumer: result } });
                callback && callback(result);
            } catch (e) {
                yield call(message!.error, "setConsumerType" + e.errmsg);
            }
        },

        *getProjectCount({ message }, { call, put }) {
            try {
                const result = yield call(specialProjectService.getProjectCount);
                yield put({ type: "input", data: { projectCount: result } });
            } catch (e) {
                yield call(message!.error, "getProjectCount" + e.errmsg);
            }
        },

        *getMessageUnReadCount({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser` });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState!.user,
                    currentUser = user && user!.currentUser;
                const scenes = yield call(notificationSceneService.getList);
                const sceneId = scenes?.find((d: any) => d.sceneCode === SCENE_CODE)?.id || 0;
                const unReadCount = yield call(notificationService.getNotificationCount, {
                    mailbox: NOTIFICATION_IN_BOX,
                    ownerId: currentUser.id,
                    isRead: false,
                    sceneId: sceneId,
                });
                yield put({ type: "input", data: { messageCount: unReadCount, sceneId: sceneId } });
            } catch (e) {
                yield call(message!.error, "getMessageUnReadCount" + e.errmsg);
            }
        },
    };
}
app.model(declareNavModel);
