// import {Mobclick} from "./umeng";

export interface StatisticsEvent {
    eventid: string;
    eventLabel: string;
}

export function setEventWithLabel(_event: StatisticsEvent) {
    // try {
    //     /* Mobclick().onEventWithLabel(eventid, eventLabel);  // 友盟*/
    //     MtaLinkH5.eventStats(event.eventid, { eventLabel: event.eventLabel }); // 腾讯统计
    // } catch (error) {
    //     console.error(error);
    // }
}

export function setPageBegin(_pageName) {
    // try {
    //     /*Mobclick().onPageBegin(pageName); // 友盟*/
    //     MtaLinkH5.pageBasicStats({ title: pageName }); // 腾讯统计
    // } catch (error) {
    //     console.error(error);
    // }
}

export function mtaH5Click(event: StatisticsEvent | undefined) {
    try {
        if (!window["_hmt"] || event) {
            return;
        }
        if (event) {
            window["_hmt"].push(["_trackCustomEvent", (event as StatisticsEvent).eventid, {}]);
        }
    } catch (error) {
        console.error(error);
    }
}

// function toLocaleLowerCase(str) {
//     str = str || "";
//     const result = str.toLocaleLowerCase();
//     return result.replace(/" "/g, "");
// }
