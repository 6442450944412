import { HttpService, resolveService } from "@reco-m/core";

export class UnitHttpService extends HttpService {
    constructor() {
        super("organization/unit");
    }

}

export const unitService = resolveService(UnitHttpService);


/** 人脉圈 */
class MemberCircleHttpService extends HttpService {
    constructor() {
        super("r2323-member/circle");
    }

    /** 判断是否已经加入人脉圈 */
    isJoin() {
        return this.httpGet("is-join");
    }
}
export const memberCircleService = resolveService(MemberCircleHttpService);

/** 洽谈邀请 */
class TalksHttpService extends HttpService {
    constructor() {
        super("talks/talks");
    }
}
export const talksService = resolveService(TalksHttpService);

/** 洽谈预约 */
class TalksAppointHttpService extends HttpService {
    constructor() {
        super("talks/talks-appoint");
    }

    audit(da) {
        return this.httpPut(`audit/${da.id}`, da);
    }

    /** 获取预约时间段标签 TALKS/YUYSJD */
    getOccupyTags(id) {
        return this.httpGet(`get-occupy-tags/${id}`);
    }
}
export const talksAppointService = resolveService(TalksAppointHttpService);

/** 峰会二维码 */
class SummitThirdHttpService extends HttpService {
    constructor() {
        super("summit/third");
    }

    getQrCode() {
        return this.httpGet(`get-qr-code`);
    }

    /** 获取企查查企业列表 */
    getQccCimpanyList(data: any) {
        return this.httpGet(`get-qcc-company-list`, this.resolveRequestParams(data));
    }
}
export const summitThirdService = resolveService(SummitThirdHttpService);
