import { articleService } from "@reco-w/article-service";
import { Button, Col, List, Row, Typography } from "antd";
import { ImageAuto } from "@reco-w/core-ui";
import { transformUrl } from "@reco-m/core";
import { getLanguageType } from "../comFunc";
import { OTime } from "../formatTime";
import { OGo } from "../objFunc";

export namespace OArticle {
    export enum EnumCatalogueCode {
        /** 峰会动态 */
        FHDT = "DTZX",
        /** 媒体报道 */
        MTBD = "TZTG",
        /** 峰会直播 */
        FHZB = "FHZB",
        /** 花絮集锦 */
        HXJJ = "HXJJ",
        /** 往届回顾 */
        WJHG = "WJHG",
    }

    export enum EnumContentType {
        /** 图文内容 */
        Form = "1",
        /** URL连接 */
        URL = "2",
    }

    export const ser = articleService;
    export const api = {
        getPaged<T extends { catalogueCode?; pageSize?}>(pa: T) {
            return ser.getPaged({ languageType: getLanguageType(), isPublish: true, orderBy: "publish_time desc,sequence,id desc", ...pa });
        },
    };

    export enum EnumItemType {
        /** 小卡片样式 */
        smallCard = "1",
        /** 图文 */
        picText = "2",
        /** 视频 */
        video = "3",
    }
    export function RenderItem({ item, that, itemType }: { item; that; itemType?: EnumItemType }) {
        if (getIsVideo(item)) {
            if (itemType === EnumItemType.video) {
                return (
                    <List.Item onClick={() => {
                        if (item.r2323ContenttypeTagValue === EnumContentType.URL)
                            window.open(item.articleUrl);
                        else
                            that.dispatch("input", { videoVisible: true, videoUrl: item.videoUrl, videoTitle: item.title });
                    }}>
                        <div className="home-broadcast-img">
                            <ImageAuto.Component src={getCover(item)} width="100%" height="210px" radius={16} />
                            <img src="assets/images/shengwu/play-icon.png" className="play-icon" />
                        </div>
                        <Typography.Paragraph ellipsis className="mb0 size-20 gray-color mt15 ant-text-center">
                            {item.title}
                        </Typography.Paragraph>
                    </List.Item>
                );
            }

            // 视频默认样式
            return (
                <div className="home-broadcast-item" onClick={() => {
                    if (item.r2323ContenttypeTagValue === EnumContentType.URL)
                        window.open(item.articleUrl);
                    else
                        that.dispatch("input", { videoVisible: true, videoUrl: item.videoUrl, videoTitle: item.title })
                }}>
                    <div className="home-broadcast-img">
                        <ImageAuto.Component src={getCover(item)} width="100%" height="207px" radius={16} />
                        <img src="assets/images/shengwu/play-icon.png" className="play-icon" />
                    </div>
                    <Typography.Paragraph ellipsis={{ rows: 1, expandable: false }} className="mb0 size-18 primary-color mt15 ant-text-center">
                        {item.title}
                    </Typography.Paragraph>
                </div>
            );
        } else if (item.catalogueCode === EnumCatalogueCode.WJHG) {
            // 往届回顾
            return (
                <List.Item>
                    <Row gutter={40}>
                        <Col xs={24} sm={24} xl={8}>
                            <ImageAuto.Component src={getCover(item)} width="100%" height="210px" radius={0} />
                        </Col>
                        <Col xs={24} sm={24} xl={16}>
                            <Typography.Paragraph ellipsis className="mb0 size-20 gray-color mt10">
                                {item.title}
                            </Typography.Paragraph>
                            <Typography.Paragraph ellipsis={{ rows: 3, expandable: false }} className="mb0 size-16 text mt15">
                                {item.summary}
                            </Typography.Paragraph>
                            <div className="mt20">
                                <Button type="primary" className="custom-btn1" shape="round" onClick={() => {
                                    if (item.r2323ContenttypeTagValue === EnumContentType.Form)
                                        OGo.go({ url: `details?id=${item.id}`, that })
                                    else if (item.r2323ContenttypeTagValue === EnumContentType.URL)
                                        window.open(item.articleUrl);
                                }}>
                                    回顾概况
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </List.Item>
            );
        }

        if (itemType === EnumItemType.picText) {
            return (
                <List.Item>
                    <Row gutter={40} className="hand" onClick={() => {
                        if (item.r2323ContenttypeTagValue === EnumContentType.Form)
                            goDetail({ item, that })
                        else if (item.r2323ContenttypeTagValue === EnumContentType.URL)
                            window.open(item.articleUrl);
                    }}>
                        <Col xs={24} sm={24} xl={18}>
                            <Typography.Paragraph ellipsis className="mb0 size-20 gray-color mt10">
                                {item.title}
                            </Typography.Paragraph>
                            <Typography.Paragraph ellipsis={{ rows: 2, expandable: false }} className="mb0 size-16 text mt10">
                                {item.summary}
                            </Typography.Paragraph>
                            <div className="custom-color1 size-14 mt12">{OTime.formatDate(item.publishTime)}</div>
                        </Col>
                        <Col xs={24} sm={24} xl={6}>
                            <ImageAuto.Component src={getCover(item)} width="100%" height="158px" radius={0} />
                        </Col>
                    </Row>
                </List.Item>
            );
        }

        // 小卡片是默认样式
        return (
            <div className="home-dynamic-item" onClick={() => {
                if (item.r2323ContenttypeTagValue === EnumContentType.Form)
                    goDetail({ item, that })
                else if (item.r2323ContenttypeTagValue === EnumContentType.URL)
                    window.open(item.articleUrl);
            }}>
                <ImageAuto.Component src={getCover(item)} width="100%" height="170px" radius={16} />
                <Typography.Paragraph ellipsis={{ rows: 2, expandable: false }} className="mb0 size-18 primary-color mt15">
                    {item.title}
                </Typography.Paragraph>
                <div className="size-14 white4 mt5">{OTime.formatDateCom(item.publishTime)}</div>
            </div>
        );
    }
    export function getCover(item) {
        return transformUrl(item.coverUrl || item.pictureUrlList?.[0] || "");
    }

    export function goDetail({ item, that }) {
        OGo.go({ url: `/${that.parkCode}/peakdynamic/details?id=${item.id}`, that });
    }
    export function goList({ code, that }) {
        let url =
            code === EnumCatalogueCode.MTBD
                ? "mediareport"
                : code === EnumCatalogueCode.FHZB
                    ? "summitlive"
                    : code === EnumCatalogueCode.HXJJ
                        ? "sidelights"
                        : code === EnumCatalogueCode.WJHG
                            ? "previousreview"
                            : "peakdynamic";
        OGo.go({ url, that });
    }
}

const videoArr = [OArticle.EnumCatalogueCode.FHZB, OArticle.EnumCatalogueCode.HXJJ];
function getIsVideo(item) {
    return videoArr.includes(item.catalogueCode);
}
