import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { Namespaces } from "./common";

export namespace consumerStepModel {
    export const namespace = Namespaces.iparkConsumerStep;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getCurrentConsumer", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },
        /**
         * 获取当前企业信息
         */
        *getCurrentConsumer({ message }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.consumerCommon}/getCurrentConsumer`, message });
                const consumerCommonState: any = yield select((state) => state[commonNamespaces.consumerCommon]),
                    consumerDetail = consumerCommonState.consumerDetail;
                yield put({ type: "input", data: { consumerDetail } });
            } catch (e) {
                yield call(message!.error, "getCurrentConsumer：" + e.errmsg);
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(consumerStepModel);
