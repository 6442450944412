import { EffectsMapObject } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { tagService } from "@reco-m/tag-service";
import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { bankAccountService } from "@reco-w/declare-service";

import { Namespaces, BANKACCOUNT_TAG_LIST, PROJECT_APPLY_YEAR_TAG_CODE } from "./common";

export namespace bankAccountModel {
    export const namespace = Namespaces.bankAccount;
    export const state: any = freeze({ ...CoreState }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        /**
         * Init page
         * @param { message }
         * @param { call, put }
         */
        *initPage({ message, props }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield yield put({ type: "getUserId", message });
                yield yield put({ type: "getTags", message });
                yield put({ type: "getPaged", message, props });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取分页列表
         * @param { message, props }
         * @param { call, put, select }
         */
        *getPaged({ message, props }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                const state = yield select((state) => state[Namespaces.bankAccount]);
                const { inputerId } = state;

                const tagUrlMaps = BANKACCOUNT_TAG_LIST.map((x) => x.urlMap);
                const params = getSearchUrl(["k", "pi", "ps", ...tagUrlMaps], props);

                let year: any;
                let applyTagIds: any[] = [];

                for (let item of BANKACCOUNT_TAG_LIST) {
                    let values: any[] = [];
                    if (params[item.urlMap]) {
                        values = params[item.urlMap].split(",");
                    }
                    let ids: any[] = [];
                    if (state![item.tagCode] && values.length && item.tagCode !== PROJECT_APPLY_YEAR_TAG_CODE) {
                        ids = state![item.tagCode].filter((t: any) => values.some((v: any) => t.tagValue === v)).map((t: any) => t.id);
                    }
                    applyTagIds = [...applyTagIds, ...ids];
                    if (item.tagCode === PROJECT_APPLY_YEAR_TAG_CODE && values?.length) {
                        year = values[0];
                    }
                    yield put({ type: "input", data: { [item.valueMap]: values } });
                }

                yield put({
                    type: "input",
                    data: {
                        key: params["k"],
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 10,
                        applyTagIds,
                        year,
                    },
                });

                const data = {
                    key: params["k"],
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || 10,
                    applyTagIds,
                    inputerId,
                    orderBy: "id desc",
                    year,
                };
                const result = yield call(bankAccountService.getPaged, data);

                yield put({ type: "input", data: { projectAccountLogList: result.items, totalItems: result.totalItems } });
            } catch (e) {
                yield call(message!.error, "getPaged：" + e?.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取标签
         * @param { message }
         * @param { call, put }
         */
        *getTags({ message }, { call, put }) {
            try {
                const tagter = yield call(tagService.getTagByTagClasses, { tagClass: BANKACCOUNT_TAG_LIST.map((x) => x.tagCode).join(",") });
                yield put({ type: "input", data: { ...tagter } });
            } catch (e) {
                yield call(message!.error, "getTags：" + e?.errmsg);
            }
        },

        /**
         * 获取用户Id
         * @param { fail }
         * @param { put, select }
         */
        *getUserId({ message }, { put, call, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser` });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user?.currentUser;
                yield put({ type: "input", data: { inputerId: user?.id } });
            } catch (e) {
                yield call(message!.error, "getUserId：" + e?.errmsg);
            }
        },
    };
}
app.model(bankAccountModel);
