export enum EnumTableName {
    article = "std_article",
    activity = "bi_activity",
    policy = "bi_policy",

    /** 账号 */
    account = "sys_account",
    member = "std_member_authentication",
    /** 走访 */
    visit = "bi_visit",
    /** 服务机构 */
    institution = "bi_service_institution",
}
