import { EffectsMapObject } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { specialProjectService } from "@reco-w/declare-service";

import { PROJECT_TAG_LIST, PROJECT_APPLY_YEAR_TAG_CODE, Namespaces, SpecialStateTagValueEnum } from "./common";
import { ProjectBaseEffects } from "./project.base.model";

export namespace draftProjectStateModel {
    export const namespace = Namespaces.drafProject;
    export const state: any = freeze({ ...CoreState }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...CoreReducers,
        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        ...ProjectBaseEffects,
        /**
         * 获取暂存列表
         * @param param0
         * @param param1
         */
        *getDraftList({ message, props, state }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                const tagUrlMaps = PROJECT_TAG_LIST.map((x) => x.urlMap);
                const params = getSearchUrl(["k", "pi", "ps", ...tagUrlMaps], props);
                let applyTagIds: any[] = [];
                let year: any;
                for (let item of PROJECT_TAG_LIST) {
                    let values: any[] = [];
                    if (params[item.urlMap]) {
                        values = params[item.urlMap].split(",");
                    }
                    let ids: any[] = [];
                    if (state![item.tagCode] && values.length) {
                        ids = state![item.tagCode].filter((t: any) => values.some((v: any) => t.tagValue === v)).map((t: any) => t.id);
                    }
                    applyTagIds = [...applyTagIds, ...ids];
                    if (item.tagCode === PROJECT_APPLY_YEAR_TAG_CODE && values?.length) {
                        year = values[0];
                    }
                    yield put({ type: "input", data: { [item.valueMap]: values } });
                }
                yield put({
                    type: "input",
                    data: {
                        key: params["k"],
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 10,
                        applyTagIds,
                        year,
                    },
                });
                let user = yield select((state) => state[Namespaces.nav]);
                if (!user!.consumer) {
                    yield yield put({ type: `${Namespaces.nav}/getCurrentConsumer` });
                    user = yield select((state) => state[Namespaces.nav]);
                }
                const data = {
                    key: params["k"],
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || 10,
                    applyTagIds,
                    year,
                    stateTagValue: SpecialStateTagValueEnum.waitSend,
                    consumerId: user!.consumer && user!.consumer.id,
                    orderBy: "id desc",
                };
                const result = yield call(specialProjectService.getDraftList, data);
                result.items?.forEach((item, index) => {
                    item.number = index + 1;
                });
                yield put({ type: "input", data: { projects: [] } });
                yield put({ type: "input", data: { projects: result.items, totalItems: result.totalItems && Number(result.totalItems) } });
            } catch (e) {
                yield call(message!.error, "getDraftList" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(draftProjectStateModel);
