import { HttpService, resolveService } from "@reco-m/core";
export class SpecialProjectHttpService extends HttpService {
    constructor() {
        super("special/project");
    }

    /**
     * 获取申报列表
     * @param data
     * @returns
     */
    getApplyList(data: any) {
        return this.httpGet("get-apply-list", this.resolveRequestParams(data));
    }

    /**
     * 获取退回列表
     * @param data
     * @returns
     */
    getBackList(data: any) {
        return this.httpGet("get-back-list", this.resolveRequestParams(data));
    }

    /**
     * 获取暂存列表
     * @param data
     * @returns
     */
    getDraftList(data: any) {
        return this.httpGet("get-draft-list", this.resolveRequestParams(data));
    }

    /**
     * 获取项目数量
     * @returns
     */
    getProjectCount() {
        return this.httpGet("get-project-count");
    }
}
export const specialProjectService = resolveService(SpecialProjectHttpService);
