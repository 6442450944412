import React from "react";
import { Modal } from "antd";
import SwiperCore, { Navigation, Pagination, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Row, Col, Carousel } from "antd";

import { PureComponent, transformAssetsUrl } from "@reco-m/core";

import { ImageAuto } from "./images.auto";

SwiperCore.use([Navigation, Pagination, Zoom]);

export namespace ImageFullScreen {
    export interface IProps extends PureComponent.IProps {
        /** 图片地址列表 */
        imgUrlList: any[];
        /** 裁剪的图片宽度 */
        cutWidth?: number;
        /** 裁剪的图片高度 */
        cutHeight?: number;
        /** 图片高度 */
        height?: string;
        /** 图片宽度 */
        width?: string;
        /** 栅格间隔，用于平铺时，默认为15 */
        rowgutter?: number;
        /** 栅格占位格数，用于平铺时，默认为4 */
        colspan?: number;
        /** 是否可点击， 默认为是 */
        canClick?: boolean;
        /** 是否是走马灯展示 */
        isCarousel?: boolean;
        /** 走马灯展示时的样式 */
        carouselClass?: string;
    }

    export interface IState extends PureComponent.IState {
        open?: boolean;
        index?: number;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            compress: false,
            rowgutter: 15,
            colspan: 4,
            canClick: true,
        };

        swiper: any;
        timeout: any;
        index: number;

        protected getInitState(_nextProps: Readonly<P>): Readonly<S> {
            return { open: false } as S;
        }

        handleImageErrored() {
            this.setState({ open: false });
        }

        componentWillUnmount() {
            clearTimeout(this.timeout);
        }

        /** 图片点击 */
        onImageClick(i) {
            this.setState({ open: true, index: i });
        }

        /** 图片取消 */
        onCancel() {
            this.setState({ open: false });
        }

        /** 图片显示模态框 */
        renderModalImage(): React.ReactNode {
            const { imgUrlList } = this.props;
            return (
                <Modal
                    title={
                        <div>
                            预览 <span className="size-12 text-error">(温馨提示：双击可放大，拖动滚动条预览大图)</span>
                        </div>
                    }
                    wrapClassName="upload-modal"
                    footer={null}
                    width={"100%"}
                    visible={this.state.open}
                    destroyOnClose={true}
                    onCancel={() => this.onCancel()}
                >
                    <Swiper initialSlide={this.state.index} zoom={true} keyboard={true} navigation={true} pagination={true} slidesPerView="auto">
                        {imgUrlList &&
                            imgUrlList.length > 0 &&
                            imgUrlList.map((e, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div className="reco-upload-item">
                                            <img src={transformAssetsUrl(e)} alt="" />
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                </Modal>
            );
        }

        /** 渲染走马灯展示 */
        renderCarousel(): React.ReactNode {
            const { width, height, imgUrlList, carouselClass } = this.props;
            return (
                <Carousel className={carouselClass || ""} dots={true} dotPosition={"bottom"} autoplay>
                    {imgUrlList && imgUrlList.length > 0 ? (
                        imgUrlList.map((item: any, i: any) => (
                            <img width={width} height={height} onClick={() => this.onImageClick(i)} key={i} src={transformAssetsUrl(item)} alt="" />
                        ))
                    ) : (
                        <img width={width} height={height} src={"assets/images/nopic.png"} alt="" />
                    )}
                </Carousel>
            );
        }

        /** 渲染栅格平铺 */
        renderGrid(): React.ReactNode {
            const { width, height, imgUrlList, colspan, canClick, cutHeight, cutWidth } = this.props;
            return (
                <div className="swiper-img mt15">
                    <Row gutter={[16,16]}>
                        {imgUrlList &&
                            imgUrlList.length > 0 &&
                            imgUrlList.map((img, i) => (
                                <Col onClick={() => (canClick ? this.onImageClick(i) : null)} span={colspan} key={i}>
                                    <ImageAuto.Component height={height} width={width} cutHeight={cutHeight} cutWidth={cutWidth} src={img} />
                                </Col>
                            ))}
                    </Row>
                </div>
            );
        }

        render(): React.ReactNode {
            const { isCarousel } = this.props;

            return (
                <>
                    {isCarousel ? this.renderCarousel() : this.renderGrid()}
                    {this.renderModalImage()}
                </>
            );
        }
    }
}
