export enum Namespaces {
    notice = "notice",
}

/**
 * 搜索栏参数枚举
 */
export enum SearchParamInUrlEnum {
    /**
     * 场景Id
     */
    sceneId = "sid",
}
