import { useState, useCallback } from "react";

export function useForceUpdate() {
    const [refreshFlag, fn] = useState<any>();
    const forceUpdate = useCallback(() => {
        fn({});
    }, []);
    return [forceUpdate, refreshFlag];
}

export function useOtherObj(initData = {}) {
    const [obj] = useState<any>(initData);
    const [forceUpdate] = useForceUpdate();
    const assign = useCallback((pa) => {
        Object.assign(obj, pa);
    }, []);
    const update = useCallback((pa) => {
        assign(pa);
        forceUpdate();
    }, []);

    return { data: obj, assign, update };
}
