import { EffectsMapObject } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { consumerService } from "@reco-w/declare-service";

import { Namespaces } from "./common";

export namespace consumerDetailModel {
    export const namespace = Namespaces.consumerDetail;
    export const state: any = freeze({ ...CoreState }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...CoreReducers,
        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        /**
         * 获取当前用户信息
         * @param param0
         * @param param1
         */
        /** 获取用户详情 */
        *getCurrentConsumer({ message, callback }, { call, put }) {
            try {
                const result = yield call(consumerService.getCurrentConsumer);
                yield put({ type: "input", data: { detail: result, ...result } });
                callback && callback(result);
            } catch (e) {
                yield call(message!.error, "getCurrentConsumer" + e.errmsg);
            }
        },
    };
}
app.model(consumerDetailModel);
