import { HttpService, resolveService } from "@reco-m/core";

export class R2323ActivityHttpService extends HttpService {
    constructor() {
        super("r2323-activity/activity");
    }

    // 获取峰会信息
    getSingle(data?: any) {
        return this.httpGet("get-single", this.resolveRequestParams(data));
    }

    // 获取组织架构
    getOrganization(data: any) {
        return this.httpGet("get-organization", this.resolveRequestParams(data));
    }

    // 获取峰会议程
    getGroups(id: any) {
        return this.httpGet("get-groups/" + id);
    }

    // 获取活动的时间区间
    getDateRange(id: any) {
        return this.httpGet("get-date-range/" + id);
    }
}

export const r2323ActivityService = resolveService(R2323ActivityHttpService);

// 峰会嘉宾
export class R2323ActivityContributorHttpService extends HttpService {
    constructor() {
        super("r2323-activity/activity-contributor");
    }

    getList(data: any) {
        return this.httpGet("list", this.resolveRequestParams(data));
    }

    getForumContributorList(data: any) {
        return this.httpGet("get-forum-contributor-list", this.resolveRequestParams(data));
    }
}

export const r2323ActivityContributorService = resolveService(R2323ActivityContributorHttpService);

// 峰会合作伙伴
export class R2323ActivityPartnerHttpService extends HttpService {
    constructor() {
        super("r2323-activity/activity-partner");
    }

    getList(data: any) {
        return this.httpGet("list", this.resolveRequestParams(data));
    }
}

export const r2323ActivityPartnerService = resolveService(R2323ActivityPartnerHttpService);

export class PartnersHttpService extends HttpService {
    constructor() {
        super("cooperate-partners/partner");
    }
}

export const partnersHttpServiceService = resolveService(PartnersHttpService);


// 峰会图片
export class R2323ActivityPictureHttpService extends HttpService {
    constructor() {
        super("r2323-activity/activity-picture");
    }

    getList(data: any) {
        return this.httpGet("list", this.resolveRequestParams(data));
    }
}

export const r2323ActivityPictureService = resolveService(R2323ActivityPictureHttpService);

// 峰会联系方式
export class R2323ActivityContactHttpService extends HttpService {
    constructor() {
        super("r2323-activity/activity-contact");
    }

    getList(data: any) {
        return this.httpGet("list", this.resolveRequestParams(data));
    }
}

export const r2323ActivityContactService = resolveService(R2323ActivityContactHttpService);

// 论坛议程
export class ActivityAgendaHttpService extends HttpService {
    constructor() {
        super("activity/activity-agenda");
    }

    getList(data: any) {
        return this.httpGet("list", this.resolveRequestParams(data));
    }
}

export const activityAgendaService = resolveService(ActivityAgendaHttpService);

// 峰会报名
export class R2323ActivityApplyHttpService extends HttpService {
    constructor() {
        super("r2323-activity/activity-apply");
    }

    // 判断是否报名
    getIsApply(data: any) {
        return this.httpGet("is-apply", this.resolveRequestParams(data));
    }

    addBatch(da) {
        return this.httpPost("add-batch", da);
    }
}

export const r2323ActivityApplyService = resolveService(R2323ActivityApplyHttpService);
