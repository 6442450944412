import { pictureService, transformUrl as transformUrlOld } from "@reco-m/core";
import { EnumTableName } from "./enum";
export namespace OFile {
    export const api = {
        async getPic({ bindTableName, bindTableId, customType, needOne }: { bindTableName?; bindTableId?; customType?; needOne? }) {
            const pics = await pictureService.getPictureList({
                bindTableName,
                bindTableId,
                customType,
            });
            if (needOne) {
                return transformUrl(pics[0]?.filePath);
            } else {
                return pics;
            }
        },

        getHeadPic(memberId) {
            return api.getPic({ bindTableName: EnumTableName.member, bindTableId: memberId, customType: 8, needOne: true });
        },
    };

    export const transformUrl = transformUrlOld;
    /** 本地图片 */
    export function getAssetsImages(url) {
        return `assets/images/${url}`;
    }
    export function getAssetsImagesPro(url) {
        return `assets/images/pro/${url}`;
    }
}
