import { CommonSearchParamInUrlEnum, IParkBindTableNameEnum } from "@reco-w/common-common";
import { getParkCode, getWholeUrl } from "@reco-w/core-ui";
import { MemberManagerEnum } from "@reco-w/member-models";
import { Modal } from "antd";
import { Namespaces } from ".";

export const DisJumpBindTableName = ["StdFlowProjectTask"]; // 不跳转的BindTableName

/**
 * 判断是否需要跳转
 * @param item
 */
export function isJump(item) {
    let tableName = item.message && item.message.bindTableName;

    let isNotJump = DisJumpBindTableName.contains(tableName); // 通过BindTableName跳转

    return { isNotJump };
}

/**
 * 获取一般路由
 * @param self 页面this
 * @param item
 */
export function getUrl(self, item) {
    let tableName = item.message.bindTableName,
        externalurl = item.message.externalUrl;
    let url = "/" + getParkCode();
    if (tableName) {
        if (tableName.indexOf(IParkBindTableNameEnum.biServiceAuditRecord) > -1) {
            url = url + "/my/mymarket";
        } else if (tableName.indexOf(IParkBindTableNameEnum.stdInfoReachObjectLog) > -1) {
            const bindTableName = getParam(externalurl, "bindTableName");
            const bindTableId = getParam(externalurl, "bindTableId");
            const pushSubjectId = getParam(externalurl, "pushSubjectId");
            const objectId = getParam(externalurl, "objectId");
            const objectLogId = getParam(externalurl, "objectLogId");

            url = url + `/msgreach?bindTableName=${bindTableName}&bindTableId=${bindTableId}&pushSubjectId=${pushSubjectId}&objectId=${objectId}&objectLogId=${objectLogId}`;
        } else if (tableName.indexOf(IParkBindTableNameEnum.certify) > -1) {
            const { state } = self.props,
                member = state!.member || {};
            const parkId = getParam(externalurl, "parkId");
            if (externalurl.indexOf(IParkBindTableNameEnum.memberAudit) > -1) {
                const companyId = getParam(externalurl, "companyId");
                const accountId = getParam(externalurl, "id");
                url =
                    url +
                    `/my/myperson/${accountId}/${item.message.bindTableId}?${CommonSearchParamInUrlEnum.titleTab}=${MemberManagerEnum.audit}&companyId=${companyId}&parkId=${parkId}`;
            } else {
                const certifyId = getParam(externalurl, "authenticationId");
                !member.id ? (url = url + `/my/mycertifyform`) : (url = url + `/my/certifyDetail?certifyId=` + certifyId + "&parkId=" + parkId);
            }
        } else if (tableName.indexOf(IParkBindTableNameEnum.stdFollow) > -1) {
            url = url + `/my/mycircle/?${CommonSearchParamInUrlEnum.titleTab}=5`;
        } else if (tableName.indexOf(IParkBindTableNameEnum.post) > -1) {
            url = url + `/circle/post/${externalurl.split("?id=")[1]}`;
        } else if (tableName.indexOf(IParkBindTableNameEnum.comment) > -1) {
            if (externalurl.indexOf(IParkBindTableNameEnum.post) > -1) {
                url = url + `/circle/post/${getParam(externalurl, "bindTableId")}`;
            } else if (externalurl.indexOf(IParkBindTableNameEnum.article) > -1) {
                url = url + `/article/${getParam(externalurl, "bindTableId")}`;
            } else if (externalurl.indexOf(IParkBindTableNameEnum.activity) > -1) {
                url = url + `/activity/${getParam(externalurl, "bindTableId")}`;
            }
        } else if (tableName.indexOf(IParkBindTableNameEnum.stdFlowState) > -1 || tableName.indexOf(IParkBindTableNameEnum.workOrderLog) > -1) {
            let result = (externalurl.split("?id=") || ["", ""])[1];
            url = url + `/my/myservedetail?id=${result}`;
        } else if (tableName.includes(IParkBindTableNameEnum.businessBill)) {
            url = url + `/my/myBusinessBill/${getParam(externalurl, "id")}`;
        } else if (tableName === IParkBindTableNameEnum.policyImplementation) {
            url = url + `/policy/${item.message.bindTableId}`;
        } else if (tableName === IParkBindTableNameEnum.policy) {
            url = url + `/policy/0/${item.message.bindTableId}`;
        } else if (tableName === IParkBindTableNameEnum.institution) {
            url = url + `/my/mymarket`;
        } else if (tableName === IParkBindTableNameEnum.companyHomePage) {
            url = url + `/my/myEnterprise`;
        } else if (tableName === IParkBindTableNameEnum.recruit) {
            url = url + `/my/myRecruit/${item.message.bindTableId}`;
        } else if (tableName === IParkBindTableNameEnum.datareport) {
            url = url + `/my/datareport/1/report/${item.message.bindTableId}`;
        }
    } else if (externalurl) {
        if (externalurl && externalurl.indexOf("resource_order") > -1) {
            url = url + `/my/orderDetails/${externalurl.split("?id=")[1]}`;
        } else {
            //
        }
    }
    return url;
}

/**
 * 点击消息
 * @param self 页面this
 * @param e
 * @param item 单条消息
 */
export function clickNoticeItem(self, e, item) {
    let tableName = item.message.bindTableName,
        externalurl = item.message.externalUrl;

    if (!tableName) {
        goToUrl(self, item);
    }

    if (tableName.indexOf(IParkBindTableNameEnum.certify) > -1) {
        if (externalurl.indexOf(IParkBindTableNameEnum.memberAudit) > -1) {
            self.dispatch({
                type: `${Namespaces.notice}/checkCertify`,
                customerId: getParam(externalurl, "companyId"),
                parkId: getParam(externalurl, "parkId"),
                callback: (isMember) => {
                    if (!isMember) {
                        e && e.preventDefault();
                        Modal.error({ title: "消息提醒", content: "你已不是该企业管理员" });
                    } else {
                        goToUrl(self, item);
                    }
                },
            });
        } else {
            goToUrl(self, item);
        }
    } else {
        goToUrl(self, item);
    }
}

export function goToUrl(self, item) {
    window.open(getWholeUrl(getUrl(self, item)));
}

/**
 * 根据url获取指定key的value
 * @param url url
 * @param paramName key
 */
export function getParam(url, paramName) {
    let x = (url?.split("?") || ["", ""])[1];
    let y = x?.split("&") || [""];
    let res = "";
    y.forEach((e) => {
        if (e.indexOf(paramName + "=") > -1) {
            res = e.split(paramName + "=")[1];
        }
    });
    return res;
}
