export enum Namespaces {
    comment = "comment",
    commentInput = "commentInput",
    commentTipoff = "commentTipoff",
    evaluate = "evaluate",
    evaluateList = "evaluateList",
}
/**
 * 举报来源枚举
 */
export enum TipOffSourceEnum {
    app = 2, // app
    web = 1, // web
}

/**
 * 评价类型枚举
 */
export enum TopicRateTypeEnum {
    /**
     * 评价
     */
    rate = 1,
    /**
     * 点赞
     */
    agree = 2,
}

/**
 * 评论来源（值）
 */
export enum TopicSourceTypeEnum {
    /**
     * Web
     */
    web = 1,
    /**
     * App
     */
    app = 2,
    /**
     * PC后台
     */
    pc = 3,
}

/**
 * 举报类型
 */
export enum TipoffTypeEnum {
    /**
     * 举报
     */
    tipoff = 1,
    /**
     * 申诉
     */
    appeal = 2,
}

/**
 * 举报状态枚举
 */
export enum TipoffStatusEnum {
    /**
     * 审核失败
     */
    fail = -1,
    /**
     * 待审核
     */
    audit = 0,
    /**
     * 审核成功
     */
    success = 1,
}

/**
 * 评论审核状态枚举
 */
export enum CommentAuditStatusEnum {
    /**
     * 待审核
     */
    waitAudit = 0,
    /**
     * 审核通过
     */
    pass = 1,
    /**
     * 审核退回
     */
    fail = 2,
}

/**
 * 评论审核状态枚举
 */
export enum RateAuditStatusEnum {
    /**
     * 待审核
     */
    waitAudit = 0,
    /**
     * 审核通过
     */
    pass = 1,
    /**
     * 审核退回
     */
    fail = 2,
}

/**
 * 默认回复长度
 */
export const DEFAULT_TEXTAREA_MAX_LENGTH = 500;
/**
 * 默认预文字
 */
export const DEFAULT_TEXTAREA_PLACEHOLDER = "说点什么呢...";
