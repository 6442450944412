import { EffectsMapObject } from "dva";

import { specialProjectService, specialService } from "@reco-w/declare-service";

export const DeclareBaseEffects: EffectsMapObject = {
    /**
     * 获取申报详情
     * @param param0
     * @param param1
     */
    *getDeclareDetail({ message, id, callback }, { call, put }) {
        try {
            const result = yield call(specialProjectService.get, id);
            yield put({ type: "input", data: { declareDetail: result } });
            callback && callback(result);
        } catch (e) {
            yield call(message!.error, "getDeclareDetail" + e.errmsg);
        }
    },

    /**
     * 获取专项详情
     * @param param0
     * @param param1
     */
    *getSpecialDetail({ message, id, callback }, { call, put }) {
        try {
            const result = yield call(specialService.get, id);
            yield put({ type: "input", data: { specialDetail: result } });
            callback && callback(result);
        } catch (e) {
            yield call(message!.error, "getSpecialDetail" + e.errmsg);
        }
    },
};
