import React from "react";
import { UploadProps } from "antd/lib/upload";

import { BasePictureDetail } from "@reco-m/core";

import { getPictureDefaultProps } from "./util";
import { UploadWrap } from "./upload.wrap";

export namespace PictureDetail {
    export interface IProps extends BasePictureDetail.IProps, UploadProps {}

    export interface IState extends BasePictureDetail.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends BasePictureDetail.Component<P, S> {
        static defaultProps = getPictureDefaultProps();

        render(): React.ReactNode {
            return <UploadWrap.Component {...this.props} readonly picture/>;
        }
    }
}
