import { getCurrentParkId, HttpService, resolveService } from "@reco-m/core";

/**
 * 服务联盟--机构管理
 */
export class MarketHttpService extends HttpService {
    /**
     * 服务联盟受理模式记录
     */
    protected institutionAcceptanceModePromise: any;
    constructor() {
        super("service-alliance/service-institution");
    }

    /**
     * 服务机构校验手机
     */
    getValidateMobile(mobile) {
        return this.httpGet("validate-institution-mobile/" + mobile + "/" + getCurrentParkId());
    }

    /**
     * 获取我最新提交的服务机构
     */
    getMyInstitution() {
        return this.httpGet("my-service-institution", this.resolveRequestParams({}, { parkId: getCurrentParkId() as any }));
    }

    /**
     * 获取短信验证码
     * @param mobile
     * @param auditId
     */
    messageVerifiCode(mobile, auditId) {
        return this.httpGet(`message-verification-code/${mobile}/${auditId}`);
    }

    /**
     * 校验短信验证码
     * @param mobile
     * @param auditId
     * @param data
     */
    validateMessageCode(mobile, auditId, data) {
        return this.httpGet(`validate-message-verification-code/${mobile}/${auditId}`, this.resolveRequestParams(data));
    }

    /**
     * 获取受理模式
     */
    getAcceptanceMode() {
        this.institutionAcceptanceModePromise || (this.institutionAcceptanceModePromise = this.httpGet("acceptance-mode"));
        return this.institutionAcceptanceModePromise || Promise.resolve({});
    }

    /**
     * 清除受理模式
     */
    clearAcceptanceMode() {
        delete this.institutionAcceptanceModePromise;
    }

    /**
     * 获取服务机构协议
     */
    getProtocol() {
        return this.httpGet("institution-service-agreement");
    }

    /**
     * 获取服务工单
     * @param data
     */
    getMyInstitutionAndProductWorkOrder(data) {
        return this.httpGet("my-institution-and-product-work-order", this.resolveRequestParams(data));
    }

    /**
     * 判断是否是变更联系人
     */
    isChangeContactPerson(id) {
        return this.httpGet("is-change-contact-person/" + id);
    }
}

export const marketService = resolveService(MarketHttpService);

/**
 * 服务联盟--产品管理
 */
export class ServiceProductService extends HttpService {
    constructor() {
        super("service-alliance/service-product");
    }

    /**
     * 产品状态及对应状态的数量
     * @returns
     */
    getProductStatusNumber(data?) {
        return this.httpGet("service-product-status-number", this.resolveRequestParams(data));
    }

    /**
     * 产品上下架
     * @param id
     * @param isOnService
     */
    onService(id, isOnService) {
        return this.httpPut("on-service/" + id + "/" + isOnService);
    }

    /**
     * 获取产品协议
     */
    getConfig() {
        return this.httpGet("product-service-agreement");
    }
    /**
     * 取消审核
     */
    cancelProductAudit(id: any) {
        return this.httpPut(`cancel-product-audit/${id}`);
    }
}

export const serviceProductService = resolveService(ServiceProductService);
