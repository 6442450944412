import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { declareFormModel } from "@reco-w/declare-models";

import { Namespaces } from "./common";

export namespace newPolicyDeclareFormComplexModel {
    export const namespace = Namespaces.newpolicyDeclareFormComplex;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        ...declareFormModel.effects,
    };
}
app.model(newPolicyDeclareFormComplexModel);
