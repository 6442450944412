export enum Namespaces {
    nav = "nav",
    personal = "personal",
    special = "special",
    appliedProject = "appliedProject",
    finishProject = "finishProject",
    backProject = "backProject",
    drafProject = "drafProject",
    declareForm = "declareForm",
    declareDetail = "declareDetail",
    policyDetail = "policyDetail",
    consumerDetail = "consumerDetail",
    consumerEdit = "consumerEdit",
    message = "message",
    bankAccount = "bankAccount",
    bankAccountDetail = "bankAccountDetail",
    bankAccountEdit = "bankAccountEdit",
}

/**
 * 用户标识
 */
export enum ConsumerTypeEnum {
    /**
     * 个人用户
     */
    personal = 1,
    /**
     * 企业用户
     */
    enterprise = 2,
    /**
     * 专家库
     */
    expert = 3,
}

/**
 * 状态机状态标签值
 */
export enum SpecialStateTagValueEnum {
    /**
     * 待受理
     */
    waitAccept = 1,
    /**
     * 受理中
     */
    processing = 5,
    /**
     * 评审中
     */
    assessing = 10,
    /**
     * 待拨款
     */
    waitFunding = 15,
    /**
     * 拨款成功
     */
    fundingSuccess = 20,
    /**
     * 待提交
     */
    waitSend = -5,
    /**
     * 已退回
     */
    back = -10,
    /**
     * 申报完成
     */
    finish = 25,
}

/**
 * 状态机状态值
 */
export enum SpicialStateEnum {
    /**
     * 待拨款
     */
    appropriationWait = 160,
    /**
     * 拨款待确认
     */
    appropriationToBeConfirmed = 170,
}

/**
 * 专项项目操作
 */
export enum SpecialProjectTriggerEnum {
    /**
     * 提交
     */
    specialProjectSubmitTrigger = "SpecialProjectSubmitTrigger",
    /**
     * 审核通过
     */
    specialProjectApprovalTrigger = "SpecialProjectApprovalTrigger",
    /**
     * 退回修改
     */
    specialProjectReturnModifyTrigger = "SpecialProjectReturnModifyTrigger",
    /**
     * 导出
     */
    specialProjectExportTrigger = "SpecialProjectExportTrigger",
    /**
     * 撤销
     */
    specialProjectRevokeTrigger = "SpecialProjectRevokeTrigger",
    /**
     * 不予受理
     */
    specialProjectDismissTrigger = "SpecialProjectDismissTrigger",
    /**
     * 退回上一环节
     */
    specialProjectReturnTrigger = "SpecialProjectReturnTrigger",
    /**
     * 修改
     */
    specialProjectModifyTrigger = "SpecialProjectModifyTrigger",
    /**
     * 公示
     */
    specialProjectPublicityTrigger = "SpecialProjectPublicityTrigger",
    /**
     * 资助
     */
    specialProjectSupportTrigger = "SpecialProjectSupportTrigger",
    /**
     * 新建拨款单
     */
    specialProjectNewFundingTrigger = "SpecialProjectNewFundingTrigger",
    /**
     * 确认拨款
     */
    specialProjectFundingTrigger = "SpecialProjectFundingTrigger",
    /**
     * 暂存
     */
    specialProjectDraftTrigger = "SpecialProjectDraftTrigger",
    /**
     * 生成批次
     */
    specialProjectGenerateBatchTrigger = "SpecialProjectGenerateBatchTrigger",
    /**
     * 提交验收单
     */
    specialProjectSubmitAcceptanceTrigger = "SpecialProjectSubmitAcceptanceTrigger",
    /**
     * 立项发文
     */
    specialProjectInitiatingDocumentTrigger = "SpecialProjectInitiatingDocumentTrigger",
    /**
     * 删除
     */
    specialProjectDeleteTrigger = "SpecialProjectDeleteTrigger",
    /**
     * 提交合同
     */
    specialProjectContractTrigger = "SpecialProjectContractTrigger",
    /**
     * 提交中期检查资料
     */
    specialProjectSubmitInspectionTrigger = "SpecialProjectSubmitInspectionTrigger",
    /**
     * 提交延期变更申请
     */
    specialProjectSubmitDelayTrigger = "SpecialProjectSubmitDelayTrigger",
}

/**
 * 政策类别标签code
 */
export const POLICY_TYPE_TAG_CODE = "Policy/zhengclb";
/**
 * 发布部门标签code
 */
export const POLICY_DEPARTMENT_TAG_CODE = "POLICY/fabbm";
/**
 * 申报年份
 */
export const PROJECT_APPLY_YEAR_TAG_CODE = "PROJECT/SHENBNF";
/**
 * 消息收件箱
 */
export const NOTIFICATION_IN_BOX = 2;
/**
 * 消息场景编码
 */
export const SCENE_CODE = "Special";
/**
 * 消息通知
 */
export const NOTIFICATION_TYPE_MESSAGE = 1;
/**
 * 专项标签集合
 */
export const SPECIAL_TAG_LIST = [
    { tagCode: POLICY_TYPE_TAG_CODE, urlMap: "pt", valueMap: "selectPolicyTypeValues", title: "政策类别", upset: "zclb" },
    { tagCode: POLICY_DEPARTMENT_TAG_CODE, urlMap: "pd", valueMap: "selectPolicyDepartmentValues", title: "发布部门", upset: "fbbm" },
];
/**
 * 申报项目标签合集
 */
export const PROJECT_TAG_LIST = [
    { tagCode: PROJECT_APPLY_YEAR_TAG_CODE, urlMap: "ay", valueMap: "selectProjectApplyYearValues", title: "申报年份", upset: "sbnf" },
    { tagCode: POLICY_DEPARTMENT_TAG_CODE, urlMap: "pd", valueMap: "selectPolicyDepartmentValues", title: "发布部门", upset: "fbbm" },
];
/**
 * 拨款项目标签合集
 */
export const BANKACCOUNT_TAG_LIST = [
    { tagCode: PROJECT_APPLY_YEAR_TAG_CODE, urlMap: "ay", valueMap: "selectProjectApplyYearValues", title: "申报年份", upset: "sbnf" },
    { tagCode: POLICY_DEPARTMENT_TAG_CODE, urlMap: "pd", valueMap: "selectPolicyDepartmentValues", title: "发布部门", upset: "fbbm" },
    { tagCode: POLICY_TYPE_TAG_CODE, urlMap: "pt", valueMap: "selectPolicyTypeValues", title: "政策类别", upset: "zclb" },
];

/**
 * 申报状态
 */
export const SPECIAL_STATE_TAG = [
    { text: "待受理", value: SpecialStateTagValueEnum.waitAccept },
    { text: "受理中", value: SpecialStateTagValueEnum.processing },
    { text: "评审中", value: SpecialStateTagValueEnum.assessing },
    { text: "待拨款", value: SpecialStateTagValueEnum.waitFunding },
    { text: "拨款成功", value: SpecialStateTagValueEnum.fundingSuccess },
    { text: "已退回", value: SpecialStateTagValueEnum.back },
];

export const MOBILE_REGEXP = /^1[0-9][0-9]{9}$/;
export const EMAIL_REGEXP = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
export const TEL_REGEXP = /^((\d{3,4})|\d{3,4}-|s)?\d{7,14}$/;
export const IDCARD_REGEXP =
    /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/;
export const FOX_REGEXP = /^(?:\d{3,4}-)?\d{7,8}(?:-\d{1,6})?$/;
export const CREDITCODE_REGEXP = /^[A-Z0-9]{18}$/;
export const POSTCODE_REGEXP = /^\d{6}$/;
