import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getCurrentUnitId } from "@reco-m/core";
import { app, mtaH5Click, statisticsEvent } from "@reco-w/core-ui";

import { getConfigValue, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberSignService, integralEventService, integralintegralService, integralOperateService } from "@reco-w/member-service";

import { Namespaces, IntergralRuleTypeEnum, CurrentIntergralTypeEnum, IntergralTitleTabEnum, SearchParamInUrlEnum } from "./common";
import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";
import { configService } from "@reco-w/common-service";

export namespace memberIntergralModel {
    export const namespace = Namespaces.memberIntergral;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
        currentIntergralType: CurrentIntergralTypeEnum.person, // 0个人 1企业
        ruleType: IntergralRuleTypeEnum.earn, // 0赚取  1支出
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, currentIntergralType }, { call, put }) {
            try {
                yield put({ type: "isSign", message });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getUserLoyalty`, message });
                yield put({ type: "getLoyal", currentIntergralType: currentIntergralType, message });
                yield put({ type: "getCertifyMember", message });
                yield put({ type: "loyalGetConfig", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 判断是否已签到
         */
        *isSign({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getUserLoyalty`, message });
                const memberState = yield select((state) => state[commonNamespaces.memberCommon]),
                    userLoyalty = memberState.userLoyalty;
                const result = yield call(memberSignService.signInfo, userLoyalty.personalSetId);

                yield put({
                    type: "input",
                    data: {
                        isSign: result.isSign,
                        continuitySignDay: result.continueSignDay,
                        todayIntegral: result.todayIntegral,
                        tomorrowIntegral: result.tomorrowIntegral,
                    },
                });
            } catch (e) {
                yield call(message!.error, "isSign：" + e.errmsg);
            }
        },
        /**
         * 处理Url，获取列表
         */
        *getOperatesListByUrl({ message, params, startDate, endDate }, { call, put }) {
            try {
                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params[CommonSearchParamInUrlEnum.pageIndex]) || 1,
                        pageSize: Number(params[CommonSearchParamInUrlEnum.pageSize]) || 10,
                        currentIntergralType: params[SearchParamInUrlEnum.intergralType],
                        ruleType: params[SearchParamInUrlEnum.ruleType],
                        titleTab: params[CommonSearchParamInUrlEnum.titleTab],
                        startDate: startDate || null,
                        endDate: endDate || null,
                    },
                });

                if (params[CommonSearchParamInUrlEnum.titleTab] === IntergralTitleTabEnum.rule) {
                    yield put({
                        type: "getRule",
                        currentIntergralType: params[SearchParamInUrlEnum.intergralType],
                    });
                }
                yield put({
                    type: "getLoyal",
                    currentIntergralType: params[SearchParamInUrlEnum.intergralType],
                });
            } catch (e) {
                yield call(message!.error, "getOperatesListByUrl：" + e.errmsg);
            }
        },
        *getIntegralSign({ message, callback }, { call, put, select }) {
            // 签到
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getUserLoyalty`, message });
                const memberState = yield select((state) => state[commonNamespaces.memberCommon]),
                    userLoyalty = memberState.userLoyalty;
                yield call(memberSignService.sign, userLoyalty.personalSetId);
                mtaH5Click(statisticsEvent.myIntergralSignIn);
                yield put({ type: "isSign", message });
                yield call(callback);
            } catch (e) {
                yield call(message!.error, "getIntegralSign：" + e.errmsg);
            }
        },
        *getRule({ currentIntergralType, message }, { call, put }) {
            // 积分规则
            try {
                yield put({ type: "showLoading" });
                if (currentIntergralType === CurrentIntergralTypeEnum.person) {
                    yield put({ type: "personEarnIntergral", currentIntergralType: CurrentIntergralTypeEnum.person, message });
                    yield put({ type: "personSpendIntergral", currentIntergralType: CurrentIntergralTypeEnum.person, message });
                } else {
                    yield put({ type: "companyEarnIntergral", currentIntergralType: CurrentIntergralTypeEnum.company, message });
                    yield put({ type: "companySpendIntergral", currentIntergralType: CurrentIntergralTypeEnum.company, message });
                }
            } catch (e) {
                yield call(message!.error, "getRule" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        *personEarnIntergral({ currentIntergralType, message }, { select, call, put }) {
            // 个人挣积分列表
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getUserLoyalty`, message });

                const memberState = yield select((state) => state[commonNamespaces.memberCommon]),
                    userLoyalty = memberState.userLoyalty;

                const result = yield call(integralEventService.integralEvent, {
                    setId: currentIntergralType === CurrentIntergralTypeEnum.company ? userLoyalty.companySetId : userLoyalty.personalSetId,
                    prefix: "positive",
                    eventType: 1, // 个人
                });

                if (result) {
                    yield put({ type: "input", data: { personEarn: result } });
                }
            } catch (e) {
                yield call(message!.error, "personEarnIntergral" + e.errmsg);
            }
        },
        *personSpendIntergral({ message, currentIntergralType }, { select, call, put }) {
            // 个人花积分列表
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getUserLoyalty`, message });

                const memberState = yield select((state) => state[commonNamespaces.memberCommon]),
                    userLoyalty = memberState.userLoyalty;

                const result = yield call(integralEventService.integralEvent, {
                    setId: currentIntergralType === CurrentIntergralTypeEnum.company ? userLoyalty.companySetId : userLoyalty.personalSetId,
                    prefix: "negative",
                    eventType: 1, // 个人
                });

                if (result) {
                    yield put({ type: "input", data: { personSpend: result } });
                }
            } catch (e) {
                yield call(message!.error, "personSpendIntergral" + e.errmsg);
            }
        },
        *companyEarnIntergral({ currentIntergralType, message }, { select, call, put }) {
            // 企业挣积分列表
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getUserLoyalty`, message });

                const memberState = yield select((state) => state[commonNamespaces.memberCommon]),
                    userLoyalty = memberState.userLoyalty;

                const result = yield call(integralEventService.integralEvent, {
                    setId: currentIntergralType === CurrentIntergralTypeEnum.company ? userLoyalty.companySetId : userLoyalty.personalSetId,
                    prefix: "positive",
                    eventType: 2, // 企业
                });

                if (result) {
                    yield put({ type: "input", data: { companyEarn: result } });
                }
            } catch (e) {
                yield call(message!.error, "companyEarnIntergral" + e.errmsg);
            }
        },
        *companySpendIntergral({ currentIntergralType, message }, { select, call, put }) {
            // 企业花积分列表
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getUserLoyalty`, message });

                const memberState = yield select((state) => state[commonNamespaces.memberCommon]),
                    userLoyalty = memberState.userLoyalty;

                const result = yield call(integralEventService.integralEvent, {
                    setId: currentIntergralType === CurrentIntergralTypeEnum.company ? userLoyalty.companySetId : userLoyalty.personalSetId,
                    prefix: "negative",
                    eventType: 2, // 企业
                });
                if (result) {
                    yield put({ type: "input", data: { companySpend: result } });
                }
            } catch (e) {
                yield call(message!.error, "companySpendIntergral" + e.errmsg);
            }
        },
        *getLoyal({ currentIntergralType, message }, { call, put, select }) {
            // 获取总积分
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getUserLoyalty`, message });
                const memberState = yield select((state) => state[commonNamespaces.memberCommon]),
                    userLoyalty = memberState.userLoyalty;

                if (userLoyalty) {
                    const loyalID = currentIntergralType === CurrentIntergralTypeEnum.company ? userLoyalty.companySetId : userLoyalty.personalSetId;
                    let loyalty: any;
                    if (loyalID) {
                        loyalty = yield call(integralintegralService.get, loyalID);
                    }
                    const totalJiFen = loyalty ? loyalty.availableIntegral : 0;

                    yield put({ type: "input", data: { totalJiFen: totalJiFen } });

                    if (currentIntergralType === CurrentIntergralTypeEnum.person) yield put({ type: "input", data: { userIntergral: totalJiFen } });
                } else {
                    yield put({ type: "input", data: { totalJiFen: 0, userIntergral: 0 } });
                }
            } catch (e) {
                yield call(message!.error, "getLoyal：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *loyalGetConfig({ message }, { call, put }) {
            // 积分说明
            try {
                // const result = yield call(memberLoyaltyService.getConfig.bind(memberLoyaltyService));
                const configList = yield call(configService.getConfigGroup, { configGroup: "MEMBER/INTEGRAL", unitId: getCurrentUnitId()});
                if (configList) {
                    const result = {
                        integralProfile: getConfigValue(configList, 'AGREEMENT_SYNOPSIS'),
                        integralObtain: getConfigValue(configList, 'AGREEMENT_ACQUIRE'),
                        integralUse: getConfigValue(configList, 'AGREEMENT_USE'),
                    };
                    yield put({ type: "input", data: { intergralConfigure: result } });
                }
            } catch (e) {
                yield call(message!.error, "loyalGetConfig：" + e.errmsg);
            }
        },

        *getOperatesAction({ data, currentIntergralType, message }, { select, call, put }) {
            // 获取积分获取和花费列表
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getUserLoyalty`, message });

                const memberState = yield select((state) => state[commonNamespaces.memberCommon]),
                    userLoyalty = memberState.userLoyalty;
                let x = {
                    setId: currentIntergralType === CurrentIntergralTypeEnum.company ? userLoyalty.companySetId : userLoyalty.personalSetId,
                };
                let result: any = {};
                if (x.setId) {
                    if (currentIntergralType === CurrentIntergralTypeEnum.company) {
                        result = yield call(integralOperateService.getCompanyPage, { ...data, ...x });
                    } else {
                        result = yield call(integralOperateService.getUserPage, { ...data, ...x });
                    }
                }

                yield put({ type: "input", data: { operates: result?.items || [], operatesTotal: Number(result?.totalItems || 0) } });
            } catch (e) {
                yield call(message!.error, "getOperatesAction：" + e.errmsg);
            }
        },
        *getCertifyMember({ message }, { select, put, call }) {
            try {
                // 获取用户信息
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentMemberInfo`, message, isRefreshMember: true });

                const memberState = yield select((state) => state[commonNamespaces.memberCommon]),
                    member = memberState.member;
                yield put({
                    type: "input",
                    data: {
                        member: member && member.id ? member : null,
                    },
                });
            } catch (e) {
                yield call(message!.error, "getCertifyMember" + e.errmsg);
            }
        },
    };
}
app.model(memberIntergralModel);
