import { getObjectProp } from "@reco-m/core";

/**
 * 电话号码，带区号
 */
export const PHONE_WITH_AREA_REGEXP = /^0\d{2,3}-?\d{7,8}$/;
/**
 * 电话号码，不带区号
 */
export const PHONE_WITHOUT_AREA_REGEXP = /^\d{8}$/;
/**
 * 校验数字
 */
export const NUMBER_REGEXP = /^[0-9]*$/;

/**
 * 空指针报错
 */
export const NULL_POINTER = "NULL_POINTER";

/**
 * 是否在Header里调用查询工单的接口
 */
export const IS_GET_ORDER_ON_HEADER = getObjectProp(client, "isgetOrderOnHeader", true);

/**
 * 是否开启行政审批
 */
export const IS_OPEN_RECRUIT = getObjectProp(client, "app.openRecruit", false);
/**
 * 是否开启招聘
 */
export const IS_OPEN_ENTERPRISE_HOME = getObjectProp(client, "app.openEnterpriseHome", false);
/**
 * 是否开启企业主页
 */
export const IS_OPEN_ADMIN_APPROVAL = getObjectProp(client, "app.openAdminiApproval", false);
/**
 * 是否开启政策申报
 */
export const IS_OPEN_POLICY_DECLARE = getObjectProp(client, "app.openPolicyDeclare", false);
/**
 * 是否开启企业账单
 */
export const IS_OPEN_BUSINESS_BILL = getObjectProp(client, "app.openBusinessBill", false);
