import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces } from "./common";

export namespace sidelightsModel {
    export const namespace = Namespaces.sidelights;

    export type StateType = typeof state;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            open: false,
        },
        !0
    );

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects
    };
}

app.model(sidelightsModel);
