import { EffectsMapObject } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { consumerService, specialProjectService } from "@reco-w/declare-service";

import { Namespaces } from "./common";

export namespace personalBasicModel {
    export const namespace = Namespaces.personal;
    export const state: any = freeze({ ...CoreState }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...CoreReducers,
        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ message }, { put }) {
            put({ type: "showLoading" });
            try {
                yield put({ type: "getCurrentConsumer", message });
                yield put({ type: "getProjectCount", message });
            } catch (e) {
                message!.error(`${e?.errmsg || e}`);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取当前用户信息
         * @param param0
         * @param param1
         */
        *getCurrentConsumer({ message }, { call, put }) {
            try {
                const result = yield call(consumerService.getCurrentConsumer);
                console.log(result, 1234);
                yield put({ type: "input", data: { consumer: result, ...result } });
            } catch (e) {
                yield call(message!.error, "getCurrentConsumer" + e.errmsg);
            }
        },

        *putPersonal({ message, callback }, { select, call }) {
            try {
                let state = yield select((state) => state[Namespaces.personal]),
                    consumer = state!.consumer,
                    contactAddress = state!.contactAddress,
                    contactEmail = state!.contactEmail,
                    contactFox = state!.contactFox,
                    contactMobile = state!.contactMobile,
                    contactTel = state!.contactTel,
                    consumerNumber = state!.consumerNumber,
                    name = state!.name,
                    remarks = state!.remarks,
                    data = { contactAddress, contactEmail, contactFox, contactMobile, contactTel, consumerNumber, name, remarks };
                const result = yield call(consumerService.putPersonal, consumer.id, data);
                yield call(callback!, result);
            } catch (e) {
                message!.error(`${e?.errmsg || e}`);
            }
        },

        *getProjectCount({ message }, { call, put }) {
            try {
                const result = yield call(specialProjectService.getProjectCount);
                yield put({ type: "input", data: { projectCount: result } });
            } catch (e) {
                yield call(message!.error, "getProjectCount" + e.errmsg);
            }
        },
    };
}
app.model(personalBasicModel);
