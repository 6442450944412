import { EffectsMapObject } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";

import { app } from "@reco-w/core-ui";

import { Namespaces } from "./common";
import { ApprovalBaseEffects } from "./approval.base.model";
import { ApprovalStateBaseEffects } from "./approval.state.base.model";

export namespace approvalStateHtmlDetailModel {
    export const namespace = Namespaces.approvalStateHtmlDetail;
    export const state: any = freeze({ ...CoreState, showloading: true }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...CoreReducers,
        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        ...ApprovalBaseEffects,
        ...ApprovalStateBaseEffects,
    };
}
app.model(approvalStateHtmlDetailModel);
