
/**
 * 我的 目录
 * @returns
 */
export function getMyMenu() {
    return [
        // {
        //     key: "/my",
        //     url: `/my`,
        //     icon: "iconhome-g",
        //     title: "我的主页",
        // },
        {
            key: "/my/summitregistration",
            url: `/my/summitregistration`,
            icon: "center-icon icon1",
            title: "峰会报名"
        },
        // {
        //     key: "/my/exhibitionregistration",
        //     url: `/my/exhibitionregistration`,
        //     icon: "center-icon icon1",
        //     title: "参展报名"
        // },
        {
            key: "/my/certifyDetail",
            url: `/my/certifyDetail`,
            icon: "center-icon icon2",
            title: "用户信息",
        },
        {
            key: "/my/negotiatewith",
            url: `/my/negotiatewith`,
            icon: "center-icon icon3",
            title: "1V1洽谈"
        },
        {
            key: "/my/circlecontacts",
            url: `/my/circlecontacts`,
            icon: "center-icon icon4",
            title: "人脉圈",
            children: [],
        },
        // {
        //     key: "/my/mynotice",
        //     url: `/my/mynotice`,
        //     icon: "iconxiaoxi",
        //     title: "消息中心",
        //     badgeMap: "noticeCount",
        // },
        // {
        //     key: "/my/relatedToMe",
        //     icon: "iconcorrelation",
        //     title: "与我相关",
        //     children: getMyMenuRelatedToMe(),
        // },
        // {
        //     key: "/my/rightCenter",
        //     icon: "iconrights",
        //     title: "权益中心",
        //     children: getMyMenuRightCenter(),
        // },
        // {
        //     key: "/my/relatedToBusiness",
        //     icon: "iconfirm",
        //     title: "企业相关",
        //     children: getMyMenuRelatedToBusiness(),
        // },
    ];
}
