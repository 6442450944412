import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers, formatDateTime, getCurrentParkId } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { policyService } from "@reco-w/policy-service";
import { Namespaces } from "./common";

export namespace policyHotModel {
    export const namespace = Namespaces.policyHot;

    export const state: any = freeze(
        {
            ...CoreState,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message }, { put, call }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getHotPolicy", message });
            } catch (e) {
                yield call(message?.error, e.errmsg);
            }
        },
        /**
         * 获取热门政策
         */
        *getHotPolicy({ message }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const data = yield call(
                    policyService.getPaged,
                    Object.assign(
                        { IsValid: true },
                        {
                            parkId: getCurrentParkId(),
                            orderBy: "Views desc",
                            PageIndex: 1,
                            PageSize: 5,
                        }
                    )
                );

                if (data.items) {
                    let formatDate = data.items.map((item, index) => {
                        return {
                            key: index + 1,
                            number: index + 1,
                            title: item.title,
                            date: formatDateTime(item.publishTime, "yyyy-MM-dd"),
                            effectTime: formatDateTime(item.effectTime, "yyyy-MM-dd"),
                            expirationTime: formatDateTime(item.expirationTime, "yyyy-MM-dd"),
                            id: item.id,
                        };
                    });
                    yield put({ type: "input", data: { policyData: formatDate, totalPage: data.totalItems } });
                }
            } catch (e) {
                yield call(message?.error, e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(policyHotModel);
