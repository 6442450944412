import { HttpService, resolveService } from "@reco-m/core";
export class FlowNumberHttpService extends HttpService {
    constructor() {
        super("flow/number");
    }

    getSerialNumber(data: any) {
        return this.httpPost("get-serial-number", data);
    }
}
export const flowNumberService = resolveService(FlowNumberHttpService);
