/** Start 排序方式枚举 */
export enum SortNumberEnum {
    /**
     * 按发布时间
     */
    inputTime = 1,
    /**
     * 按受欢迎度
     */
    popularity = 2,
}

export enum SeqEnum {
    /**
     * 正序
     */
    asc = 1,
    /**
     * 倒序
     */
    desc = 2,
}

export enum EnumWordEnum {
    /**
     * 正序
     */
    asc = " asc",
    /**
     * 倒序
     */
    desc = " desc",
}
/** End 排序方式枚举 */
