import { LocalStorageEx } from "./storage";
import { authService } from "@reco-w/auth-service";
import { getCurrentParkId } from "@reco-m/core";
import { memberService } from "@reco-w/member-service";
import { OFile } from "./file";

export namespace OSetting {
    export async function loginSet() {
        const d = await authService.getCurrentUser();
        if (d?.currentUser) {
            const u = d.currentUser;
            LocalStorageEx.setLocalStorage("userId", u.id);
            LocalStorageEx.setLocalStorage("userName", u.realName || "");
            LocalStorageEx.setLocalStorage("mobile", u.mobile || "");

            await companySet();
        } else {
            logoutSet();
        }
    }
    export function logoutSet() {
        authService.logout();
        LocalStorageEx.remove("userId");
        LocalStorageEx.remove("userName");
        LocalStorageEx.remove("mobile");
        LocalStorageEx.remove("signArr");
    }

    export async function companySet() {
        const member = await OMember.api.getMember();

        LocalStorageEx.set({ attr: "member", value: member });

        if (member?.status === 3) {
            LocalStorageEx.setLocalStorage("companyName", member.companyName);
        } else {
            companyRomveSet();
        }
    }
    export function companyRomveSet() {
        LocalStorageEx.remove("companyName");
    }
}

export namespace OUser {
    export function getUserId() {
        return LocalStorageEx.getLocalStorage("userId");
    }
}

export namespace OMember {
    export const api = {
        async getMember({ userId = OUser.getUserId(), needPic = false } = {}) {
            let m = await memberService.getMember(userId, getCurrentParkId());
            m = m[0];
            if (needPic) {
                m.pictureUrl = await OFile.api.getHeadPic(m.id);
            }

            return m;
        },
    };

    export function getMember() {
        return LocalStorageEx.get("member");
    }
    /** 是否认证 */
    export function getIsCertify() {
        return !!LocalStorageEx.getLocalStorage("companyName");
    }
}
