import { HttpService, resolveService, isAnonymous } from "@reco-m/core";

export class AuthHttpService extends HttpService {
    protected currentUserPromise: any;

    constructor() {
        super("authorization/oauth2");
    }
    /**
     * 获取当前用户信息
     */
    getCurrentUser() {
        if (!isAnonymous()) {
            this.currentUserPromise || (this.currentUserPromise = this.httpGet("current-user"));
        }

        return this.currentUserPromise || Promise.resolve({});
    }

    clearCurrentUser() {
        delete this.currentUserPromise;
    }

    refreshCurrentUser() {
        return this.clearCurrentUser(), this.getCurrentUser();
    }

    logout() {
        this.clearCurrentUser();

        return this.http.logout();
    }

    /**
     * 获取登录验证码
     * @param data
     * @returns
     */
    getLoginCode(data?) {
        return this.httpGet("authorize", this.resolveRequestParams(data));
    }
}

export const authService = resolveService(AuthHttpService);

/**
 * 用户管理
 */
export class AuthAccountHttpService extends HttpService {
    currentUser: any;
    constructor() {
        super("authorization/account");
    }
    /**
     * 修改用户信息
     */
    put(id, param) {
        return this.httpPut("" + id, param);
    }
    /**
     * 修改手机号码验证
     */
    sendEditMobileCode(data) {
        return this.httpPut("send-edit-mobile-code", data);
    }
    /**
     * 修改手机号码
     */
    editMobile(data) {
        return this.httpPut("edit-mobile", data);
    }
    /**
     * 游客重置密码验证
     */
    sendGuestResetPwd(data) {
        return this.httpPut("send-guest-reset-pwd", data);
    }
    /**
     * 游客重置密码
     */
    guestResetPwd(data) {
        return this.httpPut("guest-reset-pwd", data);
    }
    /**
     * 快速查询用户
     */
    getSampleUser(params: object) {
        return this.httpGet("fast-query", this.resolveRequestParams(params));
    }
}

export const authAccountHttpService = resolveService(AuthAccountHttpService);

/**
 * 会员管理
 */
export class AddMemberHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("member/member");
    }
    /**
     * 添加会员
     */
    addMember(id) {
        return this.httpPost(id);
    }
}
export const addMemberService = resolveService(AddMemberHttpService);

export class PersonInfoHttpService extends HttpService {
    constructor() {
        super("contact-personal/contact-personal");
    }
    /**
     * 保存联系人
     */
    saveInfo(data) {
        return this.httpPost("contact-person", data);
    }
    /**
     * 查询联系人
     */
    getInfo(data) {
        return this.httpGet("contact-person", this.resolveRequestParams(data));
    }
    /**
     * 修改联系人
     */
    modifyInfo(id, data) {
        return this.httpPut("modify-contact-person/" + id, data);
    }
}
export const personInfoService = resolveService(PersonInfoHttpService);

/**
 * 二维码管理
 */
export class AuthQrHttpService extends HttpService {
    currentUser: any;
    constructor() {
        super("authorization/qrcode");
    }
    /**
     * 检查二维码是否与用户有绑定
     */
    checkToken(data) {
        return this.httpPost("check-token", data);
    }
}

export const authQrHttpService = resolveService(AuthQrHttpService);
