import { HttpService, resolveService } from "@reco-m/core";

/**
 * 活动管理
 */
export class ActivityHttpService extends HttpService {
    constructor() {
        super("activity/activity");
    }

    /**
     * 获取详情
     * @param id
     * @param data
     * @returns
     */
    getDetail(id, data) {
        return this.httpGet(id, this.resolveRequestParams(data));
    }
}
/**
 * 活动管理
 */
export const activityService = resolveService(ActivityHttpService);

/**
 * 活动报名管理
 */
export class ActivityApplyHttpService extends HttpService {
    constructor() {
        super("activity/apply");
    }
    /**
     * 报名
     * @param activityId
     * @param [data]
     * @returns
     */
    apply(activityId, data?: any) {
        return this.httpPost("" + activityId, data);
    }
    /**
     * 取消报名
     * @param activityId
     * @param [mobile]
     * @returns
     */
    unapply(activityId, mobile?: any) {
        return this.httpDelete("" + activityId + "?mobile=" + mobile);
    }
    /**
     * 报名详情
     * @param activityId
     * @param [datas]
     * @returns
     */
    applydetail(activityId, datas?: any) {
        return this.httpGet("" + activityId, this.resolveRequestParams(datas));
    }
}
/**
 * 活动报名管理
 */
export const activityApplyHttpService = resolveService(ActivityApplyHttpService);
export class ActivityApplyFormHttpService extends HttpService {
    constructor() {
        super("activity/apply-form");
    }
    /**
     * 报名表单
     * @param [data]
     * @returns
     */
    applyform(data?: any) {
        return this.httpGet("list", this.resolveRequestParams(data));
    }
}
export const activityApplyFormHttpService = resolveService(ActivityApplyFormHttpService);

/**
 * 活动签到管理
 */
export class ActivitySignHttpService extends HttpService {
    constructor() {
        super("activity/sign-in");
    }
    /**
     * 活动签到
     * @param activityId
     * @param [data]
     * @returns
     */
    signIn(activityId, data?: any) {
        return this.httpPut("" + activityId, data);
    }
}
/**
 * 活动签到管理
 */
export const activitySignHttpService = resolveService(ActivitySignHttpService);
